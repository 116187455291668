import moment from 'moment-timezone';

export function useGlobalFilters() {
    return {
        cpf: function (value) {
            if (!value) return '';
            value = value.toString().padStart(11, '0');
            return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        },
        cnpj: function (value) {
            if (!value) return '';
            value = value.toString().padStart(14, '0');
            return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        },
        pis: function (value) {
            if (!value) return '';
            value = value.toString().padStart(11, '0');
            return value.replace(/^(\d{3})(\d{5})(\d{2})(\d)/, '$1.$2.$3-$4');
        },
        phone: function (value) {
            if (!value) return '';
            value = value.toString().padStart(10, '0');
            return value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
        },
        zipcode: function (value) {
            if (!value) return '';
            value = value.toString().padStart(8, '0');
            return value.replace(/^(\d{5})(\d{3})/, '$1-$2');
        },
        formatDate(dateString) {
            const date = moment(dateString);
            return date.format('DD/MM/YYYY [às] HH:mm[h]');
        },
        formatTime(dateString) {
            const date = moment(dateString);
            return date.format('HH:mm');
        },
        formatTimeUtc(hora) {
            return moment.tz(hora, 'UTC').format('HH:mm');
        },
        formatDateOnly(dateString) {
            const date = moment.tz(dateString, 'YYYY-MM-DD', 'America/Sao_Paulo');
            if (date.isValid()) {
                return date.format('DD/MM/YYYY');
            }
            return ' ';
        },
        formatCurrency(value) {
            if (value) return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        },
        formatDayMonth(dateString) {
            const date = moment(dateString);
            return date.format('DD/MM');
        },
        jsonToDate(dateString) {
            const date = moment(dateString.replace('Z', ''));
            return date.toDate();
        },
        cut(text, length, clamp) {
            clamp = clamp || '...';
            let node = document.createElement('div');
            node.innerHTML = text;
            let content = node.textContent;
            return content.length > length ? content.slice(0, length) + clamp : content;
        },
        simNao(value) {
            return value ? 'Sim' : 'Não';
        },
        periodo(value) {
            if (value === 1) return 'Manhã';
            if (value === 2) return 'Tarde';
            return 'Não informado';
        }
    };
}
