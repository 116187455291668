<template>
    <AppLoadingWrapper v-if="loading" />
    <Page
        :titulo="tituloDaPagina"
        :cancelar="['criar', 'editar'].includes(acaoAso)"
        :criar="acaoAso === 'listar'"
        :salvar="['editar', 'criar'].includes(acaoAso)"
        :visualizar="['editar', 'criar'].includes(acaoAso)"
        :voltar="['listar'].includes(acaoAso)"
        @cancelar="cancelar"
        @criar="acaoAso = 'criar'"
        @search="updateFilter"
        @salvar="validarFormulario"
        @voltar="voltar"
    >
        <div ref="body" :style="{ height: `${alturaResponsiva}px` }">
            <Listagem v-if="acaoAso === 'listar'" @duplicar="duplicarFormulario" @editar="editarFormulario" :filter="filter" />
            <Formulario
                ref="asoRef"
                v-if="['criar', 'editar'].includes(acaoAso)"
                v-model="aso"
                @update-height="definirAlturaResponsiva"
                @listar="listarAso"
            />
        </div>
    </Page>
</template>
<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import Page from './components/Page.vue';
import Listagem from './components/Listagem.vue';
import Formulario from './components/FormAso.vue';
import router from '../../../router';
import AppLoadingWrapper from '../../../components/AppLoadingWrapper.vue';

const body = ref(null);
const aso = ref({
    nome: '',
    isLogo: false,
    isParecer: false,
    parecer: [],
    acessoTodosClientes: false
});

const alturaResponsiva = ref(0);
const acaoAso = ref('listar');
const asoRef = ref(null);
const loading = ref(false);
const filter = ref('');

const tituloDaPagina = computed(() => {
    if (acaoAso.value === 'criar') return 'Novo ASO';
    if (acaoAso.value === 'editar') return 'Editar ASO';

    return 'Atestados de Saúde Ocupacional';
});

onMounted(() => {
    definirAlturaResponsiva();
    window.addEventListener('resize', definirAlturaResponsiva);
});
onUnmounted(() => {
    window.removeEventListener('resize', definirAlturaResponsiva);
});

function duplicarFormulario(data) {
    aso.value = data;
    acaoAso.value = 'criar';
}

function editarFormulario(data) {
    aso.value = data;
    acaoAso.value = 'editar';
}
function definirAlturaResponsiva() {
    const footer = 68;
    const coordenadas = body.value.getBoundingClientRect();

    alturaResponsiva.value = window.innerHeight - coordenadas.top - footer;
}

function cancelar() {
    acaoAso.value = 'listar';
    resetarFormAso();
}

function resetarFormAso() {
    aso.value = {
        nome: '',
        isLogo: false,
        isParecer: false,
        parecer: [],
        acessoTodosClientes: false
    };
}
function voltar() {
    if (acaoAso.value === 'listar') router.back();
    else if (acaoAso.value.id) acaoAso.value = 'editar';
    else acaoAso.value = 'criar';
}

function updateFilter(newFilter) {
    filter.value = newFilter;
}

function listarAso() {
    acaoAso.value = 'listar';
}
</script>
