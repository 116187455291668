<template>
    <DataTable :value="configFuncionario.examesAtendidos">
        <Column v-for="col of examesPrestadorColumns" :field="col.field" :key="col.field" :style="col.style">
            <template #header>
                <div class="font-semibold" v-html="col.header" />
            </template>
            <template #body="slotProps">
                <template v-if="col.field === 'prestador'">
                    <span class="font-semibold">{{ slotProps.data.prestador.name }}</span>
                </template>
                <template v-else-if="col.field === 'procedimentos'">
                    <span>{{ getNomesProcedimentos(slotProps.data.procedimentos) }}</span>
                </template>
                <template v-else-if="col.field === 'data'">
                    <CalendarioPrestadorContext :prestadorId="slotProps?.data?.prestador?.id" v-slot="{ disabledDays }">
                        <Calendar
                            :modelValue="slotProps.data.data"
                            :class="{ 'p-invalid': configFuncionario.submitted && !slotProps.data.data }"
                            :disabled="verificarDesabilitar(slotProps.index, configFuncionario.examesAtendidos)"
                            :disabledDays="disabledDays"
                            :max-date="getMaxDate(slotProps.index, true)"
                            :min-date="getMinDate(slotProps.index, configFuncionario.examesAtendidos, true)"
                            @update:modelValue="definirDataExamesAtendidos($event, slotProps.index)"
                        />
                    </CalendarioPrestadorContext>
                </template>
                <template v-else-if="col.field === 'hora'">
                    <DropdownDataHorarioDisponiveis
                        :configFuncionario="configFuncionario"
                        :examesAtendidos="slotProps.data"
                        :formData="agendamento"
                        :indexAtual="slotProps.index"
                        :todosExamesAtendidos="configFuncionario.examesAtendidos"
                        :procedimentoLaudoPCD="procedimentoLaudoPCD(slotProps.data)"
                        v-model="slotProps.data.hora"
                        @change="limparDataHoraSubsequentes(slotProps.index)"
                        :class="{ 'p-invalid': configFuncionario.submitted && !slotProps.data.hora }"
                    />
                </template>
                <template v-else>
                    <span>{{ slotProps.data[col.field] }}</span>
                </template>
            </template>
        </Column>
    </DataTable>
</template>
<script setup>
import CalendarioPrestadorContext from './../../../../../../../components/prestador/calendario-prestador-context/CalendarioPrestadorContext.vue';
import { defineProps, reactive } from 'vue';
import dayjs from 'dayjs';
import DropdownDataHorarioDisponiveis from '../../../../criar-agendamento/steps/components/DropdownDataHorarioDisponiveis.vue';
import { tabelaReservaExamesPrestadorColumns } from '../TabelaReserva';
import { DiaDaSemanaUtilPrestador } from '@/pages/gestao-medica-v3/agendamentos/dialog-reserva-horario/DiaSemanaUtilPrestador';

const props = defineProps({
    configFuncionario: {
        type: Object,
        required: true
    },
    configFuncionarioIndex: {
        type: Number,
        required: true
    },
    agendamento: {
        type: Object,
        required: true
    },
    maxDate: {
        type: Object
    },
    minDate: {
        type: Object
    }
});
const examesPrestadorColumns = tabelaReservaExamesPrestadorColumns;

function getNomesProcedimentos(procedimentos) {
    const procedimentosArray = Array.isArray(procedimentos) ? procedimentos : [procedimentos];
    return procedimentosArray.map(({ name }) => name).join('; ');
}

function verificarDesabilitar(indexAmbiente, examesAtendidosFuncionario) {
    if (indexAmbiente === 0) {
        return false;
    }

    const exameAnterior = examesAtendidosFuncionario.at(indexAmbiente - 1);
    return !(exameAnterior.data && exameAnterior.hora);
}

const diaDaSemanaUtil = reactive(new DiaDaSemanaUtilPrestador());

function getMinDate(index, examesAtendidos, exameAtendido = true) {
    if (index === 0 && exameAtendido) {
        return props.minDate;
    } else {
        const dataExamesComplementares = examesAtendidos?.at(0)?.data;
        if (!dataExamesComplementares) return null;
        const antecedencia = examesAtendidos?.at(0).antecedencia;

        const dataAposDiasUteis = diaDaSemanaUtil.obterDataApos(dataExamesComplementares, +antecedencia);
        return dataAposDiasUteis.toDate();
    }
}

function getMaxDate(index, exameAtendido = true) {
    if (index === 0 && exameAtendido) {
        return props.maxDate;
    }
    return null;
}

function definirDataExamesAtendidos(dataSelecionada, index) {
    const existeDataHoraAnterior = index > 0;

    if (existeDataHoraAnterior) {
        reutilizaDataSeDiasIguais(dataSelecionada, index);
    } else {
        const configFuncionario = props.configFuncionario;
        configFuncionario.examesAtendidos[index].data = dataSelecionada;
    }

    limparDataHoraSubsequentes(index);
}

function reutilizaDataSeDiasIguais(dataSelecionada, index) {
    const examesAtendidos = props.configFuncionario.examesAtendidos;
    const dataAnterior = examesAtendidos[index - 1]?.data;
    const horaAnterior = new Date(examesAtendidos[index - 1]?.hora?.horarioSaida);
    const mesmoDiaAnterior = dayjs(dataSelecionada).isSame(dataAnterior, 'day');

    const configFuncionario = props.configFuncionario;
    configFuncionario.examesAtendidos[index].data = mesmoDiaAnterior ? horaAnterior : dataSelecionada;
}

function limparDataHoraSubsequentes(indexAtual) {
    const configFuncionario = props.configFuncionario;
    configFuncionario.examesAtendidos.forEach((exame, index) => {
        if (index > indexAtual) {
            exame.data = undefined;
            exame.hora = undefined;
        }
    });
    configFuncionario.examesNaoAtendidos?.forEach((exame) => {
        exame.data = undefined;
        exame.hora = undefined;
    });
}

function procedimentoLaudoPCD() {
    const procedimentos = props.agendamento.funcionarios.find(
        (funcionario) => funcionario.id === props.configFuncionario.idFuncionario
    )?.procedimentos;
    return !!procedimentos?.some((p) => p.pcd);
}
</script>
