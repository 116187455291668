<template>
    <Dropdown
        v-model="value"
        :options="items"
        :optionLabel="optionLabel"
        :optionValue="optionValue"
        :placeholder="placeHolder"
        :virtualScrollerOptions="{
            lazy: true,
            onLazyLoad: onLazyLoad,
            itemSize: 28,
            showLoader: true,
            loading: loading
        }"
        emptyFilterMessage="Nenhum registro encontrado"
        filter
        autoFilterFocus
        @filter="filtrar"
        :filterFields="filterFields"
    >
        <template v-slot:loader="{ options }">
            <div class="flex align-items-center p-2" style="height: 30px">
                <Skeleton :width="options.even ? '60%' : '50%'" height="1.2rem" />
            </div>
        </template>

        <template #value="slotProps">
            <div v-if="slotProps.value && renderValue">
                {{ this.renderValue(slotProps) }}
            </div>
            <div v-else-if="slotProps.value && optionLabel">
                {{ slotProps.value[this.optionLabel] }}
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
        </template>

        <template #option="slotProps">
            <div v-if="renderOption">
                <span>
                    {{ this.renderOption(slotProps) }}
                </span>
            </div>
            <div v-else-if="slotProps.option && optionLabel">
                {{ slotProps.option[this.optionLabel] }}
            </div>
            <span v-else>
                {{ slotProps.option }}
            </span>
        </template>
    </Dropdown>
</template>

<script>
export default {
    emits: ['update:modelValue'],
    props: {
        modelValue: {
            type: Object
        },
        optionLabel: {
            type: String,
            required: true
        },
        optionValue: {
            type: String
        },
        renderValue: {
            type: Function
        },
        renderOption: {
            type: Function
        },
        recordsPerPage: {
            type: Number,
            default: 20
        },
        service: {
            type: Object,
            required: true
        },
        placeHolder: {
            type: String,
            default: 'Selecione'
        },
        queryDelay: {
            type: Number,
            default: 500
        },
        filtrosExtras: {
            type: Object
        },
        ordenacao: {
            type: Object
        },
        filterFields: {
            type: Array
        }
    },
    data() {
        return {
            loading: false,
            filter: null,
            items: [],
            firstLoaded: -1,
            lastLoaded: -1,
            page: 1,
            limit: 10,
            hasMoreItems: true,
            timeout: null
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    watch: {
        filtrosExtras(newValue, oldValue) {
            if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                this.resetarProps();
                this.onLazyLoad({ first: 0 });
            }
        }
    },
    methods: {
        async filtrar(event) {
            this.filter = event.value;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.resetarProps();

                this.onLazyLoad({ first: 0 });
            }, this.queryDelay);
        },
        async onLazyLoad(event) {
            const { first, last } = event;

            if (this.loading || !this.hasMoreItems) {
                await this.$nextTick(() => (this.loading = false));
                return;
            }

            if (first <= this.firstLoaded) {
                this.loading = false;
                return;
            }

            this.firstLoaded = first;
            this.lastLoaded = last;
            this.limit = this.recordsPerPage;

            try {
                this.loading = true;
                await this.load({
                    page: this.page,
                    limit: this.limit,
                    filter: this.filter,
                    filtrosExtras: this.filtrosExtras,
                    sort: this.ordenacao
                });

                this.page++;
            } catch (error) {
                console.error('Erro ao buscar registros:', error);
                throw error;
            } finally {
                this.loading = false;
            }
        },
        async load({ page, limit, filter, filtrosExtras }) {
            const records = await this.service.findAll({ page, limit, filter, filtrosExtras, sort: this.ordenacao });

            if (records.data.items?.length === 0) {
                this.hasMoreItems = false;
                this.loading = false;
                return;
            }

            this.items = [...this.items, ...records.data.items];
        },
        resetarProps() {
            this.items = [];
            this.page = 1;
            this.firstLoaded = -1;
            this.lastLoaded = -1;
            this.hasMoreItems = true;
        }
    }
};
</script>
