<template>
    <div v-if="!asos.length" class="flex flex-column h-full my-auto justify-content-center align-items-center">
        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 6rem" />
        <div v-else class="flex flex-column align-items-center">
            <img src="layout/images/formularios/listagem-vazia.svg" alt="listagem vazia" width="132" />
            Ainda não foi adicionada nenhuma informação
        </div>
    </div>
    <DataTable
        v-else
        :pt="{
            wrapper: { style: { height: 'calc(100% - 40px)' } }
        }"
        :rows="10"
        :totalRecords="paginacao.totalItems"
        :value="asos"
        class="h-full"
        lazy
        paginator
        scroll-height="100%"
        scrollable
        removable-sort
        @page="listar"
        @sort="onSort"
    >
        <Column
            v-for="coluna in colunas"
            :field="coluna.field"
            :header="coluna.header"
            :key="coluna.field"
            :sortable="coluna.sortable"
            :style="{ width: coluna.width }"
            header-class="bg-gray-200"
        >
            <template #body="{ data }">
                <span class="flex gap-2" :class="{ 'font-bold': data.isPadrao }">
                    <span class="">
                        {{ coluna.field.split('.').reduce((valor, chave) => valor[chave], data) }}
                    </span>
                    <span v-if="coluna.field === 'name'">{{ data.isPadrao ? '(padrão)' : undefined }}</span>
                </span>
            </template>
        </Column>
        <Column bodyClass="text-right" headerClass="bg-gray-200" style="width: 40px">
            <template #body="{ data }">
                <Button class="text-600" icon="pi pi-ellipsis-v" text @click="abrirMenu($event, data?.id, data?.isPadrao)" />
            </template>
        </Column>
        <ProgressBar v-if="loading" class="w-full border-noround z-2 absolute" mode="indeterminate" style="height: 4px; top: 40px" />
    </DataTable>
    <Menu ref="menu" :model="menuAcoes" :popup="true" />
    <ConfirmDialog />
</template>
<script setup>
import { onBeforeMount, ref, watch, defineProps, defineEmits } from 'vue';
import { checkPermission } from '@/common/check-permission';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import BaseService from '@/services/BaseService';
import dayjs from 'dayjs';
import { useDialog } from 'primevue/usedialog';
const serviceVinculoClient = new BaseService('vinculo-customer/aso');
import { getCompany } from '@/services/auth';
import ConteudoModalClientesVinculados from '@/components/clientes-vinculados/ConteudoModalClientesVinculados.vue';
import EnumClienteVinculadoOrigem from '@/enums/EnumClienteVinculadoOrigem';
import ConteudoModalNovoVinculo from '@/components/clientes-vinculados/ConteudoModalNovoVinculo.vue';
import axios from 'axios';

const emit = defineEmits(['duplicar', 'editar']);

const props = defineProps({
    filter: {
        type: String,
        default: ''
    }
});
const colunas = [
    {
        field: 'id',
        header: 'Código',
        sortable: true,
        width: '100px'
    },
    {
        field: 'name',
        header: 'Nome do ASO',
        sortable: true
    },
    {
        field: 'createdAt',
        header: 'Data Criação',
        sortable: true,
        width: '120px'
    }
];
const asos = ref([]);
const asoId = ref(null);
const asoIsPadrao = ref(null);
const paginacao = ref({
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0
});
const loading = ref(false);
const menu = ref(null);
const menuAcoes = ref([
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        disabled: () => !checkPermission('gestamedica_gerenciar_aso:editar'),
        command: editar
    },
    {
        label: 'Duplicar',
        icon: 'pi pi-clone',
        command: duplicar
    },
    {
        label: 'Gerar PDF',
        icon: 'pi pi-file',
        command: gerarPdf
    },
    {
        label: 'Clientes Vinculados',
        icon: 'pi pi-link',
        command: abrirModalClientesVinculados
    },
    {
        label: 'Tornar Padrão',
        icon: 'pi pi-tag',
        disabled: () => asoIsPadrao.value,
        command: abrirTornarPadrao
    },
    {
        label: 'Excluir',
        icon: 'pi pi-trash',
        disabled: () => !checkPermission('gestamedica_gerenciar_aso:excluir') || asoIsPadrao.value,
        command: abrirConfirmacaoExcluir
    }
]);

const serviceAso = new BaseService('aso');
const toast = useToast();
const confirm = useConfirm();

onBeforeMount(() => {
    listar();
});

watch(
    () => props.filter,
    () => {
        listar();
    }
);
async function buscar() {
    loading.value = true;
    try {
        const { data } = await serviceAso.findById(asoId.value);
        asoId.value = null;

        return data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar o ASO',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}
async function duplicar() {
    const aso = await buscar();
    aso.id = undefined;
    aso.isPadrao = false;

    emit('duplicar', aso);
}
async function gerarPdf() {
    try {
        const aso = await buscar();
        let { urlLogo, name } = await getCompany();
        if(urlLogo){
            urlLogo = urlLogo.split('/').pop();
        }
        const { logoUrlEmpresa, nomeClienteVinculo } = await getLogoAndNameByAsoId(aso.id);
        const { data } = await axios.post(
            process.env.VUE_APP_API_GERADOR_PDF + `/gerador-pdf/visualizar`,
            {
                tipo_documento: 'ASO',
                parametros: {
                    empresa: {
                        name: nomeClienteVinculo,
                        inscricao: '',
                        tradeName: '',
                        logo: aso.isLogo ? logoUrlEmpresa : ''
                    },
                    company: {
                        name: name,
                        logo: urlLogo
                    },
                    nomeFuncionario: '',
                    exame: {
                        medico: '',
                        exames: '',
                        tipoExame: ''
                    },
                    medicoResponsavel: {
                        crm: '',
                        uf: ''
                    },
                    funcionario: {
                        code: '',
                        name: '',
                        sex: '',
                        rg: '',
                        cpf: '',
                        position: {
                            name: '',
                            department: ''
                        },
                        admissionDate: '',
                        dataNascimento: ''
                    },
                    tipoExame: {
                        descricao: ''
                    },
                    parecer: ['APTO_PARA_FUNCAO', 'INAPTO_PARA_FUNCAO']
                }
            },
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );

        const pdfBlob = new Blob([data], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `ASO_${aso.nome}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        listar();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao gerar PDF',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}
async function getLogoAndNameByAsoId(asoId) {
    const { data } = await serviceVinculoClient.findById(asoId);

    let logoUrlEmpresa = '';
    let nomeClienteVinculo = '';

    if (data.items[0]?.urlLogo) {
        logoUrlEmpresa = data.items[0]?.urlLogo.split('/').pop();
    }

    if (data.items[0]?.name) {
        nomeClienteVinculo = data.items[0].name;
    }

    return { logoUrlEmpresa, nomeClienteVinculo };
}
async function editar() {
    const aso = await buscar();
    emit('editar', aso);
}
async function excluir() {
    loading.value = true;
    try {
        await serviceAso.remove(asoId.value);
        listar();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao excluir ASO',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}

const page = ref(1);
const limit = ref(10);
const sort = ref(null);

async function onSort(event) {
    const field = event.sortField;
    const order = event.sortOrder === 1 ? `ASC` : 'DESC';
    if (field) {
        sort.value = `${field} ${order}`;
    } else {
        sort.value = null;
    }

    await listar();
}
async function listar(event) {
    loading.value = true;
    try {
        if (event?.page !== undefined) {
            page.value = event.page + 1;
        } else {
            page.value = 1;
        }
        limit.value = event?.rows ?? limit.value;
        const { data } = await serviceAso.findAll({
            page: page.value,
            limit: limit.value,
            sort: sort.value,
            filter: props.filter
        });

        asos.value = data.items.map((item) => ({
            ...item,
            name: item.nome,
            createdAt: dayjs(item.createdAt).format('DD/MM/YYYY')
        }));
        paginacao.value = data.meta;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar listar os ASOs',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}
function abrirMenu(event, id, isPadrao) {
    asoId.value = id;
    asoIsPadrao.value = isPadrao;
    menu.value.toggle(event);
}
function abrirConfirmacaoExcluir() {
    confirm.require({
        message: `O ASO será excluído, deseja continuar?`,
        header: 'Excluir ASO',
        icon: 'pi pi-info-circle',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Continuar',
        rejectClass: 'p-button-primary p-button-text',
        acceptClass: 'p-button-danger p-button-outlined',
        accept: excluir
    });
}

function abrirTornarPadrao() {
    confirm.require({
        message: `O ASO selecionado será o novo padrão, deseja continuar?`,
        header: 'Atualizar ASO Padrão',
        icon: 'pi pi-info-circle',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Continuar',
        rejectClass: 'p-button-primary p-button-text',
        acceptClass: 'p-button-danger p-button-outlined',
        accept: updateAsoPadrao
    });
}

async function updateAsoPadrao() {
    loading.value = true;
    try {
        const { data } = await serviceAso.findAll({
            page: 1,
            limit: 9999
        });

        let idPadraoAntigo;
        data?.items.forEach((asoPadrao) => {
            if (asoPadrao.isPadrao === true) {
                idPadraoAntigo = asoPadrao.id;
            }
        });

        if (idPadraoAntigo) {
            await serviceAso.save({
                id: idPadraoAntigo,
                isPadrao: false
            });
        }
        await serviceAso.save({
            id: asoId.value,
            isPadrao: true
        });
        toast.add({
            severity: 'success',
            summary: 'ASO padrão atualizado com sucesso',
            life: 4000
        });
        listar();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tornar ASO padrão',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}

const dialog = useDialog();
function abrirModalClientesVinculados() {
    dialog.open(ConteudoModalClientesVinculados, {
        data: {
            origem: EnumClienteVinculadoOrigem.ASO,
            idOrigem: asoId.value
        },
        props: {
            header: 'Clientes vinculados',
            style: {
                width: '100%',
                maxWidth: '700px'
            },
            modal: true
        },
        onClose(opt) {
            const { novoVinculo } = opt.data;
            if (novoVinculo) {
                abrirModalNovoVinculo();
            }
        }
    });
}

function abrirModalNovoVinculo() {
    dialog.open(ConteudoModalNovoVinculo, {
        data: {
            origem: EnumClienteVinculadoOrigem.ASO,
            idOrigem: asoId.value
        },
        props: {
            header: 'Novo vínculo',
            style: {
                width: '100%',
                maxWidth: '700px'
            },
            modal: true
        },
        onClose() {
            abrirModalClientesVinculados();
        }
    });
}
</script>
