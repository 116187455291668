<template>
    <div class="card flex flex-column p-6" style="height: calc(100dvh - 105px)">
        <div v-if="criar" class="flex align-items-center my-4">
            <router-link :to="{ path: '/' }">
                <i class="pi pi-home text-700" style="font-size: 1.5rem" />
            </router-link>
            <div v-for="(crumb, index) in breadcrumbs" :key="index">
                <template v-if="!crumb.currentRoute">
                    <i class="pi pi-chevron-right text-700 mx-3" style="font-size: 1rem" />
                    <router-link :to="crumb.to">
                        <span :class="`${crumb.currentRoute ? 'font-light' : 'font-medium'} text-700`">
                            {{ crumb.label }}
                        </span>
                    </router-link>
                </template>
            </div>
        </div>
        <div class="flex justify-content-between align-items-center">
            <div>
                <h4 class="font-medium my-1">{{ props.titulo }}</h4>
                <span class="text-700">{{ subtitulo }}</span>
            </div>
            <div>
                <Button
                    v-if="criar"
                    :icon="isMobile ? 'pi pi-plus' : ''"
                    :label="isMobile ? '' : 'Novo Formulário'"
                    class="m-2 px-4"
                    @click="emit('criar')"
                />

                <Button
                    v-if="visualizar"
                    :icon="isMobile ? 'pi pi-eye' : ''"
                    :label="isMobile ? '' : 'Visualizar'"
                    class="m-2 px-4"
                    outlined
                    @click="emit('visualizar')"
                />
                <Button
                    outlined
                    v-if="cancelar || voltar"
                    class="m-2 px-4"
                    :icon="isMobile ? 'pi pi-chevron-left' : ''"
                    :label="isMobile ? '' : 'Cancelar'"
                    @click="emit('cancelar')"
                />
                <Button
                    v-if="salvar"
                    :icon="isMobile ? 'pi pi-save' : ''"
                    :label="isMobile ? '' : 'Salvar'"
                    class="m-2 px-4"
                    @click="emit('salvar')"
                />
            </div>
        </div>
        <div class="w-full my-4" style="height: 1px; background: #ced4da" />
        <slot />
    </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';

const emit = defineEmits(['cancelar', 'criar', 'salvar', 'visualizar', 'voltar']);
const props = defineProps({
    cancelar: {
        type: Boolean,
        default: false
    },
    criar: {
        type: Boolean,
        default: false
    },
    salvar: {
        type: Boolean,
        default: false
    },
    subtitulo: {
        type: String,
        required: false
    },
    titulo: {
        type: String,
        default: 'Titulo'
    },
    visualizar: {
        type: Boolean,
        default: false
    },
    voltar: {
        type: Boolean,
        default: false
    }
});

const route = useRoute();
const isMobile = ref(false);
const breadcrumbs = computed(() => {
    return route.matched
        ?.filter((matchedRoute) => {
            return matchedRoute.name !== 'home' && !matchedRoute.meta?.defaultRoute;
        })
        .map((matchedRoute) => ({
            label: matchedRoute?.meta?.breadcrumbLabel ?? matchedRoute.name,
            to: matchedRoute.path,
            currentRoute: matchedRoute.path === route.path
        }));
});

onMounted(() => {
    verificarBreakpoint();
    window.addEventListener('resize', verificarBreakpoint);
});
onUnmounted(() => {
    window.removeEventListener('resize', verificarBreakpoint);
});

function verificarBreakpoint() {
    isMobile.value = window.innerWidth < 600;
}
</script>
