import { obterDiaDaSemanaPorIndice, obterHorariosDiasUteis } from '@/enums/EnumDiaDaSemana';
import dayjs from 'dayjs';

export class DiaDaSemanaUtilPrestador {
    diasUteis = obterHorariosDiasUteis();

    async iniciar(obterPrestador) {
        const prestador = await obterPrestador;
        this.diasUteis = prestador.prestadorHorariosDisponiveisEntity;
        return this;
    }

    obterDataApos(dataInicio, diasAntecedencia) {
        if (!this.diasUteis) dayjs(dataInicio);
        const atual = {
            data: dayjs(dataInicio),
            antecedencia: diasAntecedencia
        };
        try {
            while (atual.antecedencia >= 0) {
                atual.data = atual.data.add(1, 'days');

                const diaAtual = (diaUtil) => {
                    const diaAtual = obterDiaDaSemanaPorIndice(atual.data.day());
                    return diaUtil.diaDaSemana === diaAtual;
                };
                if (this.diasUteis.some(diaAtual)) {
                    atual.antecedencia--;
                }
            }
            return atual.data;
        } catch (error) {
            console.error(error);
            return dayjs(dataInicio).add(diasAntecedencia, 'day');
        }
    }
}
