<script setup>
import BaseService from '@/services/BaseService';
import { ref, defineProps, defineExpose } from 'vue';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
    prestadorId: Number
});

const loading = ref(false);
const enumOrigem = {
    CREDITO: 'Crédito Gerado',
    REEMBOLSO: 'Reembolso',
    Agendamento: 'Agendamento'
};

const toast = useToast();
const metadadosTabela = ref({});
const depositosConcluidos = ref([]);
async function buscarDepositosConcluidos() {
    loading.value = true;
    try {
        const url = 'agendamento-deposito/concluidos/extrato';
        const service = new BaseService(url);
        metadadosTabela.value.filtrosExtras = props;
        const { data } = await service.findAll(metadadosTabela.value);
        depositosConcluidos.value = data.items.map((deposito) => {
            return {
                ...deposito,
                origem: enumOrigem[deposito.origem],
                pagamento: deposito.data || '-',
                codigo: deposito.codigo || '-',
                valor: formatarParaReal(deposito.total)
            };
        });

        metadadosTabela.value.total = +data.meta.totalItems;
        metadadosTabela.value.itensPorPagina = +data.meta.itemsPerPage;
        metadadosTabela.value.page = +data.meta.currentPage;
        metadadosTabela.value.sort = {};
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao buscar registros de depositos concluídos! ',
            life: 3000
        });
    }
    loading.value = false;
}

function atualizarMetadadosTabela(metadados) {
    if (metadados.page !== undefined) {
        metadadosTabela.value.page = metadados.page + 1;
    }
    if (metadados.sortField) {
        metadadosTabela.value.sort = {
            [metadados.sortField]: metadados.sortOrder === 1 ? 'ASC' : 'DESC'
        };
    }

    buscarDepositosConcluidos();
}

function formatarParaReal(valor) {
    if (!valor) return 'R$ -';
    return (+valor.replace('-', '')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

buscarDepositosConcluidos();
defineExpose({ buscarDepositosConcluidos });
</script>
<template>
    <DataTable
        :loading="loading"
        :value="depositosConcluidos"
        :rows="metadadosTabela?.itensPorPagina"
        :totalRecords="metadadosTabela?.total"
        class="p-0 border-1 border-200 border-round-sm"
        style="font-family: 'Inter UI', sans-serif"
        @page="atualizarMetadadosTabela"
        @sort="atualizarMetadadosTabela"
    >
        <Column field="origem" header="Origem" style="width: 120px" />
        <Column field="criadoem" header="Data" class="w-1" />
        <Column field="pagamento" header="Pagamento" class="w-1 text-center" />
        <Column field="nomeFuncionario" header="Funcionário">
            <template #body="{ data }">
                <div v-if="data.tipo_credito === 'MANUAL'">
                    ALTERAÇÃO MANUAL
                    <i v-tooltip.top="data.justificativa" class="pi pi-info-circle mr-2 text-primary text-md" />
                </div>
                <div v-else>{{ data.nomeFuncionario }}</div>
            </template>
        </Column>
        <Column field="codigo" header="Código" class="w-1" />
        <Column field="valor" header="Valor" body-class="text-right" class="w-1 pl-6" />
    </DataTable>
    <Paginator
        :rows="metadadosTabela?.itensPorPagina"
        :totalRecords="metadadosTabela?.total"
        currentPageReportTemplate="Página {currentPage} de {totalPages}"
        template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
        @page="($event) => atualizarMetadadosTabela($event)"
    />
</template>
