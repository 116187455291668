<template>
    <div v-if="!formularios.length" class="flex flex-column h-full my-auto justify-content-center align-items-center">
        <i v-if="loading" class="pi pi-spin pi-spinner" style="font-size: 6rem" />
        <div v-else class="flex flex-column align-items-center">
            <img src="layout/images/formularios/listagem-vazia.svg" alt="listagem vazia" width="132" />
            Ainda não foi adicionada nehuma informação
        </div>
    </div>
    <DataTable
        v-else
        :pt="{
            wrapper: { style: { height: 'calc(100% - 40px)' } }
        }"
        :rows="10"
        :totalRecords="paginacao.totalItems"
        :value="formularios"
        class="h-full"
        lazy
        paginator
        scroll-height="100%"
        scrollable
        removable-sort
        @page="listar"
        @sort="onSort"
    >
        <Column
            v-for="coluna in colunas"
            :field="coluna.field"
            :header="coluna.header"
            :key="coluna.field"
            :sortable="coluna.sortable"
            :style="{ width: coluna.width }"
            header-class="bg-gray-200"
        >
            <template #body="{ data }">
                <span class="flex gap-2" :class="{ 'font-semibold': data.isPadrao }">
                    <span class="">
                        {{ coluna.field.split('.').reduce((valor, chave) => valor[chave], data) }}
                    </span>
                    <span v-if="coluna.field === 'titulo'">{{ data.isPadrao ? '(padrão)' : undefined }}</span>
                </span>
            </template>
        </Column>
        <Column bodyClass="text-right" headerClass="bg-gray-200" style="width: 40px">
            <template #body="{ data }">
                <Button class="text-600" icon="pi pi-ellipsis-v" text @click="abrirMenu($event, data?.id)" />
            </template>
        </Column>
        <ProgressBar v-if="loading" class="w-full border-noround z-2 absolute" mode="indeterminate" style="height: 4px; top: 40px" />
    </DataTable>
    <Menu class="w-fit" ref="menu" :model="menuAcoes" :popup="true" />
    <ConfirmDialog />
</template>
<script setup>
import { onBeforeMount, ref, defineEmits } from 'vue';
import { checkPermission } from '@/common/check-permission';
import { useConfirm } from 'primevue/useconfirm';
import { useToast } from 'primevue/usetoast';
import BaseService from '@/services/BaseService';
import dayjs from 'dayjs';
import { useDialog } from 'primevue/usedialog';
import ConteudoModalClientesVinculados from '@/components/clientes-vinculados/ConteudoModalClientesVinculados.vue';
import ConteudoModalNovoVinculo from '@/components/clientes-vinculados/ConteudoModalNovoVinculo.vue';
import EnumClienteVinculadoOrigem from '@/enums/EnumClienteVinculadoOrigem';

const emit = defineEmits(['duplicar', 'editar']);

const colunas = [
    {
        field: 'id',
        header: 'Código',
        sortable: true,
        width: '100px'
    },
    {
        field: 'titulo',
        header: 'Nome',
        sortable: true
    },
    {
        field: 'tipoFormulario.name',
        header: 'Tipo',
        sortable: true,
        width: '160px'
    },
    {
        field: 'dataCriacao',
        header: 'Data criação',
        sortable: true,
        width: '120px'
    }
];
const formularios = ref([]);
const formularioId = ref(null);
const loading = ref(false);
const menu = ref(null);
const menuAcoes = ref([
    {
        label: 'Tornar padrão',
        icon: 'pi pi-star',
        disabled: verificarPadrao,
        command: abrirConfirmacaoPadrao
    },
    {
        label: 'Editar',
        icon: 'pi pi-pencil',
        disabled: () => !checkPermission('gestaomedica_painel_formularios:editar')|| verificarPadrao(),
        command: editar
    },
    {
        label: 'Duplicar',
        icon: 'pi pi-copy',
        command: duplicar
    },
    {
        label: 'Clientes vinculados',
        icon: 'pi pi-link',
        command: abrirModalClientesVinculados
    },
    {
        label: 'Excluir',
        icon: 'pi pi-trash',
        disabled: () => !checkPermission('gestaomedica_painel_formularios:excluir') || verificarPadrao(),
        command: abrirConfirmacaoExcluir
    }
]);
const paginacao = ref({
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0
});
const service = new BaseService('formulario');
const toast = useToast();
const confirm = useConfirm();

onBeforeMount(() => {
    listar();
});

async function buscar() {
    loading.value = true;
    try {
        const { data } = await service.findById(formularioId.value);
        formularioId.value = null;

        return data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar o formulário',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}

async function duplicar() {
    const formulario = await buscar();
    formulario.id = undefined;
    formulario.secoes.forEach((secao) => {
        secao.id = undefined;
        secao.formularioPergunta.forEach((pergunta) => {
            pergunta.id = undefined;
            pergunta.formularioInstrucaoResposta.forEach((instrucao) => {
                instrucao.id = undefined;
            });
            pergunta.formularioOpcaoResposta.forEach((opcao) => {
                opcao.id = undefined;
                if (opcao.formularioRedirecionamento) {
                    opcao.formularioRedirecionamento.id = undefined;
                }
            });
            if (pergunta.formularioRedirecionamento) {
                pergunta.formularioRedirecionamento.id = undefined;
            }
        });
    });
    delete formulario.isPadrao;

    emit('duplicar', formulario);
}
async function editar() {
    const formulario = await buscar();
    emit('editar', formulario);
}
async function excluir() {
    loading.value = true;
    try {
        await service.remove(formularioId.value);
        listar();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar o formulário',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}

const page = ref(1);
const limit = ref(10);
const sort = ref(null);

async function onSort(event) {
    const field = event.sortField;
    const order = event.sortOrder === 1 ? `ASC` : 'DESC';
    if (field) {
        sort.value = `${field} ${order}`;
    } else {
        sort.value = null;
    }

    await listar();
}

async function listar(event) {
    loading.value = true;
    try {
        if (event?.page !== undefined) {
            page.value = event.page + 1;
        } else {
            page.value = 1;
        }
        limit.value = event?.rows ?? limit.value;
        const { data } = await service.findAll({
            page: page.value,
            limit: limit.value,
            sort: sort.value
        });
        formularios.value = data.items.map((item) => ({
            ...item,
            dataCriacao: dayjs(item.createdAt).format('DD/MM/YYYY')
        }));
        paginacao.value = data.meta;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar listar os formulários',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}

function abrirMenu(event, id) {
    formularioId.value = id;
    menu.value.toggle(event);
}
function abrirConfirmacaoExcluir() {
    confirm.require({
        message: `O formulário será excluído, deseja continuar?`,
        header: 'Excluir formulário',
        icon: 'pi pi-info-circle',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Continuar',
        rejectClass: 'p-button-primary p-button-text',
        acceptClass: 'p-button-danger p-button-outlined',
        accept: excluir
    });
}

const dialog = useDialog();
function abrirModalClientesVinculados() {
    dialog.open(ConteudoModalClientesVinculados, {
        data: {
            origem: EnumClienteVinculadoOrigem.FICHA,
            idOrigem: formularioId.value
        },
        props: {
            header: 'Clientes vinculados',
            style: {
                width: '100%',
                maxWidth: '700px'
            },
            modal: true
        },
        onClose(opt) {
            const { novoVinculo } = opt.data;
            if (novoVinculo) {
                abrirModalNovoVinculo();
            }
        }
    });
}

function abrirModalNovoVinculo() {
    dialog.open(ConteudoModalNovoVinculo, {
        data: {
            origem: EnumClienteVinculadoOrigem.FICHA,
            idOrigem: formularioId.value
        },
        props: {
            header: 'Novo vínculo',
            style: {
                width: '100%',
                maxWidth: '700px'
            },
            modal: true
        },
        onClose() {
            abrirModalClientesVinculados();
        }
    });
}

function verificarPadrao() {
    return formularios.value.find((formulario) => formulario.id === formularioId.value)?.isPadrao;
}

const confirmDialog = useConfirm();

async function abrirConfirmacaoPadrao() {
    confirmDialog.require({
        message: 'Tem certeza que deseja definir este formulário como padrão?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary p-button-outlined',
        rejectLabel: 'Cancelar',
        acceptLabel: 'Confirmar',
        accept: async () => {
            try {
                const serviceFormularioPadrao = new BaseService(`formulario/formulario-padrao/${formularioId.value}`);
                await serviceFormularioPadrao.save({});
                toast.add({
                    severity: 'success',
                    summary: 'Padrão definido com sucesso',
                    life: 3000
                });
                await listar();
            } catch (error) {
                toast.add({
                    severity: 'error',
                    summary: 'Erro ao tentar definir o formulário como padrão',
                    life: 3000,
                    detail: error.response?.data?.message
                });
            }
        }
    });
}
</script>
