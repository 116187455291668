<template>
    <div :class="{ 'cursor-pointer': !editavel }" class="bg-white px-6 py-4 mt-3 fadein animation-duration-100" @click.stop="emit('selecionar')">
        <div class="grid m-0 align-items-center">
            <div class="text-xl font-semibold text-600 ml-3">{{ numero }}.</div>
            <div class="col">
                <InputText
                    ref="nome"
                    v-show="editavel"
                    v-model="pergunta.nomePergunta"
                    :class="{ 'p-invalid': v$.nomePergunta.$error }"
                    class="w-full p-3 text-xl border-round-md"
                    placeholder="Pergunta"
                />
                <small v-if="v$.nomePergunta.$error" class="p-error w-full">{{ v$.nomePergunta.$errors[0].$message }}</small>
                <div
                    v-show="!editavel"
                    class="text-xl font-semibold text-600 my-3 cursor-auto"
                    style="width: fit-content"
                    @click.stop="emit('selecionar', $refs.nome.$el)"
                >
                    {{ pergunta.nomePergunta || 'Pergunta' }}
                    <span v-if="pergunta.obrigatorio" style="color: #ff7171"> * </span>
                </div>
            </div>
            <div v-if="editavel" class="col-5 pr-0">
                <Dropdown
                    :modelValue="pergunta.tipoResposta"
                    :options="tiposDeResposta"
                    class="w-full border-round-md"
                    input-class="p-3 text-xl"
                    option-label="label"
                    option-value="value"
                    placeholder="Selecionar"
                    @update:modelValue="alterarTipoResposta"
                />
            </div>
        </div>
        <div v-if="pergunta.tipoResposta === EnumTipoRespostaFormulario.DISSERTATIVA" class="my-2">
            <InputText
                class="p-3 w-full text-xl border-round-md"
                :disabled="!editavel"
                placeholder="Insira sua resposta"
                maxlength="200"
                v-model="resposta"
            />
            <div v-if="editavel" class="flex align-items-center mt-5 text-xl text-800">
                Redirecionar para:
                <DropdownGroup
                    v-model="pergunta.formularioRedirecionamento"
                    :items="itensRedirecionamento"
                    class="ml-3"
                    input-class="p-3 text-xl w-full md:w-24rem"
                    item-text="label"
                    item-value="id"
                />
            </div>
        </div>
        <div v-if="pergunta.tipoResposta === EnumTipoRespostaFormulario.MULTIPLA_ESCOLHA" class="my-2">
            <div v-if="editavel" class="grid m-0 align-items-end">
                <div class="col-7 ml-4" />
                <div class="col-auto">Redirecionar para:</div>
            </div>
            <div v-for="(opcao, index) in pergunta.formularioOpcaoResposta" :key="index">
                <div class="grid m-0 align-items-center">
                    <Checkbox v-if="pergunta.variasRespostas" disabled />
                    <RadioButton v-else disabled />
                    <div v-if="editavel" class="col py-2">
                        <InputText
                            v-model="opcao.textoOpcao"
                            :class="{ 'p-invalid': v$.formularioOpcaoResposta.$each.$response.$errors?.[index].textoOpcao.length }"
                            :disabled="opcao.outros"
                            class="w-full p-3 text-xl text-700 border-round-md"
                            placeholder="Opção"
                            @blur="validarCampos()"
                        />
                    </div>
                    <div v-if="!editavel && opcao.outros" class="col-6 py-2">
                        <InputText
                            v-model="opcao.textoOpcao"
                            class="border-none border-bottom-1 border-noround shadow-none text-xl p-3 w-full"
                            disabled
                            placeholder="Adicionar opção"
                        />
                    </div>
                    <div v-if="!editavel && !opcao.outros" class="text-xl text-600 m-3">
                        {{ opcao.textoOpcao }}
                    </div>
                    <div v-if="editavel" class="col-5 py-2 pr-0 flex">
                        <DropdownGroup
                            v-model="opcao.formularioRedirecionamento"
                            :items="itensRedirecionamento"
                            class="w-full"
                            input-class="p-3 text-xl"
                            item-text="label"
                            item-value="id"
                        />
                        <Button class="text-700 p-0 ml-4" icon="pi pi-times" size="large" text @click="removerOpcao(opcao.redirecionamentoId)" />
                    </div>
                </div>
                <small v-if="v$.formularioOpcaoResposta.$each.$response.$errors?.[index].textoOpcao.length" class="p-error ml-6">
                    {{ v$.formularioOpcaoResposta.$each.$response.$errors[index].textoOpcao[0].$message }}
                </small>
            </div>
            <div v-if="editavel" class="grid m-0 align-items-center">
                <Checkbox v-if="pergunta.variasRespostas" disabled />
                <RadioButton v-else disabled />
                <InputText
                    v-model="novaOpcao"
                    class="border-none border-bottom-1 border-noround shadow-none text-xl p-3 ml-3"
                    placeholder="Adicionar opção"
                    @keyup.enter="adicionarOpcao()"
                />
                <span v-if="!pergunta.formularioOpcaoResposta?.some((opcao) => opcao.outros)" class="text-xl text-700 ml-2">
                    ou <a class="ml-2" style="cursor: pointer" @click="adicionarOpcaoOutros()">adicionar "Outros"</a>
                </span>
            </div>
        </div>
        <div v-if="pergunta.tipoResposta === EnumTipoRespostaFormulario.TABELA" class="my-2">
            <div class="overflow-auto mt-4">
                <div class="grid grid-nogutter justify-content-between align-items-start flex-nowrap">
                    <div class="col-auto p-0">
                        <div class="bg-gray-50 border-bottom-1 border-200 w-full h-4rem" />
                        <div
                            v-for="(instrucao, index) in pergunta.formularioInstrucaoResposta"
                            :class="{ 'bg-gray-50': (index + 1) % 2 }"
                            :key="index"
                            class="h-4rem border-bottom-1 border-200 flex flex-column justify-content-center align-items-center px-3"
                        >
                            <div v-if="editavel" class="flex">
                                <InputText
                                    v-if="editavel"
                                    v-model="instrucao.textoInstrucao"
                                    :class="{ 'p-invalid': v$.formularioInstrucaoResposta.$each.$response.$errors[index].textoInstrucao.length }"
                                    class="w-full p-2 text-lg text-700 border-round-md"
                                    placeholder="Instrução"
                                    style="min-width: 200px"
                                    @blur="validarCampos()"
                                />
                                <Button
                                    class="text-700 p-0 ml-2"
                                    icon="pi pi-times"
                                    size="large"
                                    text
                                    @click="removerInstrucao(instrucao.redirecionamentoId)"
                                />
                            </div>
                            <div v-else class="text-800 w-max">
                                {{ instrucao.textoInstrucao }}
                            </div>
                            <small
                                v-if="v$.formularioInstrucaoResposta.$each.$response.$errors[index].textoInstrucao.length"
                                class="p-error w-full mt-1"
                            >
                                {{ v$.formularioInstrucaoResposta.$each.$response.$errors[index].textoInstrucao[0].$message }}
                            </small>
                        </div>
                    </div>
                    <div v-for="(opcao, index) in pergunta.formularioOpcaoResposta" :key="index" class="col p-0">
                        <div class="h-4rem bg-gray-50 border-bottom-1 border-200 flex flex-column justify-content-center align-items-center px-3">
                            <div v-if="editavel" class="flex">
                                <InputText
                                    v-model="opcao.textoOpcao"
                                    :class="{ 'p-invalid': v$.formularioOpcaoResposta.$each.$response.$errors[index].textoOpcao.length }"
                                    class="w-full p-2 text-lg text-700 border-round-md"
                                    placeholder="Opção"
                                    style="min-width: 100px"
                                    @blur="validarCampos()"
                                />
                                <Button
                                    class="text-700 p-0 ml-2"
                                    icon="pi pi-times"
                                    size="large"
                                    text
                                    @click="removerOpcao(opcao.redirecionamentoId)"
                                />
                            </div>
                            <div v-else class="text-800 w-max">
                                {{ opcao.textoOpcao }}
                            </div>
                            <small v-if="v$.formularioOpcaoResposta.$each.$response.$errors[index].textoOpcao.length" class="p-error w-full mt-1">
                                {{ v$.formularioOpcaoResposta.$each.$response.$errors[index].textoOpcao[0].$message }}
                            </small>
                        </div>
                        <div
                            v-for="(_, index) in pergunta.formularioInstrucaoResposta"
                            :class="{ 'bg-gray-50': (index + 1) % 2 }"
                            :key="index"
                            class="w-full h-4rem flex justify-content-center align-items-center border-bottom-1 border-200"
                        >
                            <RadioButton disabled />
                        </div>
                    </div>
                    <div v-if="editavel" class="col-auto">
                        <div class="w-full h-4rem bg-gray-50 border-bottom-1 border-200 flex justify-content-center align-items-center px-2">
                            <Button class="px-4" icon="pi pi-plus" icon-class="text-xl" text @click.stop="adicionarOpcao()" />
                        </div>
                        <div
                            v-for="(_, index) in pergunta.formularioInstrucaoResposta"
                            :class="{ 'bg-gray-50': (index + 1) % 2 }"
                            :key="index"
                            class="w-full h-4rem flex align-items-center px-4 border-bottom-1 border-200"
                        />
                    </div>
                </div>
            </div>
            <Button
                v-if="editavel"
                class="px-4 mt-4 text-lg font-semibold"
                icon="pi pi-plus"
                icon-class="text-lg font-semibold"
                label="Adicionar instrução"
                text
                @click.stop="adicionarInstrucao()"
            />
            <div v-if="editavel" class="flex justify-content-end align-items-center">
                Redirecionar para:
                <DropdownGroup
                    v-model="pergunta.formularioRedirecionamento"
                    :items="itensRedirecionamento"
                    class="ml-3"
                    input-class="p-3 text-xl w-full md:w-24rem"
                    item-text="label"
                    item-value="id"
                />
            </div>
        </div>
        <div v-if="editavel" class="text-xl pt-6 pb-2">
            <div class="grid align-items-center">
                <div class="col-5 text-800">Pergunta destinada ao gênero:</div>
                <Dropdown
                    v-model="pergunta.direcionamentoGenero"
                    :options="perguntaDirecionadaOptions"
                    class="col p-1"
                    style="max-width: 300px"
                    option-label="label"
                    option-value="value"
                />
            </div>
            <div v-if="pergunta.tipoFormularioId === idTipoFichaClinica" class="mt-3 grid align-items-center">
                <label for="tipoExame" class="col-5 text-800">Para qual tipo de exame?</label>
                <Dropdown
                    id="tipoExame"
                    v-model="pergunta.direcionamentoTipoExame"
                    class="col p-1"
                    style="max-width: 300px"
                    :options="tiposExames"
                    optionLabel="descricao"
                    option-value="id"
                />
            </div>
            <div class="mt-3 grid align-items-center">
                <div class="col-5 flex align-items-center gap-2">
                    <InputSwitch id="observacao" v-model="pergunta.adicionarObservacao" />
                    <label for="observacao">Adicionar observação</label>
                </div>
                <InputText
                    v-model="pergunta.labelObservacao"
                    class="col"
                    style="max-width: 300px"
                    placeholder="Descrição"
                    :disabled="!pergunta.adicionarObservacao"
                />
            </div>
            <div class="flex justify-content-between mt-6">
                <div class="flex gap-4">
                    <Button
                        class="p-0 text-600"
                        icon="pi pi-trash"
                        icon-class="text-xl text-800"
                        label="Excluir"
                        text
                        @click.stop="emit('excluir')"
                    />
                    <Button
                        class="p-0 ml-4 text-600"
                        icon="pi pi-clone"
                        icon-class="text-xl text-800"
                        label="Duplicar"
                        text
                        @click.stop="emit('duplicar')"
                    />
                    <div>
                        <Button
                            :disabled="!aumentarOrdem"
                            class="p-0 text-600 ml-6"
                            icon="pi pi-arrow-down"
                            text
                            @click.stop="emit('aumentarOrdem')"
                        />
                        <Button :disabled="!diminuirOrdem" class="p-0 text-600" icon="pi pi-arrow-up" text @click.stop="emit('diminuirOrdem')" />
                    </div>
                </div>
                <div class="flex gap-4 text-600">
                    <div v-if="pergunta.tipoResposta === EnumTipoRespostaFormulario.MULTIPLA_ESCOLHA" class="flex align-items-center">
                        <InputSwitch v-model="pergunta.variasRespostas" style="height: 19px; width: 36px" input-id="variasRespostas" />
                        <label class="ml-2" for="variasRespostas">Várias respostas</label>
                    </div>
                    <div class="flex align-items-center">
                        <InputSwitch v-model="pergunta.obrigatorio" style="height: 19px; width: 36px" input-id="obrigatoria" />
                        <label class="ml-2" for="obrigatoria">Obrigatória</label>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!editavel && pergunta.labelObservacao" class="text-xl pt-2 pb-2">
            <div class="flex flex-row align-items-center">
                <div class="text text-600 font-bold">{{ pergunta.labelObservacao }}</div>
            </div>
            <div class="flex flex-row align-items-center py-2 border-round-md">
                <InputText class="border-round-md border-bottom-1 shadow-none text-xl mt-2 py-3 w-6" disabled placeholder="Insira sua resposta" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps, defineEmits, ref, watch, onMounted } from 'vue';
import { helpers, required } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import EnumTipoRespostaFormulario from '@/enums/EnumTipoRespostaFormulario';
import useVuelidate from '@vuelidate/core';
import DropdownGroup from './DropdownGroup.vue';
import AreaMedicaService from '@/services/AreaMedicaService';

const emit = defineEmits(['update:modelValue', 'aumentarOrdem', 'diminuirOrdem', 'duplicar', 'excluir', 'selecionar']);
const props = defineProps({
    aumentarOrdem: {
        type: Boolean,
        default: true
    },
    diminuirOrdem: {
        type: Boolean,
        default: true
    },
    editavel: {
        type: Boolean,
        default: false
    },
    itensRedirecionamento: {
        type: Array,
        default: () => []
    },
    modelValue: {
        type: Object,
        required: true
    },
    numero: {
        type: Number,
        required: true
    }
});

const novaOpcao = ref('');
const pergunta = ref({ ...props.modelValue });
const tiposDeResposta = [
    {
        label: 'Dissertativa',
        value: 'DISSERTATIVA'
    },
    {
        label: 'Múltipla Escolha',
        value: 'MULTIPLA_ESCOLHA'
    },
    {
        label: 'Tabela',
        value: 'TABELA'
    }
];
const validacoes = {
    nomePergunta: {
        required: helpers.withMessage('Este campo é obrigatório', required)
    },
    formularioInstrucaoResposta: {
        $each: helpers.forEach({
            textoInstrucao: {
                required: helpers.withMessage('Este campo é obrigatório', required)
            }
        })
    },
    formularioOpcaoResposta: {
        $each: helpers.forEach({
            textoOpcao: {
                required: helpers.withMessage('Este campo é obrigatório', required)
            }
        })
    }
};
const toast = useToast();

const v$ = useVuelidate(validacoes, pergunta);

watch(
    pergunta,
    (novoValor) => {
        if (JSON.stringify(novoValor) !== JSON.stringify(props.modelValue)) {
            emit('update:modelValue', novoValor);
        }
    },
    { deep: true }
);
watch(
    () => props.modelValue,
    (novoValor) => {
        if (JSON.stringify(novoValor) !== JSON.stringify(pergunta.value)) {
            pergunta.value = novoValor;
        }
    }
);

async function validarCampos() {
    const validacao = await v$.value.$validate();
    if (validacao) v$.value.$reset();
}
function adicionarInstrucao() {
    const instrucoes = pergunta.value.formularioInstrucaoResposta;
    if (instrucoes.length >= 10) {
        toast.add({
            severity: 'error',
            summary: 'Limite de opções de instruções',
            life: 4000,
            detail: 'Você atingiu o limite máximo de instruções'
        });
        return;
    }
    const instrucao = {
        redirecionamentoId: crypto.randomUUID(),
        textoInstrucao: `Instrucao ${instrucoes.length + 1}`
    };

    pergunta.value.formularioInstrucaoResposta.push(instrucao);
}
function adicionarOpcao() {
    const opcoes = pergunta.value.formularioOpcaoResposta;

    if (
        (pergunta.value.tipoResposta === EnumTipoRespostaFormulario.MULTIPLA_ESCOLHA && opcoes.length >= 10) ||
        (pergunta.value.tipoResposta === EnumTipoRespostaFormulario.TABELA && opcoes.length >= 3)
    ) {
        toast.add({
            severity: 'error',
            summary: 'Limite de opções de resposta',
            life: 4000,
            detail: 'Você atingiu o limite máximo de opções de resposta'
        });
        return;
    }

    const indexOutros = opcoes.findIndex((opcao) => opcao.outros);

    const opcao = {
        textoOpcao: novaOpcao.value,
        redirecionamentoId: crypto.randomUUID()
    };
    if (indexOutros == -1) pergunta.value.formularioOpcaoResposta.push(opcao);
    else pergunta.value.formularioOpcaoResposta.splice(indexOutros, 0, opcao);

    novaOpcao.value = '';
}
function adicionarOpcaoOutros() {
    const opcoes = pergunta.value.formularioOpcaoResposta;
    if (opcoes.some((opcao) => opcao.outros)) return;

    if (opcoes.length >= 10) {
        toast.add({
            severity: 'error',
            summary: 'Limite de opções de resposta',
            life: 4000,
            detail: 'Você atingiu o limite máximo de opções de resposta'
        });
        return;
    }

    pergunta.value.formularioOpcaoResposta.push({
        redirecionamentoId: crypto.randomUUID(),
        textoOpcao: 'Outros',
        outros: true
    });
}
function alterarTipoResposta(tipoResposta) {
    if (tipoResposta === EnumTipoRespostaFormulario.MULTIPLA_ESCOLHA) {
        pergunta.value.formularioRedirecionamento = null;
    }
    if (pergunta.value.tipoResposta === EnumTipoRespostaFormulario.MULTIPLA_ESCOLHA) {
        pergunta.value.formularioOpcaoResposta = [];
    }
    if (pergunta.value.tipoResposta === EnumTipoRespostaFormulario.TABELA) {
        pergunta.value.formularioOpcaoResposta = [];
        pergunta.value.formularioInstrucaoResposta = [];
    }

    pergunta.value.tipoResposta = tipoResposta;
}
function removerInstrucao(redirecionamentoId) {
    const instrucoes = pergunta.value.formularioInstrucaoResposta;
    const index = instrucoes.findIndex((instrucao) => {
        return instrucao.redirecionamentoId === redirecionamentoId;
    });

    if (pergunta.value.id) instrucoes[index].excluido = true;
    else instrucoes.splice(index, 1);
}
function removerOpcao(redirecionamentoId) {
    const opcoes = pergunta.value.formularioOpcaoResposta;
    const index = opcoes.findIndex((opcao) => {
        return opcao.redirecionamentoId === redirecionamentoId;
    });

    if (opcoes[index].id) opcoes[index].excluido = true;
    else opcoes.splice(index, 1);
}

const perguntaDirecionadaOptions = [
    {
        label: 'Ambos',
        value: 'AMBOS'
    },
    {
        label: 'Gênero Masculino',
        value: 'MASCULINO'
    },
    {
        label: 'Gênero Feminino',
        value: 'FEMININO'
    }
];

const idTipoFichaClinica = 30;
const serviceTiposExames = new AreaMedicaService('/tipo_exames');
const tiposExames = ref([]);

onMounted(async () => {
    const { data } = await serviceTiposExames.findAll({});
    tiposExames.value = [
        {
            descricao: 'Todos',
            id: 99
        },
        ...data
    ];
});

watch(
    () => pergunta.value.adicionarObservacao,
    (val) => {
        if (!val) {
            pergunta.value.labelObservacao = '';
        }
    }
);
</script>
