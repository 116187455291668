import AreaService from '@/services/AreaMedicaService';
import { showError, showSuccess } from '@/utils/Toast';
import { getClientBase } from '@/services/http';

export default class AgendamentoService {
    constructor(toast) {
        this.$toast = toast;
        this.$service = new AreaService('/agendamentos');
    }

    async save(form, funcionario) {
        const agendamento = {};

        const observacao = `${funcionario?.observacao ?? ''}\n\n${form?.observacao ?? ''}`.trim() || null;
        agendamento.quemAgenda = form.quemAgenda;
        agendamento.quemAvisa = form.quemAvisa;
        agendamento.nomeSolicitante = form.nome ?? form.nomeSolicitante;
        agendamento.procedimentos = funcionario.procedimentos;
        agendamento.riscos = funcionario?.riscos;
        agendamento.nomeFuncionario = funcionario.name;
        agendamento.funcionario = funcionario;
        agendamento.cliente = funcionario.customerId;
        agendamento.status = { id: 1 };
        agendamento.pcd = funcionario.pcd;
        agendamento.resideForaDoPais = !!form.resideForaDoPais;
        agendamento.telefoneFuncionario = funcionario.phone;
        agendamento.emailFuncionario = funcionario.email;
        agendamento.dataSugestaoInicial = form.intervaloSugerido[0];
        agendamento.dataSugestaoFinal = form.intervaloSugerido[1];
        agendamento.tipoExame = form.tipoExame;
        agendamento.prestador = form.prestador;
        agendamento.cidade = form.cidade;
        agendamento.emailSolicitante = form.email ?? form.emailSolicitante;
        agendamento.telefoneSolicitante = form.telefone ?? form.telefoneSolicitante;
        agendamento.periodo = form.periodo;
        agendamento.dataHomologacao = funcionario.dataHomologacao;
        agendamento.detalheConsulta = funcionario.detalheConsulta;
        agendamento.dataLiberacao = funcionario.dataLiberacao;
        agendamento.tipoRetorno = funcionario.tipoRetorno;
        agendamento.questionarioRisco = funcionario.questionarioRisco;
        agendamento.questionarioHierarquiaId = funcionario.questionarioHierarquiaId;
        agendamento.horarioMarcadoPrestador = form.horarioMarcado;
        agendamento.procedimentosFromSoc = funcionario.procedimentosFromSoc;
        agendamento.observacao = observacao;
        agendamento.asoEmDia = funcionario?.asoEmDia;
        agendamento.direcionarParaAtendente = funcionario?.direcionarParaAtendente;
        /** TODO Está informação não está sendo utilizada no Agendamento, nem existe campo para salvar esses dados.
         ** Porém na pré solicitação existe.
         agendamento.informacoesPcd = JSON.stringify(funcionario.informacoesDeficienciasPcd);
         */

        agendamento.telefoneSolicitante = agendamento.telefoneSolicitante
            ? agendamento.telefoneSolicitante.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
            : null;
        agendamento.telefoneFuncionario = agendamento.telefoneFuncionario
            ? agendamento.telefoneFuncionario.replace('(', '').replace(')', '').replace('-', '').replaceAll(' ', '')
            : null;

        try {
            const solicitanteInvalido = !agendamento?.telefoneSolicitante;
            if (solicitanteInvalido) {
                throw new Error('Telefone do solicitante é obrigatório');
            }
            const { data } = await this.$service.save(agendamento);
            if (funcionario?.anexos && funcionario?.anexos.length) {
                const anexosAppendUpload = funcionario?.anexos.map((anexo) => {
                    const formData = new FormData();
                    formData.append('file', anexo.arquivo);
                    formData.append('origem', 'AGENDAMENTO');
                    formData.append('origemId', data.agendamentoId);
                    formData.append('tipo', anexo.tipo);
                    return formData;
                });
                for (const anexo of anexosAppendUpload) {
                    await getClientBase().post('/anexo', anexo);
                }
            }
            showSuccess(this.$toast, `Agendamento do colaborador ${funcionario.name} salvo com sucesso`);
            return true;
        } catch (error) {
            showError(this.$toast, error);
            return false;
        }
    }
}
