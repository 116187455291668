<script setup>
import BaseService from '@/services/BaseService';
import { ref, computed, watch, defineExpose } from 'vue';
import { helpers, requiredIf } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const loading = ref(false);
const visivel = ref(false);
const filtros = ref({
    dataPagamento: null,
    horaInicial: null,
    horaFinal: null
});

const desativarHora = computed(() => {
    return !filtros.value?.dataPagamento?.length || filtros.value?.dataPagamento?.[1];
});

const rules = {
    horaFinal: {
        required: helpers.withMessage(
            'Campo deve ser preenchido',
            requiredIf(() => filtros.value?.horaInicial)
        ),
        validate: helpers.withMessage('Horário inválido', validarHorario)
    },
    horaInicial: {
        required: helpers.withMessage(
            'Campo deve ser preenchido',
            requiredIf(() => filtros.value?.dataPagamento?.[0] && !filtros.value?.dataPagamento?.[1])
        ),
        validate: helpers.withMessage('Horário inválido', validarHorario)
    }
};
const v$ = useVuelidate(rules, filtros);

watch(
    () => filtros.value?.dataPagamento,
    (novoValor) => {
        if (novoValor?.[1]) {
            filtros.value.horaInicial = null;
            filtros.value.horaFinal = null;
        }
    }
);

async function gerarPdf() {
    loading.value = true;
    try {
        const service = new BaseService('/deposito-antecipado/gerar-impressao-massa');
        const { data } = await service.findAll({
            filtrosExtras: filtros.value
        });
        window.open(data.url, '_blank');
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: error.response?.data?.message || 'Erro ao gerar PDF',
            life: 3000
        });
    }
    loading.value = false;
}

function abrir() {
    filtros.value = {
        dataPagamento: null,
        horaInicial: null,
        horaFinal: null
    };
    visivel.value = true;
}

function fechar() {
    visivel.value = false;
}

function validarHorario(horario) {
    if (!horario) return true;
    const [hora, minuto] = horario.split(':');
    return hora <= 23 && minuto <= 59;
}

defineExpose({
    abrir
});
</script>

<template>
    <Dialog :visible="visivel" modal style="width: 400px; max-width: 90%" @update:visible="fechar()">
        <template #header>
            <h4>Gerar PDF</h4>
        </template>
        <AppLoadingWrapper v-if="loading" />
        <div v-else class="grid">
            <div class="col-12">
                <div for="dataPagamento" class="text-gray-600 py-1">Data de pagamento</div>
                <Calendar
                    id="dataPagamento"
                    v-model="filtros.dataPagamento"
                    :disabledDays="[0, 6]"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                    hideOnRangeSelection
                    selectionMode="range"
                    showButtonBar
                    showIcon
                />
            </div>

            <div class="col py-0 flex flex-column justify-content-end">
                <div for="dataPagamento" class="text-gray-600 py-1">Hora</div>
                <InputMask v-model="filtros.horaInicial" :disabled="desativarHora" class="w-full" mask="99:99" />
                <small v-if="v$.horaInicial.$error" class="p-error w-full">
                    {{ v$.horaInicial.$errors[0].$message }}
                </small>
                <small v-else class="p-error w-full" style="visibility: hidden">&nbsp;</small>
            </div>
            <div class="col-1 py-0 flex justify-content-center align-items-center">Até</div>
            <div class="col py-0 flex flex-column justify-content-end">
                <InputMask v-model="filtros.horaFinal" :disabled="desativarHora" class="w-full" mask="99:99" />
                <small v-if="v$.horaFinal.$error" class="p-error w-full">
                    {{ v$.horaFinal.$errors[0].$message }}
                </small>
                <small v-else class="p-error w-full" style="visibility: hidden">&nbsp;</small>
            </div>
        </div>
        <template #footer>
            <Button class="p-button p-button-text" label="Cancelar" @click="fechar" />
            <Button :disabled="!filtros?.dataPagamento?.length" class="p-button h-full m-0" label="Gerar" @click="gerarPdf" />
        </template>
    </Dialog>
</template>
