<template>
    <div class="card flex flex-column p-6" style="height: calc(100dvh - 105px)">
        <Button v-if="voltar" class="w-min px-1" icon="pi pi-chevron-left" label="Voltar" text @click="emit('voltar')" />
        <Button v-if="cancelar" class="w-min px-1" icon="pi pi-times" label="Cancelar" text @click="emit('cancelar')" />
        <div class="flex align-items-center my-4">
            <router-link :to="{ path: '/' }">
                <i class="pi pi-home text-700" style="font-size: 1.5rem" />
            </router-link>
            <div v-for="(crumb, index) in breadcrumbs" :key="index">
                <template v-if="!crumb.currentRoute">
                <i class="pi pi-chevron-right text-700 mx-3" style="font-size: 1rem" />
                <router-link :to="crumb.to">
                    <span :class="`${crumb.currentRoute ? 'font-light' : 'font-medium'} text-700`">
                        {{ crumb.label }}
                    </span>
                </router-link>
            </template>
            </div>
        </div>
        <div class="flex flex-column mb-0 md:flex-row md:justify-content-between md:align-items-center">
            <div class="flex flex-row gap-1 align-items-center text-800 mb-0">
                <AppInfoManual nomeTelaDoManual="gerenciar-aso" />
                <h4 class="font-medium my-2">{{ props.titulo }}</h4>
            </div>
        </div>
        <div class="w-full my-2" style="height: 1px; background: #ced4da" />
        <div v-if="criar" class="flex flex-column mb-4 md:flex-row md:justify-content-between md:align-items-center">
        <div class="p-fluid mt-0 w-full md:mt-1 md:w-5">
            <InputText v-if="criar" v-model="filter" style="width: 85%" placeholder="Pesquisar" @change="emitSearch" />
        </div>
        <div class="mt-2 ml-auto md:mt-0">
            <Button v-if="criar" :icon="isMobile ? 'pi pi-plus' : ''" :label="isMobile ? '' : 'Novo ASO'" class="m-2 px-4" @click="emit('criar')" />
        </div>
        </div>
        <slot />
    </div>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits, onMounted, onUnmounted } from 'vue';
import AppInfoManual from '../../../../components/AppInfoManual.vue';
import { useRoute } from 'vue-router';

const emit = defineEmits(['cancelar', 'criar', 'salvar', 'listar', 'voltar', 'search']);
const props = defineProps({
    cancelar: {
        type: Boolean,
        default: false
    },
    criar: {
        type: Boolean,
        default: false
    },
    salvar: {
        type: Boolean,
        default: false
    },
    titulo: {
        type: String,
        default: 'Titulo'
    },
    listar: {
        type: Boolean,
        default: false
    },
    voltar: {
        type: Boolean,
        default: false
    }
});

const route = useRoute();
const isMobile = ref(false);
const filter = ref('');
const breadcrumbs = computed(() => {
    return route.matched
        ?.filter((matchedRoute) => {
            return matchedRoute.name !== 'home' && !matchedRoute.meta?.defaultRoute;
        })
        .map((matchedRoute) => ({
            label: matchedRoute?.meta?.breadcrumbLabel ?? matchedRoute.name,
            to: matchedRoute.path,
            currentRoute: matchedRoute.path === route.path
        }));
});

onMounted(() => {
    verificarBreakpoint();
    window.addEventListener('resize', verificarBreakpoint);
});
onUnmounted(() => {
    window.removeEventListener('resize', verificarBreakpoint);
});

function verificarBreakpoint() {
    isMobile.value = window.innerWidth < 600;
}

function emitSearch() {
    emit('search', filter.value);
}
</script>
