<script setup>
import DialogAlterarValores from './dialogs/DialogAlterarValores';
import BaseService from '@/services/BaseService';
import { ref, computed, defineEmits, defineProps, defineExpose } from 'vue';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['atualizar']);
const props = defineProps({
    prestadorId: Number
});

const loading = ref(false);

const emAberto = computed(() => {
    return formatarParaReal(resumoDespositos.value?.emAberto);
});
const credito = computed(() => {
    return formatarParaReal(resumoDespositos.value?.credito);
});
const saldo = computed(() => {
    return formatarParaReal(calcularSaldo(resumoDespositos.value), false);
});
const reembolso = computed(() => {
    return formatarParaReal(resumoDespositos.value?.reembolso);
});

const calcularSaldo = (data) => {
    const valorAPagar = Number(data.emAberto) || 0;
    const valorCredito = Number(data.credito) || 0;
    const saldo = valorCredito - valorAPagar;

    let saldoFormatado;

    if (valorCredito && !valorAPagar) {
        saldoFormatado = valorCredito;
    }

    if (!valorCredito && valorAPagar) {
        saldoFormatado = valorAPagar;
    }

    if (valorAPagar && valorCredito < valorAPagar) {
        saldoFormatado = valorAPagar;
    }

    if (valorCredito && valorAPagar && valorCredito > valorAPagar) {
        saldoFormatado = saldo;
    }

    return saldoFormatado?.toString();
};

const toast = useToast();
const resumoDespositos = ref({});
async function buscarResumoDepositos() {
    loading.value = true;
    try {
        const url = 'agendamento-deposito/listar-extrato-prestadores';
        const service = new BaseService(url);
        const { data } = await service.findAll({
            filtrosExtras: { id: props.prestadorId }
        });

        resumoDespositos.value = data?.items[0];
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao buscar o resumo dos depósitos! ',
            life: 3000
        });
    }
    loading.value = false;
}

function atualizar() {
    emit('atualizar');
}

function formatarParaReal(valor, sinal = true) {
    if (!valor) return 'R$ 0,00';

    const valorFormatado = (+valor.replace('-', '')).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL'
    });

    if (sinal) {
        let sinalPrefixo = '';
        if (+valor < 0) {
            sinalPrefixo = '- ';
        } else if (+valor > 0) {
            sinalPrefixo = '+ ';
        }
        return sinalPrefixo + valorFormatado;
    }

    return valorFormatado;
}

const dialogAlterarValores = ref(null);
function abrirDialogAlterarValores() {
    dialogAlterarValores.value.abrir(props.prestadorId);
}

buscarResumoDepositos();
defineExpose({ buscarResumoDepositos });
</script>
<template>
    <div class="mt-5 border-1 border-200 border-round-sm" style="height: calc(100% - 24px)">
        <div class="w-full px-3 py-2 surface-100 font-medium text-700">Resumo</div>
        <div class="flex flex-column justify-content-end gap-3 p-4" style="height: calc(100% - 24px)">
            <div class="flex justify-content-between text-700" style="font-size: 12px">
                <div>Em aberto</div>
                <div>{{ emAberto }}</div>
            </div>
            <div class="flex justify-content-between text-700" style="font-size: 12px">
                <div>Credito</div>
                <div>{{ credito }}</div>
            </div>
            <div class="border-top-1 border-300 my-2" />
            <div class="flex justify-content-between text-600">
                <div class="font-bold" style="font-size: 14px">Saldo</div>
                <div
                    :class="{
                        'text-green-600': resumoDespositos?.saldo?.includes('+'),
                        ' p-error': resumoDespositos?.saldo?.includes('-')
                    }"
                    style="font-size: 14px"
                >
                    {{ saldo }}
                </div>
            </div>
            <Button class="p-button-outlined my-1" label="Alterar Valores" @click="abrirDialogAlterarValores" />
            <div class="flex justify-content-between text-700" style="font-size: 12px">
                <div>Reembolso</div>
                <div>{{ reembolso }}</div>
            </div>
        </div>
        <DialogAlterarValores ref="dialogAlterarValores" @atualizar="atualizar" />
    </div>
</template>
