<script setup>
import CabecalhoAcoes from './components/CabecalhoAcoes.vue';
import TabelaExtratos from './components/TabelaExtratos.vue';
import Breadcrumb from './components/Breadcrumb.vue';
import BaseService from '@/services/BaseService';
import { useToast } from 'primevue/usetoast';
import { ref } from 'vue';

const loading = ref(false);
const itensBreadcrumb = [
    {
        path: '/gestao-medica-v3/deposito-antecipado',
        title: 'Depósito Antecipado'
    }
];

const sortTable = ref(null);
const toast = useToast();
const metadadosTabela = ref({});
const extratos = ref([]);
async function getDepositosAntecipados() {
    loading.value = true;
    try {
        extratos.value = [];
        const service = new BaseService('agendamento-deposito/listar-extrato-prestadores');
        const { data } = await service.findAll(metadadosTabela.value);

        extratos.value = data.items.map((extrato) => {
            const sinalSaldo = +extrato.saldo < 0 ? '- ' : extrato.saldo > 0 ? '+ ' : '';
            const saldo = sinalSaldo + formatarParaReal(extrato.saldo);
            const emAberto = extrato.emAberto;
            const credito = extrato?.credito;
            const reembolso = extrato.reembolso ? formatarParaReal(extrato.reembolso) : '-';

            return { ...extrato, saldo, emAberto, credito, reembolso };
        });
        metadadosTabela.value.total = +data.meta.totalItems;
        metadadosTabela.value.limit = +data.meta.itemsPerPage;
        metadadosTabela.value.page = +data.meta.currentPage;
        metadadosTabela.value.sort = {};
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao buscar registros de deposito antecipado! ',
            life: 3000
        });
    }
    loading.value = false;
}

function atualizarMetadadosTabela(metadados) {
    if (metadados.page !== undefined) {
        metadadosTabela.value.page = metadados.page + 1;
    }
    if (metadados.sortField) {
        sortTable.value = metadados;
        metadadosTabela.value.sort = {
            [metadados.sortField]: metadados.sortOrder === 1 ? 'ASC' : 'DESC'
        };
    }

    if (sortTable.value) {
        metadadosTabela.value.sort = {
            [sortTable.value.sortField]: sortTable.value.sortOrder === 1 ? 'ASC' : 'DESC'
        };
    }

    getDepositosAntecipados();
}

function formatarParaReal(valor) {
    if (!+valor) return valor;
    return (+valor.replace('-', '')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

getDepositosAntecipados();
</script>
<template>
    <div class="card flex flex-column" style="padding: 32px; gap: 16px">
        <Breadcrumb :rotas="itensBreadcrumb" />
        <h1 class="font-semibold text-2xl m-0">Extratos</h1>
        <CabecalhoAcoes v-model:loading="loading" v-model:metadadosTabela="metadadosTabela" @atualizar="getDepositosAntecipados" />
        <TabelaExtratos
            v-model:loading="loading"
            :metadados="metadadosTabela"
            :value="extratos"
            @atualizar="getDepositosAntecipados"
            @metadados="atualizarMetadadosTabela"
        />
    </div>
</template>
