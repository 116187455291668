<template>
    <div class="container" style="height: 100%">
        <div class="input-section text-700">
            Nome do ASO:
            <span style="color: #ff7171"> * </span>
            <InputText ref="nome" v-model="aso.nome" style="width: 100%" :class="{ 'p-invalid': v$.aso.nome.$error }" />
            <small v-if="v$.aso.nome.$error" class="p-error w-full">{{ v$.aso.nome.$errors[0].$message }}</small>
        </div>
        <div class="pdf-and-list-section">
            <div class="pdf-section">
                <iframe :src="pdfSrc" style="width: 100%; height: 100%; overflow: auto; border: none" frameborder="0"></iframe>
            </div>
            <div class="list-section">
                <div class="col-span-1">
                    <h3 class="lines font-medium">Personalização</h3>
                    <h4 class="lines font-medium">Identidade da empresa</h4>

                    <h5 class="logo flex text-900">
                        Logo
                        <div class="field-checkbox">
                            <InputSwitch id="logo" v-model="aso.isLogo" />
                        </div>
                    </h5>
                    <div class="w-full my-4" style="height: 1px; background: #ced4da" />
                    <h4 class="lines font-medium">Parecer</h4>
                    <h5 class="logo flex text-900">
                        Todos
                        <div class="field-checkbox">
                            <InputSwitch id="todos" v-model="selecionarTodos" />
                        </div>
                    </h5>
                    <h5 class="logo flex">
                        Manipulação de alimentos
                        <div class="field-checkbox">
                            <InputSwitch
                                id="manipulacaoAlimentos"
                                v-model="asoParecer.manipulacaoAlimentos"
                                @change="updateParecer('manipulacaoAlimentos', checkboxes.manipulacaoAlimentos)"
                            />
                        </div>
                    </h5>
                    <h5 class="logo flex text-900">
                        Uso de proteção respiratória
                        <div class="field-checkbox">
                            <InputSwitch
                                id="protecaoRespiratoria"
                                v-model="asoParecer.protecaoRespiratoria"
                                @change="updateParecer('protecaoRespiratoria', checkboxes.protecaoRespiratoria)"
                            />
                        </div>
                    </h5>
                    <h5 class="logo flex text-900">
                        Trabalho com eletricidade
                        <div class="field-checkbox">
                            <InputSwitch
                                id="trabalhoEletricidade"
                                v-model="asoParecer.trabalhoEletricidade"
                                @change="updateParecer('trabalhoEletricidade', checkboxes.trabalhoEletricidade)"
                            />
                        </div>
                    </h5>
                    <h5 class="logo flex text-900">
                        Condução de veículos motorizados
                        <div class="field-checkbox">
                            <InputSwitch
                                id="veiculosMotorizados"
                                v-model="asoParecer.veiculosMotorizados"
                                @change="updateParecer('veiculosMotorizados', checkboxes.veiculosMotorizados)"
                            />
                        </div>
                    </h5>
                    <h5 class="logo flex text-900">
                        Condução de veículos motorizados industriais
                        <div class="field-checkbox">
                            <InputSwitch
                                id="veiculosMotorizadosIndustriais"
                                v-model="asoParecer.veiculosMotorizadosIndustriais"
                                @change="updateParecer('veiculosMotorizadosIndustriais', checkboxes.veiculosMotorizadosIndustriais)"
                            />
                        </div>
                    </h5>
                    <h5 class="logo flex text-900">
                        Participação em brigadas de emergência
                        <div class="field-checkbox">
                            <InputSwitch
                                id="brigadasEmergencia"
                                v-model="asoParecer.brigadasEmergencia"
                                @change="updateParecer('brigadasEmergencia', checkboxes.brigadasEmergencia)"
                            />
                        </div>
                    </h5>
                    <div class="logo flex">
                        <div class="mt-4 ml-auto md:mt-0">
                            <Button label="Salvar" class="m-2 px-4" @click="validarFormulario" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, onMounted, watch, computed } from 'vue';
import { helpers, required } from '@vuelidate/validators';
import { useToast } from 'primevue/usetoast';
import axios from 'axios';
import useVuelidate from '@vuelidate/core';
import BaseService from '@/services/BaseService';
const serviceAso = new BaseService('aso');
const serviceVinculoClient = new BaseService('vinculo-customer/aso');
import { getCompany } from '@/services/auth';
import AsoParecerEnum from '../../../../enums/EnumAsoParecer';

const emit = defineEmits(['update:modelValue', 'update-height', 'listar']);
const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    }
});

const checkboxes = {
    manipulacaoAlimentos: [AsoParecerEnum.APTO_PARA_FUNCAO, AsoParecerEnum.INAPTO_PARA_FUNCAO],
    protecaoRespiratoria: [AsoParecerEnum.APTO_PARA_TRABALHO_EM_ESPACOS_CONFINADOS, AsoParecerEnum.INAPTO_PARA_TRABALHO_EM_ESPACOS_CONFINADOS],
    trabalhoEletricidade: [AsoParecerEnum.APTO_PARA_TRABALHO_EM_ALTURA, AsoParecerEnum.INAPTO_PARA_TRABALHO_EM_ALTURA],
    veiculosMotorizados: [AsoParecerEnum.APTO_PARA_FUNCAO, AsoParecerEnum.INAPTO_PARA_FUNCAO],
    veiculosMotorizadosIndustriais: [AsoParecerEnum.APTO_PARA_FUNCAO, AsoParecerEnum.INAPTO_PARA_FUNCAO],
    brigadasEmergencia: [AsoParecerEnum.APTO_PARA_EXPOSICAO_RADIOACOES_IONIZANTES, AsoParecerEnum.INAPTO_PARA_EXPOSICAO_RADIOACOES_IONIZANTES]
};

const aso = ref(structuredClone(props.modelValue));
const asoParecer = ref({
    manipulacaoAlimentos: false,
    protecaoRespiratoria: false,
    trabalhoEletricidade: false,
    veiculosMotorizados: false,
    veiculosMotorizadosIndustriais: false,
    brigadasEmergencia: false
});

const pdfSrc = ref('');
const toast = useToast();

const validacoes = {
    aso: {
        nome: {
            required: helpers.withMessage('Este campo é obrigatório', required)
        }
    }
};

const v$ = useVuelidate(validacoes, { aso });

watch(aso, (aso) => emit('update:modelValue', aso), { deep: true });
watch(
    () => props.modelValue,
    (valor) => {
        if (JSON.stringify(valor) !== JSON.stringify(aso.value)) {
            aso.value = valor;
        }
    }
);

onMounted(() => {
    buscarAsoPadrao();
    updateHeight();
});

function updateHeight() {
    emit('update-height');
}

async function buscarAsoPadrao(logoUrl = '', pareceres) {
    try {
        const { companyLogo, name } = await getCompanyLogo();
        let pareceresSelecionados = [...aso.value.parecer];
        if (pareceres) pareceresSelecionados = pareceres;
        if (aso.value.id) {
            var clienteNameVinculo = await getNameClienteVinculo(aso.value.id);
            var logoUrlById = aso.value.isLogo ? await getLogoByAsoId(aso.value.id) : logoUrl;
        }
        pareceresSelecionados.forEach((parecer) => {
            for (const key in checkboxes) {
                if (checkboxes[key].includes(parecer)) {
                    asoParecer.value[key] = true;
                }
            }
        });
        const { data } = await axios.post(
            process.env.VUE_APP_API_GERADOR_PDF + `/gerador-pdf/visualizar`,
            {
                tipo_documento: 'ASO',
                parametros: {
                    empresa: {
                        name: aso.value.id ? clienteNameVinculo : '',
                        inscricao: '',
                        tradeName: '',
                        logo: aso.value.id ? logoUrlById : ''
                    },
                    company: {
                        name: name,
                        logo: companyLogo
                    },
                    nomeFuncionario: '',
                    exame: {
                        medico: '',
                        exames: '',
                        tipoExame: ''
                    },
                    medicoResponsavel: {
                        crm: '',
                        uf: ''
                    },
                    funcionario: {
                        code: null,
                        name: '',
                        sex: '',
                        rg: '',
                        cpf: '',
                        position: {
                            name: '',
                            department: ''
                        },
                        admissionDate: '',
                        dataNascimento: ''
                    },
                    tipoExame: {
                        descricao: ''
                    },
                    parecer: pareceresSelecionados
                }
            },
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        );
        const pdfBlob = new Blob([data], { type: 'application/pdf' });
        pdfSrc.value = URL.createObjectURL(pdfBlob);
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar ASO',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
}

const selecionarTodos = computed({
    get() {
        return Object.values(asoParecer.value).every((checked) => checked);
    },
    set(val) {
        Object.keys(asoParecer.value).forEach((key) => {
            asoParecer.value[key] = val;
        });

        if (val) {
            const pareceresSelecionados = Object.keys(checkboxes);
            const convertParecerToEnum = pareceresSelecionados.map((parecer) => checkboxes[parecer]);
            const pareceresFormatados = [...new Set(convertParecerToEnum.flat())];
            aso.value.parecer = pareceresFormatados;
        } else {
            aso.value.parecer = [];
        }

        buscarAsoPadrao('', aso.value.parecer);
    }
});

async function updateParecer(field, values) {
    let pareceres = [...aso.value.parecer] || [];
    pareceres = pareceres.filter((parecer) => !checkboxes[field].includes(parecer));

    if (asoParecer.value[field]) {
        pareceres = pareceres.concat(values);
    }
    aso.value.parecer = [...new Set(pareceres)];

    if (aso.value.id) {
        var clienteNameVinculo = await getNameClienteVinculo(aso.value.id);
        var logoUrl = aso.value.isLogo ? await getLogoByAsoId(aso.value.id) : '';
    }
    const { companyLogo, name } = await getCompanyLogo();
    const { data } = await axios.post(
        process.env.VUE_APP_API_GERADOR_PDF + `/gerador-pdf/visualizar`,
        {
            tipo_documento: 'ASO',
            parametros: {
                empresa: {
                    name: aso.value.id ? clienteNameVinculo : '',
                    inscricao: '',
                    tradeName: '',
                    logo: aso.value.id ? logoUrl : ''
                },
                company: {
                    name: name,
                    logo: companyLogo
                },
                nomeFuncionario: '',
                exame: {
                    medico: '',
                    exames: '',
                    tipoExame: ''
                },
                medicoResponsavel: {
                    crm: '',
                    uf: ''
                },
                funcionario: {
                    code: null,
                    name: '',
                    sex: '',
                    rg: '',
                    cpf: '',
                    position: {
                        name: '',
                        department: ''
                    },
                    admissionDate: '',
                    dataNascimento: ''
                },
                tipoExame: {
                    descricao: ''
                },
                parecer: aso.value.parecer
            }
        },
        {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    );
    const pdfBlob = new Blob([data], { type: 'application/pdf' });
    pdfSrc.value = URL.createObjectURL(pdfBlob);
}

watch(
    () => aso.value.isLogo,
    async () => {
        await buscarAsoPadrao();
    },
    { deep: true, immediate: true }
);
watch(
    () => aso.value.parecer,
    (newParecer) => {
        Object.keys(checkboxes).forEach((key) => {
            asoParecer.value[key] = checkboxes[key].some((parecer) => newParecer.includes(parecer));
        });
    },
    { deep: true, immediate: true }
);

const parecerSelecionados = computed(() => {
    const pareceres = asoParecer.value;
    const selecionados = [];

    for (const key in pareceres) {
        if (pareceres[key]) {
            selecionados.push(key);
        }
    }
    return selecionados;
});

async function salvarAso() {
    try {
        await serviceAso.save({
            ...aso.value,
            isLogo: aso.value.isLogo,
            isParecer: parecerSelecionados.value.length > 0,
            parecer: aso.value.parecer,
            acessoTodosClientes: false
        });
        toast.add({
            severity: 'success',
            summary: 'ASO salvo com sucesso',
            life: 4000
        });
        emit('listar');
        resetarFormAso();
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar ASO',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
}

async function getLogoByAsoId(asoId) {
    const { data } = await serviceVinculoClient.findById(asoId);
    if (data.items[0]?.urlLogo === null || data.items[0]?.urlLogo === undefined) {
        urlLogo = '';
        return urlLogo;
    }
    let urlLogo = data.items[0]?.urlLogo.split('/').pop();

    return urlLogo;
}

async function getNameClienteVinculo(asoId) {
    const { data } = await serviceVinculoClient.findById(asoId);
    let nomeClienteVinculo;
    if (data.items[0]?.name === null || data.items[0]?.name === undefined) {
        nomeClienteVinculo = '';
        return nomeClienteVinculo;
    }
    nomeClienteVinculo = data.items[0]?.name;

    return nomeClienteVinculo;
}

async function getCompanyLogo() {
    const { urlLogo, name } = await getCompany();
    if (urlLogo === null) {
        const companyLogo = '';
        return { companyLogo, name };
    }
    const companyLogo = urlLogo.split('/').pop();
    return { companyLogo, name };
}

async function validarFormulario() {
    const validacao = await v$.value.$validate();
    if (validacao) {
        salvarAso();
    } else {
        toast.add({
            severity: 'error',
            summary: 'Erro na validação',
            life: 4000,
            detail: 'Por favor, preencha os campos obrigatórios'
        });
    }
}

function resetarFormAso() {
    aso.value = {
        nome: '',
        isLogo: false,
        isParecer: false,
        parecer: [],
        acessoTodosClientes: false
    };
}

function structuredClone(objeto) {
    const objetoString = JSON.stringify(objeto);
    return JSON.parse(objetoString);
}
</script>
<style scoped>
.container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.input-section {
    padding-bottom: 1rem;
}

.pdf-and-list-section {
    display: flex;
    gap: 24px;
    width: 100%;
    flex-wrap: wrap;
}

.pdf-section {
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.1);
}

.list-section {
    width: 100%;
    min-width: 360px;
    max-width: 500px;
    padding: 1rem;
    background-color: #ffffff;
}

.field-checkbox {
    display: flex;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
    margin: 0.4rem 1rem;
}
.lines {
    font-size: 2rem;
}

@media (min-width: 576px) {
    .logo {
        font-size: 1rem;
    }
    .lines {
        font-size: 1rem;
    }
}

@media (min-width: 768px) {
    .logo {
        font-size: 1rem;
    }
    .lines {
        font-size: 10px;
    }
}

@media (min-width: 1024px) {
    .logo {
        font-size: 1rem;
    }
    .lines {
        font-size: 1rem;
    }
}

@media (min-width: 1400px) {
    .logo {
        font-size: 16px;
    }
    .lines {
        font-size: 16px;
    }
}
</style>
