import { RouterView } from 'vue-router';
const GestaoRiscoIndex = () => import('@/pages/gestao-risco/Index.vue');
const ClientesGroList = () => import('@/pages/gestao-risco/GridClientes.vue');
const UnidadesGroList = () => import('@/pages/gestao-risco/GridUnidades.vue');
const ElaboracaoGroList = () => import('@/pages/gestao-risco/GridElaboracao.vue');
const AmbienteGroList = () => import('@/pages/gestao-risco/GridAmbiente.vue');
const CaracterizacaoGroForm = () => import('@/pages/gestao-risco/FormCaracterizacao.vue');
const InventarioRiscoGroList = () => import('@/pages/gestao-risco/GridInventarioRisco.vue');

export const GestaoRisco = {
    path: '/gestao-risco',
    name: 'Gestão de risco',
    redirect: '/gestao-risco/clientes',
    component: RouterView,
    children: [
        {
            path: 'clientes',
            name: 'Gestão de riscos - Clientes - default',
            component: GestaoRiscoIndex,
            meta: {
                permission: 'gestaosesmt_gro_cliente:view',
                defaultRoute: true
            },
            children: [
                {
                    path: '',
                    name: 'Gestão de riscos - Clientes ',
                    component: ClientesGroList,
                    meta: {
                        breadcrumbLabel: 'Clientes',
                        permission: 'gestaosesmt_gro_cliente:view',
                        rotasAnteriores: [
                            {
                                label: 'Gestão de riscos',
                                to: '/gestao-risco/clientes'
                            },
                            {
                                label: 'Clientes',
                                to: '/gestao-risco/clientes'
                            }
                        ]
                    }
                },
                {
                    path: ':id/unidades',
                    name: 'Gestão de riscos - unidades',
                    component: UnidadesGroList,
                    meta: {
                        breadcrumbLabel: 'Unidades',
                        permission: 'gestaosesmt_gro_unidade:view',
                        rotasAnteriores: [
                            {
                                label: 'Gestão de riscos',
                                to: '/gestao-risco'
                            },
                            {
                                label: 'Clientes',
                                to: '/gestao-risco/clientes'
                            },
                            {
                                label: 'Unidades',
                                to: '/gestao-risco/clientes/:id/unidades'
                            }
                        ]
                    }
                },
                {
                    path: ':id/unidades/:idUnidade/elaboracao/:idElaboracao',
                    name: 'Gestão de riscos - elaboração',
                    component: ElaboracaoGroList,
                    meta: {
                        breadcrumbLabel: 'Elaboracão',
                        permission: 'gestaosesmt_gro_unidade:view',
                        rotasAnteriores: [
                            {
                                label: 'Gestão de riscos',
                                to: '/gestao-risco'
                            },
                            {
                                label: 'Clientes',
                                to: '/gestao-risco/clientes'
                            },
                            {
                                label: 'Unidades',
                                to: '/gestao-risco/clientes/:id/unidades'
                            },
                            {
                                label: 'Elaboração',
                                to: '/gestao-risco/clientes/:id/unidades/:idUnidade/elaboracao/:idElaboracao'
                            }
                        ]
                    }
                },
                {
                    path: ':id/unidades/:idUnidade/elaboracao/:idElaboracao/ambiente/:visitaTecnicaId',
                    name: 'Gestão de riscos - ambiente',
                    component: AmbienteGroList,
                    meta: {
                        breadcrumbLabel: 'Ambiente',
                        permission: 'gestaosesmt_gro_unidade:view',
                        rotasAnteriores: [
                            {
                                label: 'Gestão de riscos',
                                to: '/gestao-risco'
                            },
                            {
                                label: 'Clientes',
                                to: '/gestao-risco/clientes'
                            },
                            {
                                label: 'Unidades',
                                to: '/gestao-risco/clientes/:id/unidades'
                            },
                            {
                                label: 'Elaboração',
                                to: '/gestao-risco/clientes/:id/unidades/:idUnidade/elaboracao/:idElaboracao'
                            },
                            {
                                label: 'Ambiente',
                                to: '/gestao-risco/clientes/:id/unidades/:idUnidade/elaboracao/:idElaboracao/ambiente/:visitaTecnicaId'
                            }
                        ]
                    }
                },
                {
                    path: ':id/unidades/:idUnidade/elaboracao/:idElaboracao/inventario-risco/:visitaTecnicaId',
                    name: 'Gestão de riscos - inventário de risco',
                    component: InventarioRiscoGroList,
                    meta: {
                        breadcrumbLabel: 'Inventário de risco',
                        permission: 'gestaosesmt_gro_unidade:view',
                        rotasAnteriores: [
                            {
                                label: 'Gestão de riscos',
                                to: '/gestao-risco'
                            },
                            {
                                label: 'Clientes',
                                to: '/gestao-risco/clientes'
                            },
                            {
                                label: 'Unidades',
                                to: '/gestao-risco/clientes/:id/unidades'
                            },
                            {
                                label: 'Elaboração',
                                to: '/gestao-risco/clientes/:id/unidades/:idUnidade/elaboracao/:idElaboracao'
                            },
                            {
                                label: 'Inventário de risco',
                                to: '/gestao-risco/clientes/:id/unidades/:idUnidade/elaboracao/:idElaboracao/inventario-risco/:visitaTecnicaId'
                            }
                        ]
                    }
                },
                {
                    path: ':id/unidades/:idUnidade/elaboracao/:idElaboracao/inventario-risco/:visitaTecnicaId/caracterizacao/:respostaperigoId?/setor/:setor?/cargo/:cargo?',
                    name: 'Gestão de riscos - caracterização',
                    component: CaracterizacaoGroForm,
                    meta: {
                        breadcrumbLabel: 'Caracterização',
                        permission: 'gestaosesmt_gro_unidade:view',
                        rotasAnteriores: [
                            {
                                label: 'Gestão de riscos',
                                to: '/gestao-risco'
                            },
                            {
                                label: 'Clientes',
                                to: '/gestao-risco/clientes'
                            },
                            {
                                label: 'Unidades',
                                to: '/gestao-risco/clientes/:id/unidades'
                            },
                            {
                                label: 'Elaboração',
                                to: '/gestao-risco/clientes/:id/unidades/:idUnidade/elaboracao/:idElaboracao'
                            },
                            {
                                label: 'Inventário de risco',
                                to: '/gestao-risco/clientes/:id/unidades/:idUnidade/elaboracao/:idElaboracao/inventario-risco/:visitaTecnicaId'
                            },
                            {
                                label: 'Caracterização',
                                to: '/gestao-risco/clientes/:id/unidades/:idUnidade/elaboracao/:idElaboracao/inventario-risco/:visitaTecnicaId/caracterizacao/:respostaperigoId?/setor/:setor?/cargo/:cargo?'
                            }
                        ]
                    }
                }
            ]
        }
    ]
};
