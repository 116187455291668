import axios from 'axios';
import { getIdsAuthorizedCustomers, getIdsAuthorizedCustomersBranches, logout } from './auth';
import { getFilterCustomerIds } from './filters';
import { getTenant, getToken } from '@/common/storage';

export function getClientBase(auxHeaders = {}, auxConfigs = {}) {
    return getClient(process.env.VUE_APP_API_BASE_URL, auxHeaders, auxConfigs);
}

export function getClientAreaMedica(auxHeaders = {}) {
    return getClient(process.env.VUE_APP_API_MEDICO_URL, auxHeaders);
}

export function getClientSecurity(auxHeaders = {}) {
    return getClient(process.env.VUE_APP_API_SEGURANCA_URL, auxHeaders);
}

export function getClientEsocial(auxHeaders = {}) {
    return getClient(process.env.VUE_APP_API_NOVO_MICROSERVICE_ESOCIAL, auxHeaders);
}

function getClient(baseURL, auxHeaders = {}, auxConfigs = {}) {
    const idsAuthorizedCustomers = getIdsAuthorizedCustomers();
    const idsAuthorizedBranches = getIdsAuthorizedCustomersBranches();
    const filteredCustomerIds = getFilterCustomerIds();
    const filterCustomerIds = filteredCustomerIds ? { 'filter-customer': JSON.stringify(filteredCustomerIds) } : {};

    const authToken = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};
    const apiKey = getTenant() ? { 'x-api-key': getTenant() } : {};
    const customersKeys = idsAuthorizedCustomers ? { customers: JSON.stringify(idsAuthorizedCustomers) } : {};
    const customersBranchKeys = idsAuthorizedBranches ? { 'customer-branches': JSON.stringify(idsAuthorizedBranches) } : {};
    const headers = { ...authToken, ...apiKey, ...auxHeaders, ...customersKeys, ...filterCustomerIds, ...customersBranchKeys };

    const client = axios.create({ baseURL, headers, ...auxConfigs });

    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                logout();
            }
            if (error?.response?.data?.details?.response?.semApiKey) {
                const event = new Event('sem-api-key');
                document.dispatchEvent(event);
            }

            return Promise.reject(error);
        }
    );

    return client;
}

export function getClientMicroserviceManual(auxHeaders = {}) {
    const baseURL = process.env.VUE_APP_API_NOVO_MICROSERVICE_MANUAL_URL;
    const authToken = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};
    const headers = { ...authToken, ...auxHeaders };
    const client = axios.create({ baseURL, headers });
    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
    );
    return client;
}

export function getClientMicroserviceLogAcao(auxHeaders = {}) {
    const baseURL = process.env.VUE_APP_API_NOVO_MICROSERVICE_LOG_ACAO_URL;
    const authToken = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};
    const headers = { ...authToken, ...auxHeaders };
    const client = axios.create({ baseURL, headers });
    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
    );
    return client;
}

export function getClientMicroserviceNotificacao(auxHeaders = {}) {
    const baseURL = process.env.VUE_APP_API_NOVO_MICROSERVICE_NOTIFICACAO;
    const authToken = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};
    const headers = { ...authToken, ...auxHeaders };
    const client = axios.create({ baseURL, headers });
    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
    );
    return client;
}

export function getClientMicroserviceGerarRelatorio(auxHeaders = {}) {
    const baseURL = process.env.VUE_APP_API_API_NOVO_MICROSERVICE_PDF_URL;
    const authToken = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};
    const headers = { ...authToken, ...auxHeaders };
    const client = axios.create({ baseURL, headers });
    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
    );
    return client;
}

export function getClientMicroserviceSenior(auxHeaders = {}) {
    const baseURL = process.env.VUE_APP_API_MICROSERVICE_SENIOR;
    const authToken = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};
    const headers = { ...authToken, ...auxHeaders };
    const client = axios.create({ baseURL, headers });
    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
    );
    return client;
}

export function getClientMicroserviceRelatorio(auxHeaders = {}) {
    const baseURL = process.env.VUE_APP_API_NOVO_MICROSERVICE_RELATORIO;
    const authToken = getToken() ? { Authorization: `Bearer ${getToken()}` } : {};
    const headers = { ...authToken, ...auxHeaders };
    const client = axios.create({ baseURL, headers });
    client.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error?.response?.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
    );
    return client;
}
