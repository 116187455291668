<template>
    <Dialog :style="{ width: '450px' }" class="p-fluid" :header="confirmHeader" :closable="false" :draggable="draggable" modal>
        <template v-if="title" #default>
            <div class="field">
                <div class="flex flex-row align-items-center w-full gap-3">
                    <i v-if="!noIcon" class="pi pi-info-circle text-blue-500 text-5xl" />
                    <p class="text-lg font-semibold">{{ title }}</p>
                </div>
            </div>
        </template>
        <template #footer>
            <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="$emit('onClose')" />
            <Button
                :label="confirmLabel"
                icon="pi pi-check"
                class="p-button-info"
                :loading="loading"
                :disabled="loading"
                @click="$emit('onConfirm')"
            />
        </template>
    </Dialog>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        confirmHeader: {
            type: String,
            default: 'Confirmar'
        },
        loading: {
            type: Boolean,
            required: true
        },
        noIcon: {
            type: Boolean,
            default: false
        },
        confirmLabel: {
            type: String,
            default: 'Confirmar'
        },
        draggable: {
            type: Boolean,
            default: false
        }
    }
};
</script>
