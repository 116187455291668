<script setup>
import AppConfirmDialog from '@/components/AppConfirmDialog.vue';
import DialogAnexoComprovante from './DialogAnexoComprovante.vue';
import BaseService from '@/services/BaseService';
import { ref, computed, defineExpose, defineProps, defineEmits } from 'vue';
import { useToast } from 'primevue/usetoast';
import { mCNPJ } from '@/utils/Mask';

const emit = defineEmits(['atualizar']);
const props = defineProps({
    depositosSelecionados: Array
});

const toast = useToast();
const loading = ref(false);
const dialogConfirmacao = ref(false);
const visivel = ref(false);
const prestador = ref(null);
const anexosId = ref([]);

const depositosIds = computed(() => {
    return props.depositosSelecionados.map((deposito) => {
        return deposito.idAgendamentoDeposito;
    });
});
const agendamentosIds = computed(() => {
    return props.depositosSelecionados.map((deposito) => {
        return deposito.idAgendamento;
    });
});
const agendamentosDepositoIds = computed(() => {
    return props.depositosSelecionados.map((deposito) => {
        return deposito.idAgendamentoDeposito;
    });
});
const valorTotal = computed(() => {
    return props.depositosSelecionados.reduce((soma, deposito) => {
        return soma + Number(deposito.total);
    }, 0);
});

const valorComDesconto = computed(() => {
    if (prestador.value?.saldo >= valorTotal.value) {
        return 0;
    }
    return valorTotal.value - Number(prestador.value?.saldo || 0);
});

const valorCreditoAplicado = computed(() => {
    return Math.min(Number(prestador.value?.saldo || 0), valorTotal.value);
});

async function buscarInformacoesPagamento() {
    loading.value = true;
    try {
        const service = new BaseService('/agendamento-deposito/listar-dados-pagamento-prestador');
        const { data } = await service.findById(prestadorId.value);
        prestador.value = data;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao buscar as informações de pagamento!',
            life: 3000
        });
    }
    loading.value = false;
}

async function efetuarPagamento() {
    loading.value = true;
    try {
        const service = new BaseService('/agendamento-deposito/gestao-deposito');
        await service.save({
            agendamentosIds: agendamentosIds.value,
            agendamentosDepositoIds: agendamentosDepositoIds.value,
            prestadorId: prestadorId.value,
            valorComDesconto: valorComDesconto.value
        });
        fechar();
        emit('atualizar');
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar efetuar pagamento!',
            life: 3000
        });
    }
    loading.value = false;
}

function validarPagamento() {
    if (!anexosId?.value?.length && valorComDesconto.value > 0) {
        toast.add({
            severity: 'error',
            summary: 'Anexo do comprovante é obrigatório!',
            life: 3000
        });
        return;
    }

    efetuarPagamento();
}

const dialogAnexoComprovante = ref(null);
function abrirDialogAnexoComprovante() {
    dialogAnexoComprovante.value?.abrir();
}

const prestadorId = ref(null);
function abrir(id) {
    prestadorId.value = id;
    buscarInformacoesPagamento();
    visivel.value = true;
}

function fechar() {
    dialogConfirmacao.value = false;
    visivel.value = false;
}

function cancelar() {
    dialogAnexoComprovante.value.deletarAnexos();
    fechar();
}

defineExpose({
    abrir
});
</script>

<template>
    <Dialog v-if="visivel" :visible="visivel" modal style="width: 644px; max-width: 90%" @update:visible="dialogConfirmacao = true">
        <template #header>
            <h4>Pagar funcionários selecionados</h4>
        </template>
        <AppLoadingWrapper v-if="loading" />
        <div v-else>
            <div class="flex justify-content-between mb-3">
                <h5 class="text-gray-800 mt-1">{{ prestador?.nome }}</h5>
                <h5 class="text-gray-800 mt-1">#{{ prestador?.id }}</h5>
            </div>
            <div class="grid">
                <div class="col-4">
                    <div class="text-gray-600">CNPJ:</div>
                    <div class="text-gray-800 font-medium">{{ mCNPJ(prestador?.cnpj) }}</div>
                </div>
                <div class="col-5" v-if="prestador?.metodoPagamento.toUpperCase() !== 'PIX'">
                    <div class="text-gray-600">Titular da conta:</div>
                    <div class="text-gray-800 font-medium">{{ prestador?.nomeTitularConta }}</div>
                </div>
                <div class="col-3" v-if="prestador?.metodoPagamento.toUpperCase() !== 'PIX'">
                    <div class="text-gray-600">Agência:</div>
                    <div class="text-gray-800 font-medium">{{ prestador?.codigoAgencia }}</div>
                </div>
                <div class="col-4">
                    <div class="text-gray-600">Método de pagamento:</div>
                    <div class="text-gray-800 font-medium">{{ prestador?.metodoPagamento }}</div>
                </div>
                <div v-if="prestador?.metodoPagamento.toUpperCase() === 'PIX'" class="col-4">
                    <div class="text-gray-600">Chave Pix:</div>
                    <div class="text-gray-800 font-medium">{{ prestador?.pix }}</div>
                </div>
                <div class="col-5" v-if="prestador?.metodoPagamento.toUpperCase() !== 'PIX'">
                    <div class="text-gray-600">Banco:</div>
                    <div class="text-gray-800 font-medium">{{ prestador?.nomeBanco }}</div>
                </div>
                <div class="col-3" v-if="prestador?.metodoPagamento.toUpperCase() !== 'PIX'">
                    <div class="text-gray-600">Conta:</div>
                    <div class="text-gray-800 font-medium">{{ prestador?.contaCorrente }}</div>
                </div>
            </div>
            <div class="border-bottom-none border-top-1 border-solid border-gray-300 w-full mt-3 mb-5" />
            <div class="flex flex-column gap-3">
                <div class="flex justify-content-between">
                    <div class="text-gray-700">Subtotal</div>
                    <div class="text-gray-700">
                        {{ valorTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                    </div>
                </div>
                <div class="flex justify-content-between">
                    <div class="text-gray-700">Crédito</div>
                    <div class="text-green-600">
                        -
                        {{ valorCreditoAplicado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                    </div>
                </div>
                <div class="flex justify-content-between">
                    <div class="text-gray-900">Total</div>
                    <div class="text-gray-900 font-medium" style="font-size: 16px">
                        {{ valorComDesconto.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                    </div>
                </div>
            </div>
            <div>
                <Button
                    :label="anexosId?.length ? 'Ver comprovante' : 'Anexar comprovante'"
                    class="p-button p-button-text mt-5 p-0"
                    icon="pi pi-paperclip"
                    @click="abrirDialogAnexoComprovante"
                />
                <DialogAnexoComprovante
                    ref="dialogAnexoComprovante"
                    v-model:anexosId="anexosId"
                    :depositosIds="depositosIds"
                    enableImport
                    enableRemove
                />
            </div>
            <AppConfirmDialog
                v-model:visible="dialogConfirmacao"
                :loading="loading"
                title="Deseja cancelar o processo de pagamento?"
                @onConfirm="cancelar"
                @onClose="dialogConfirmacao = false"
            />
        </div>
        <template #footer>
            <Button v-if="!loading" class="p-button p-button-text" label="Cancelar" @click="dialogConfirmacao = true" />
            <Button
                v-if="!loading"
                :disabled="!anexosId?.length && valorComDesconto > 0"
                class="p-button h-full m-0"
                label="Efetuar pagamento"
                @click="validarPagamento"
            />
        </template>
    </Dialog>
</template>
