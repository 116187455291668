<template>
    <Dropdown
        ref="dropdown"
        v-bind="$attrs"
        :modelValue="value"
        :options="optionItemsTratados"
        :option-group-label="optionGroupConfig?.label"
        :option-group-children="optionGroupConfig?.items"
        :optionLabel="optionLabel"
        :optionValue="null"
        :loading="loading || $attrs.loading"
        :placeholder="placeholder"
        :pt="{
            panel: {
                style: 'max-width: 100%'
            }
        }"
        autoFilterFocus
        filter
        showClear
        @change="tratarSelecaoOpcao($event)"
        @filter="filtrar"
        :disabled="disabled"
    >
        <template #value="slotProps">
            <div v-if="$slots.value && slotProps.value">
                <slot name="value" v-bind="slotProps" />
            </div>
            <div v-else-if="slotProps.value && renderValue">
                {{ formatLabel(this.renderValue(slotProps)) }}
            </div>
            <div v-else-if="slotProps.value && optionLabel">
                {{ slotProps.value[this.optionLabel] }}
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
        </template>
        <template #optiongroup="slotProps">
            <div v-if="$slots.optiongroup">
                <slot name="optiongroup" v-bind="slotProps" />
            </div>
            <div v-else class="font-bold" :class="{ 'mt-4': slotProps.index }">
                {{ slotProps.option.label }}
            </div>
        </template>
        <template #option="slotProps">
            <div v-if="$slots.option">
                <slot name="option" v-bind="slotProps" />
            </div>
            <div v-else-if="renderOption" :style="localStyleOptions(slotProps)" class="flex justify-content-between align-items-center">
                <span>
                    {{ this.renderOption(slotProps) }}
                </span>
                <i
                    v-if="agruparPrestadores && slotProps.option.prioridade === enumPrioridadePrestador.PREFERENCIAL"
                    v-tooltip.top="'Possui melhor qualidade no atendimento'"
                    class="pi pi-star-fill text-gray-500"
                />
            </div>
            <div v-else-if="slotProps.option && optionLabel" :style="localStyleOptions(slotProps)">
                {{ slotProps.option[this.optionLabel] }}
            </div>
            <span v-else :style="localStyleOptions(slotProps)">
                {{ slotProps.option }}
            </span>
        </template>
        <template #empty>
            <div v-if="$slots.empty">
                <slot name="empty" v-bind="slotProps" />
            </div>
            <div v-else>
                <span>Nenhum registro encontrado</span>
            </div>
        </template>
    </Dropdown>
    <ConfirmDialog v-if="agruparPrestadores" :group="`prestadorPreferencial${groupKey}`" style="max-width: 480px" :closable="false">
        <template #message>
            <div class="flex flex-column justify-content-between align-items-center">
                <img src="layout/images/atencao.svg" alt="simbolo de atenção" />
                <h4>Prestador selecionado não está na lista preferencial</h4>
                <span class="text-lg line-height-3 text-center"
                    >Os prestadores preferenciais são avaliados com base no atendimento, no retorno do agendamento e na conclusão do processo, o que
                    contribui para uma experiência de serviço de <span class="font-bold">alta qualidade.</span>
                </span>
            </div>
        </template>
    </ConfirmDialog>
</template>
<script>
import { limitarCaracteres } from '../../services/auth';

export default {
    name: 'AppOnePageDropdown',
    props: {
        modelValue: {
            type: Object
        },
        optionLabel: {
            type: String,
            required: true
        },
        optionValue: {
            type: String
        },
        itemSize: {
            type: Number,
            default: 20
        },
        service: {
            validator: (value) => typeof value === 'object' || !value,
            required: true
        },
        queryDelay: {
            type: Number,
            default: 500
        },
        placeholder: {
            type: String
        },
        renderValue: {
            type: Function
        },
        renderOption: {
            type: Function
        },
        idValue: {
            type: Number
        },
        autoLoad: {
            type: Boolean,
            default: true
        },
        loadAoAdicionarFiltrosExtras: {
            type: Boolean,
            default: true
        },
        loadDataById: {
            type: Boolean,
            default: true
        },
        resultWithItems: {
            type: Boolean,
            default: true
        },
        styleOptions: {
            type: Function
        },
        filtrosExtras: {
            type: Object
        },
        groupKey: {
            type: Number
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:modelValue'],
    data() {
        return {
            loading: false,
            filter: null,
            optionItems: [{}],
            selectedRecord: {},
            timeout: null,
            enumPrioridadePrestador: {
                PREFERENCIAL: 'PREFERENCIAL',
                SEGUNDA_OPCAO: 'SEGUNDA_OPCAO',
                OUTROS: 'OUTROS'
            }
        };
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        },
        agruparPrestadores() {
            return this.filtrosExtras?.agruparPrestadores;
        },
        possuiPrestadorSugeridos() {
            return this.optionItems.some(({ prioridade }) => prioridade !== this.enumPrioridadePrestador.OUTROS);
        },
        todosSaoPrestadoresSugeridos() {
            return this.optionItems.every(({ prioridade }) => prioridade !== this.enumPrioridadePrestador.OUTROS);
        },
        todosSaoPrestadoresDisponiveis() {
            return this.optionItems.every(({ prioridade }) => prioridade === this.enumPrioridadePrestador.OUTROS);
        },
        optionItemsTratados() {
            if (!this.agruparPrestadores) {
                return this.optionItems;
            }
            if (this.todosSaoPrestadoresSugeridos) {
                return this.optionItems.reduce(
                    (acc, prestador) => {
                        acc[0].items.push(prestador);
                        return acc;
                    },
                    [
                        {
                            label: 'Prestadores sugeridos',
                            items: []
                        }
                    ]
                );
            }
            if (this.todosSaoPrestadoresDisponiveis) {
                return this.optionItems.reduce(
                    (acc, prestador) => {
                        acc[0].items.push(prestador);
                        return acc;
                    },
                    [
                        {
                            label: 'Prestadores disponíveis',
                            items: []
                        }
                    ]
                );
            }
            return this.optionItems.reduce(
                (acc, prestador) => {
                    if (prestador.prioridade !== this.enumPrioridadePrestador.OUTROS) {
                        acc[0].items.push(prestador);
                    } else {
                        acc[1].items.push(prestador);
                    }
                    return acc;
                },
                [
                    {
                        label: 'Prestadores sugeridos',
                        items: []
                    },
                    {
                        label: 'Prestadores disponíveis',
                        items: []
                    }
                ]
            );
        },
        optionGroupConfig() {
            if (this.optionItems === this.optionItemsTratados) {
                return;
            }
            return {
                label: 'label',
                items: 'items'
            };
        }
    },
    async mounted() {
        await this.loadData(this.idValue);
        if (this.autoLoad) {
            await this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
        }
    },
    watch: {
        idValue(newValue) {
            if (!newValue) {
                return;
            }

            this.loadData(newValue);
        },
        service() {
            this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
        },
        async filtrosExtras(value) {
            if (!this.loadAoAdicionarFiltrosExtras) return;
            await this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: value });
        }
    },
    methods: {
        async loadData(idValue) {
            try {
                if (!this.idValue) return;
                if (!this.loadDataById) return;

                const found = this.optionItems.find((p) => p.id == idValue);

                if (found) {
                    this.selectedRecord = found;
                } else {
                    const { data: record } = await this.service.findById(idValue);
                    this.selectedRecord = record;
                }

                this.value = this.selectedRecord;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: error?.response?.data?.message || error?.message,
                    life: 3000
                });
            }
        },
        async filtrar(event) {
            this.filter = event.value;

            if (this.timeout) {
                clearTimeout(this.timeout);
            }

            this.timeout = setTimeout(() => {
                this.load({ limit: this.itemSize, filter: this.filter, filtrosExtras: this.filtrosExtras });
            }, this.queryDelay);
        },
        async load({ limit, filter, filtrosExtras }) {
            if (!this.service) return;
            try {
                this.loading = true;
                const { data } = await this.service.findAll({ limit, filter, filtrosExtras });
                if (this.resultWithItems) {
                    this.optionItems = data.items;
                } else {
                    this.optionItems = data;
                }

                this.loading = false;
            } catch (error) {
                this.optionItems = [];
                this.loading = false;
                this.$toast.add({
                    severity: 'info',
                    summary: error?.response?.data?.message || error?.message,
                    life: 3000
                });
            }
        },
        localStyleOptions(slotProps) {
            if (this.styleOptions) {
                return this.styleOptions(slotProps);
            }

            return null;
        },
        formatLabel(text) {
            return limitarCaracteres(text, 40);
        },
        tratarSelecaoOpcao(event) {
            const idOpcaoSelecionada = event.value?.id;
            const dropdown = this.$refs.dropdown;
            const opcaoSelecionada = dropdown.$props.modelValue;
            const prestadorNaoSugerido = event.value?.prioridade === this.enumPrioridadePrestador.OUTROS;

            if (opcaoSelecionada?.id === idOpcaoSelecionada) {
                return;
            }

            if (this.possuiPrestadorSugeridos && prestadorNaoSugerido && this.agruparPrestadores) {
                const hideDropdown = dropdown.hide;
                dropdown.hide = () => undefined;

                this.$confirm.require({
                    group: `prestadorPreferencial${this.groupKey}`,
                    acceptLabel: 'Estou ciente',
                    rejectLabel: 'Alterar prestador',
                    rejectClass: 'p-button-outlined mr-4',
                    accept: () => {
                        this.value = event.value;
                        dropdown.hide = hideDropdown;
                    },
                    reject: () => {
                        dropdown.hide = hideDropdown;
                    }
                });
            } else this.value = event.value;
        }
    }
};
</script>
