<template>
    <div>
        <div class="p-4 border-round border-1 border-300 grid">
            <div class="col-12 md:col-4 flex flex-column gap-2">
                <label class="w-full flex justify-content-between" for="prestador">
                    <span> Prestador<span class="p-error">*</span> </span>
                    <span class="flex column-gap-2">
                        <a
                            :style="{ visibility: !form.prestador ? 'hidden' : 'unset' }"
                            href="javascript:void(0)"
                            @click="mostrarInformacoesPrestador($event, form.prestador)"
                        >
                            Ver informações da cliníca
                        </a>
                        <i
                            v-if="isReservaDeHorario && !possuiCadastroPrestadorReservaDeHorario"
                            class="pi pi-exclamation-triangle text-orange-500"
                            v-tooltip="
                                'O prestador selecionado é elegível para reserva de horários, porém não teve sua agenda cadastrada. Caso deseje utilizar essa funcionalidade, por favor faça o cadastro da tela de prestadores.'
                            "
                        />
                    </span>
                </label>
                <DropdownPrestadorCliente
                    id="prestador"
                    v-model="v$.prestador.$model"
                    @update:model-value="verificarPrestador"
                    :class="{ 'p-invalid': v$.prestador.$error && v$.prestador.lazy.$dirty }"
                    :filtrosExtras="filtrosExtrasPrestador"
                    :idCidade="formData.cidade?.id"
                    :idCliente="formData.funcionarios?.map((e) => e.customerId)"
                />
                <small v-if="v$.prestador.$error && v$.prestador.lazy.$dirty" class="p-error w-full">
                    {{ v$.prestador.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 md:col-4 flex flex-column gap-2">
                <label class="w-full" for="intervaloSugerido"> Sugerir intervalo de data<span class="p-error">*</span></label>
                <AppInputCalendarioRangedV2
                    id="intervaloSugerido"
                    v-model="v$.intervaloSugerido.$model"
                    @update:modelValue="() => (v$.periodo.$model = null)"
                    :class="{ 'p-invalid': v$.intervaloSugerido.$error && v$.intervaloSugerido.lazy.$dirty }"
                    :idPrestador="form.prestador?.id"
                    :idCidade="formData.cidade?.id"
                    :disabled="!form.prestador"
                    :disabledDays="disabledDays"
                    :minDate="intervaloMinDate"
                    :maxDate="intervaloMaxDate"
                    :periodo="form.periodo"
                    @onGetPeriodoManhaVisible="setPeriodoManhaVisible"
                />
                <small v-if="v$.intervaloSugerido.$error && v$.intervaloSugerido.lazy.$dirty" class="p-error w-full">
                    {{ v$.intervaloSugerido.$errors[0].$message }}
                </small>
            </div>
            <div class="flex flex-column gap-2" :class="exibirCampoHorario ? 'col-6 md:col-2' : 'col-12 md:col-4'">
                <label class="w-full" for="periodo"> Período do dia<span class="p-error">*</span> </label>
                <Dropdown
                    id="periodo"
                    v-model="v$.periodo.$model"
                    :class="{ 'p-invalid': v$.periodo.$error && v$.periodo.lazy.$dirty }"
                    :disabled="!form.intervaloSugerido || !form.intervaloSugerido[0] || !periodos?.length"
                    :options="periodos"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione..."
                />
                <small v-if="v$.periodo.$error && v$.periodo.lazy.$dirty" class="p-error w-full">
                    {{ v$.periodo.$errors[0].$message }}
                </small>
            </div>
            <div v-if="exibirCampoHorario" class="col-6 md:col-2 flex flex-column gap-2">
                <label class="w-full" for="horarioMarcado">Horário<span class="p-error">*</span></label>
                <InputMask
                    id="horarioMarcado"
                    v-model="v$.horarioMarcado.$model"
                    :class="{ 'p-invalid': v$.horarioMarcado.$error && v$.horarioMarcado.lazy.$dirty }"
                    mask="99:99"
                    required
                    @blur="validarSePrestadorAtendePeriodoEHorario()"
                />
                <small v-if="v$.horarioMarcado.$error && v$.horarioMarcado.lazy.$dirty" class="p-error w-full">
                    {{ v$.horarioMarcado.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 md:col-4 flex flex-column gap-2">
                <label class="w-full" for="cliente"> Cliente </label>
                <p id="cliente" class="h-full flex align-items-center">{{ formData.cliente?.name }}</p>
            </div>
            <div class="col-12 md:col-4 flex flex-column gap-2">
                <label class="w-full" for="quemAgenda"> Quem agenda<span class="p-error">*</span> </label>
                <Dropdown
                    id="quemAgenda"
                    v-model="v$.quemAgenda.$model"
                    :class="{ 'p-invalid': v$.quemAgenda.$error && v$.quemAgenda.lazy.$dirty }"
                    :disabled="quemAgendaDisabled"
                    :options="agendaAvisaOptions"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione..."
                />
                <small v-if="v$.quemAgenda.$error && v$.quemAgenda.lazy.$dirty" class="p-error w-full">
                    {{ v$.quemAgenda.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 md:col-4 flex flex-column gap-2">
                <label class="w-full" for="quemAvisa"> Quem avisa<span class="p-error">*</span> </label>
                <Dropdown
                    id="quemAgenda"
                    v-model="v$.quemAvisa.$model"
                    :class="{ 'p-invalid': v$.quemAvisa.$error && v$.quemAvisa.lazy.$dirty }"
                    :disabled="quemAvisaDisabled"
                    :options="agendaAvisaOptions"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione..."
                />
                <small v-if="v$.quemAvisa.$error && v$.quemAvisa.lazy.$dirty" class="p-error w-full">
                    {{ v$.quemAvisa.$errors[0].$message }}
                </small>
            </div>
            <div class="col-12 flex flex-column gap-2">
                <label class="w-full" for="obs"> Observações </label>
                <Textarea id="obs" v-model="form.observacao" autoResize cols="20" required rows="3" />
            </div>
        </div>
        <OverlayInformacoesClinica ref="infoClinica" />
    </div>
</template>

<script setup>
import { computed, defineEmits, defineExpose, defineProps, inject, nextTick, onActivated, onMounted, ref, watch } from 'vue';
import DropdownPrestadorCliente from '@/pages/prestadores/components/DropdownPrestadorCliente.vue';
import TipoExame from '@/enums/TipoExame';
import AppInputCalendarioRangedV2 from '@/components/input/AppInputCalendarioRangedV2.vue';
import QuemAgendaAvisa from '@/enums/QuemAgendaAvisa';
import moment from 'moment';
import BaseService from '@/services/BaseService';
import { usePrimeVue } from 'primevue/config';
import EnumPeriodo from '@/enums/EnumPeriodo';
import { showWarning } from '@/utils/Toast';
import { useToast } from 'primevue/usetoast';
import { obterDiaDaSemanaPorIndice } from '@/enums/EnumDiaDaSemana';
import { uniq } from 'ramda';
import { limitarCaracteres } from '@/services/auth';
import OverlayInformacoesClinica from './components/OverlayInformacoesClinica.vue';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import usePeriodo from './composables/usePeriodo';
import { getClientBase } from '@/services/http';
import { PrestadorAgendamento } from '../../../../../components/prestador/calendario-prestador-context/PrestadorAgendamento';

const emit = defineEmits(['update:formData']);

const props = defineProps({
    formData: {
        type: Object,
        required: true
    }
});

const rules = {
    prestador: { required: helpers.withMessage('Campo obrigatório', required), lazy: true },
    intervaloSugerido: {
        requiredPersonalizadoIntervaloSugerido: helpers.withMessage('Campo obrigatório', requiredPersonalizadoIntervaloSugerido),
        lazy: true
    },
    periodo: { required: helpers.withMessage('Campo obrigatório', required), lazy: true },
    quemAgenda: { validacaoCampoQuemAgendaAvisa: helpers.withMessage('Campo obrigatório', validacaoCampoQuemAgendaAvisa), lazy: true },
    quemAvisa: { validacaoCampoQuemAgendaAvisa: helpers.withMessage('Campo obrigatório', validacaoCampoQuemAgendaAvisa), lazy: true },
    horarioMarcado: { requiredPersonalizadoHorarioMarcado: helpers.withMessage('Campo obrigatório', requiredPersonalizadoHorarioMarcado), lazy: true }
};

const form = computed({
    get() {
        return props.formData;
    },
    set(value) {
        emit('update:formData', value);
    }
});

const v$ = useVuelidate(rules, form);

const filtrosExtrasPrestador = computed(() => {
    const tiposDeExamesQuePrecisamDeClinico = [
        TipoExame.ADMISSIONAL,
        TipoExame.PERIODICO,
        TipoExame.DEMISSIONAL,
        TipoExame.RETORNO_AO_TRABALHO,
        TipoExame.MUDANCA_FUNCAO
    ];
    const apenasQueAtendamClinico = tiposDeExamesQuePrecisamDeClinico.includes(props.formData.tipoExame?.id);
    return {
        apenasQueAtendamClinico,
        agruparPrestadores: true
    };
});

const prestador = ref(null);
const infoClinica = ref(null);
const openingHours = ref([]);

function mostrarInformacoesPrestador(event, prestadorInfo) {
    prestador.value = prestadorInfo;
    infoClinica?.value?.open(event, prestador.value.id);
}

const { setPeriodoManhaVisible, prestadorUsaSoc, periodos, validarDisponibilidadePeriodo } = usePeriodo(form, openingHours, isReservaDeHorario);

watch(
    () => form.value.prestador,
    async (value) => {
        form.value.intervaloSugerido = null;
        form.value.periodo = null;
        await carregarHorariosAtendimentoPrestador();
        if (value) {
            await desabilitarDiasNaoAtendidos(value.id);
        }
        await defineQuemAgendaAvisa();
        await validarSePrestadorAtendePeriodoEHorario();
        desabilitaDataMinimaHoje();
    }
);

const disabledDays = ref([]);

watch(
    disabledDays,
    (value) => {
        form.value.disabledDays = value;
    },
    { deep: true }
);

const intervaloMaxDate = ref(null);
watch(
    intervaloMaxDate,
    (value) => {
        form.value.intervaloMaxDate = value;
    },
    { deep: true }
);
const $servicePrestadores = new BaseService('/suppliers');

const primeVue = usePrimeVue();

async function desabilitarDiasNaoAtendidos(prestadorId) {
    if (!prestadorId) return;

    disabledDays.value = [];
    intervaloMaxDate.value = null;

    let currentWeekDay;

    if (form.value.intervaloSugerido?.[0]) {
        currentWeekDay = moment(form.value.intervaloSugerido[0]).weekday();
    }

    primeVue.config.locale.dayNamesShort.forEach((day, index) => {
        const horario = openingHours.value.find((e) => e.weekDay.toLowerCase() === day);
        if (horario?.atendimentoMatutino || horario?.atendimentoVespertino) {
            if (currentWeekDay && currentWeekDay + 1 === index) {
                currentWeekDay = index;
            }
            return;
        }
        disabledDays.value.push(index);
    });

    if (currentWeekDay) {
        const days = currentWeekDay - moment(form.value.intervaloSugerido[0]).weekday();
        intervaloMaxDate.value = moment(form.value.intervaloSugerido[0], 'YYYY-MM-DD').add(days, 'd').toDate();
    }
}

const quemAgendaDisabled = ref(true);
const quemAvisaDisabled = ref(true);
const LIMITE_CARACTERES = 58;
const agendaAvisaOptions = computed(() => {
    return [
        {
            label: props.formData.profile?.name ? limitarCaracteres(props.formData.profile?.name, LIMITE_CARACTERES) : '',
            value: QuemAgendaAvisa.SOLICITANTE
        },
        {
            label: props.formData.profile?.defaultCompany?.name
                ? limitarCaracteres(props.formData.profile?.defaultCompany?.name, LIMITE_CARACTERES)
                : '',
            value: QuemAgendaAvisa.EMPRESA
        }
    ];
});

const metodoAgendamentoPadraoCliente = computed(() => {
    return !props.formData.profile.quemAgenda && !props.formData.profile.quemAvisa;
});

async function defineQuemAgendaAvisa() {
    if (!form.value.prestador) return;
    if (prestadorUsaSoc.value && props.formData?.cliente?.usaReservaHorario) {
        form.value.quemAvisa = QuemAgendaAvisa.SOLICITANTE;
        form.value.quemAgenda = QuemAgendaAvisa.SOLICITANTE;
        quemAgendaDisabled.value = true;
        quemAvisaDisabled.value = !props.formData.cliente.permiteAlterarMetodoReservaHorario;
    } else {
        if (metodoAgendamentoPadraoCliente.value) {
            await defineClienteQuemAgendaAvisa();
        } else {
            form.value.quemAvisa = props.formData.profile.quemAvisa;
            form.value.quemAgenda = props.formData.profile.quemAgenda;
        }

        quemAvisaDisabled.value = form.value.quemAvisa !== QuemAgendaAvisa.USUARIO_ESCOLHE;
        quemAgendaDisabled.value = form.value.quemAgenda !== QuemAgendaAvisa.USUARIO_ESCOLHE;
    }
}

const $clienteMetodoAtendimentoService = new BaseService('cliente-metodo-atendimento/find-by-exame-cliente/:idTipo');
async function defineClienteQuemAgendaAvisa() {
    $clienteMetodoAtendimentoService.path = $clienteMetodoAtendimentoService.path.replace(':idTipo', props.formData.tipoExame.id);

    const { data } = await $clienteMetodoAtendimentoService.findById(props.formData.cliente.id);

    if (data) {
        form.value.quemAvisa = data.quemAvisa;
        form.value.quemAgenda = data.quemAgenda;
    } else {
        form.value.quemAvisa = props.formData.cliente.quemAvisa;
        form.value.quemAgenda = props.formData.cliente.quemAgenda;
    }
}

watch(
    () => form.value.intervaloSugerido,
    () => {
        validarDisponibilidadePeriodo();
        validarSePrestadorAtendePeriodoEHorario();
        desabilitaDataMinimaHoje();
    }
);

watch(
    () => form.value.periodo,
    () => {
        validarSePrestadorAtendePeriodoEHorario();
    }
);

const intervaloMinDate = ref(null);
watch(
    intervaloMinDate,
    (value) => {
        form.value.intervaloMinDate = value;
    },
    { deep: true }
);

watch(
    () => form.value.quemAgenda,
    () => {
        desabilitaDataMinimaHoje();
        validarSePrestadorAtendePeriodoEHorario();
    }
);

function desabilitaDataMinimaHoje() {
    const dataInicial = form.value.intervaloSugerido?.[0];
    const empresaAgenda = form.value.quemAgenda === QuemAgendaAvisa.EMPRESA;
    const isDataInicialHoje = dataInicial && moment().isSame(moment(dataInicial), 'days');

    if (empresaAgenda) {
        intervaloMinDate.value = moment().add(1, 'days').toDate();
        if (isDataInicialHoje) {
            form.value.intervaloSugerido = [];
        }
    } else {
        intervaloMinDate.value = moment().toDate();
    }
}

const horarioInvalido = ref(false);

const exibirCampoHorario = computed(() => {
    return (
        !prestadorUsaSoc.value &&
        form.value.quemAgenda === QuemAgendaAvisa.SOLICITANTE &&
        form.value.periodo &&
        form.value.intervaloSugerido?.[0] &&
        form.value.intervaloSugerido?.[1]
    );
});

function requiredPersonalizadoIntervaloSugerido(value) {
    return value?.every((item) => item);
}

function requiredPersonalizadoHorarioMarcado() {
    return !(exibirCampoHorario.value && !form.value.horarioMarcado);
}

function validacaoCampoQuemAgendaAvisa(value) {
    return !(value && value === QuemAgendaAvisa.USUARIO_ESCOLHE);
}

const $toast = useToast();

async function validarSePrestadorAtendePeriodoEHorario() {
    if (!form.value.horarioMarcado) return;
    const [hora, minutos] = form.value.horarioMarcado.split(':');
    if (hora > 23 || minutos > 59) {
        $toast.add({
            severity: 'info',
            summary: 'O formato de horário está inválido. Por favor, insira um formato de horário válido (HH:mm).',
            life: 5000
        });
        form.value.horarioMarcado = null;
        return;
    }
    const quemAgenda = form.value.quemAgenda && form.value.quemAgenda !== QuemAgendaAvisa.USUARIO_ESCOLHE;
    const intervaloSugerido = form.value.intervaloSugerido?.[0] && form.value.intervaloSugerido?.[1];
    if (!form.value.prestador || !intervaloSugerido || !form.value.periodo || !quemAgenda) {
        return false;
    }

    const horario = form.value.horarioMarcado;
    horarioInvalido.value = !horario || !moment(horario, 'HH:mm').isValid();
    if (exibirCampoHorario.value && horarioInvalido.value) return false;

    const periodoDaManha = [EnumPeriodo.MANHA, EnumPeriodo.AMBOS].includes(form.value.periodo);
    const periodoDaTarde = [EnumPeriodo.TARDE, EnumPeriodo.AMBOS].includes(form.value.periodo);
    const horarioEValidoDeManha = periodoDaManha && horario <= '12:00';
    const horarioEValidoDeTarde = periodoDaTarde && horario > '12:00';

    if (exibirCampoHorario.value && !horarioEValidoDeManha && !horarioEValidoDeTarde) {
        showWarning($toast, 'O horário informado não está no período selecionado');
        await nextTick(() => (form.value.horarioMarcado = ''));
        return false;
    }

    const horarioAtualEMaior = moment().format('HH:mm') > horario;
    const dataFimEHoje = moment(form.value.intervaloSugerido[1]).isSame(moment(), 'day');
    const dataInicioEHoje = moment(form.value.intervaloSugerido[0]).isSame(moment(), 'day');
    if (exibirCampoHorario.value && dataInicioEHoje && dataFimEHoje && horarioAtualEMaior) {
        showWarning($toast, 'O horário informado é menor que o atual');
        await nextTick(() => (form.value.horarioMarcado = ''));
        return false;
    }

    const dataFim = moment(form.value.intervaloSugerido[1]);
    const dataInicio = moment(form.value.intervaloSugerido[0]);

    let horarioInformadoEValido = false;
    while (dataInicio.isSameOrBefore(dataFim, 'day')) {
        const diaDaSemana = obterDiaDaSemanaPorIndice(dataInicio.day());
        const horarioDeAtedimento = openingHours.value.find((p) => p.weekDay === diaDaSemana);

        if (horarioDeAtedimento) {
            const horarioEValidoManha = horario >= horarioDeAtedimento.startHourAm && horario <= horarioDeAtedimento.endHourAm;
            const horarioEValidoTarde = horario >= horarioDeAtedimento.startHourPm && horario <= horarioDeAtedimento.endHourPm;
            const atendeHorarioDeManha = periodoDaManha && horarioEValidoManha;
            const atendeHorarioDeTarde = periodoDaTarde && horarioEValidoTarde;
            if (atendeHorarioDeManha || atendeHorarioDeTarde) {
                horarioInformadoEValido = true;
            }
        }

        dataInicio.add(1, 'day');
    }

    if (exibirCampoHorario.value && !horarioInformadoEValido) {
        showWarning($toast, 'O prestador não atende neste horário dentro do intervalo sugerido');
        await nextTick(() => (form.value.horarioMarcado = ''));
        return false;
    }

    return true;
}

async function carregarHorariosAtendimentoPrestador() {
    if (!form.value.prestador) {
        openingHours.value = [];
        return;
    }
    try {
        const { data } = await $servicePrestadores.findById(form.value.prestador.id);
        openingHours.value = data.openingHours;
        new PrestadorAgendamento($toast).verificarPrestadorNaoPossuiHorario(data.openingHours);
    } catch (error) {
        $toast.add({
            severity: 'error',
            summary: `Não foi possível buscar agenda de horários do prestador. ${error?.message}`,
            life: 5000
        });
    }
}

onMounted(() => {
    intervaloMinDate.value = moment().toDate();
    form.value.periodo = EnumPeriodo.AMBOS;
});

const clientes = ref([]);
const isReservaDeHorario = inject('isReservaDeHorario');
const possuiCadastroPrestadorReservaDeHorario = inject('possuiCadastroPrestadorReservaDeHorario');
const setPossuiCadastroPrestadorReservaDeHorario = inject('setPossuiCadastroPrestadorReservaDeHorario');

async function verificarPrestador() {
    if (form.value.prestador) {
        try {
            const { data } = await getClientBase().get('/prestador-config-reserva/verificar-se-existe', {
                params: {
                    cnpj: form.value.prestador.cnpj
                }
            });

            setPossuiCadastroPrestadorReservaDeHorario(data);
        } catch (error) {
            $toast.add({
                severity: 'error',
                summary: 'Não foi possível verificar se o prestador atende reserva de horários. Por favor, tente novamente.',
                life: 5000
            });
            delete form.value.prestador;
        }
    }
}

onActivated(async () => {
    await nextTick(async () => {
        if (form.value?.funcionarios) {
            clientes.value = uniq(form.value?.funcionarios?.map((p) => p.customerId));
        }

        if (!form.value?.quemAgenda || !form.value?.quemAvisa) {
            await defineQuemAgendaAvisa();
        }
    });
});

defineExpose({
    async validarFormulario() {
        await validarSePrestadorAtendePeriodoEHorario();
        return v$.value.$validate();
    }
});
</script>
