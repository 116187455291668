export const queryParamsTelaClientesGro = {
    state: {
        queryParamsTelaClientesGro: {},
        queryParamsTelaUnidadesGro: {},
        queryParamsTelaAmbienteGro: {},
        queryParamsTelaInventarioRiscoGro: {}
    },
    getters: {
        queryParamsTelaClientesGro: function (state) {
            return state.queryParamsTelaClientesGro;
        },
        queryParamsTelaUnidadesGro: function (state) {
            return state.queryParamsTelaUnidadesGro;
        },
        queryParamsTelaAmbienteGro: function (state) {
            return state.queryParamsTelaAmbienteGro;
        },
        queryParamsTelaInventarioRiscoGro: function (state) {
            return state.queryParamsTelaInventarioRiscoGro;
        }
    },
    mutations: {
        UPDATE_QUERY_PARAMS_TELA_CLIENTES_GRO(state, payload) {
            state.queryParamsTelaClientesGro = payload;
        },
        UPDATE_QUERY_PARAMS_TELA_UNIDADES_GRO(state, payload) {
            state.queryParamsTelaUnidadesGro = payload;
        },
        UPDATE_QUERY_PARAMS_TELA_AMBIENTE_GRO(state, payload) {
            state.queryParamsTelaAmbienteGro = payload;
        },
        UPDATE_QUERY_PARAMS_TELA_INVENTARIO_RISCO_GRO(state, payload) {
            state.queryParamsTelaInventarioRiscoGro = payload;
        }
    },
    actions: {
        setQueryParamsTelaClientesGro(context, payload) {
            context.commit('UPDATE_QUERY_PARAMS_TELA_CLIENTES_GRO', payload);
        },
        setQueryParamsTelaUnidadesGro(context, payload) {
            context.commit('UPDATE_QUERY_PARAMS_TELA_UNIDADES_GRO', payload);
        },
        setQueryParamsTelaAmbienteGro(context, payload) {
            context.commit('UPDATE_QUERY_PARAMS_TELA_AMBIENTE_GRO', payload);
        },
        setQueryParamsTelaInventarioRiscoGro(context, payload) {
            context.commit('UPDATE_QUERY_PARAMS_TELA_INVENTARIO_RISCO_GRO', payload);
        }
    }
};
