<script setup>
import ResumoDepositos from './components/ResumoDepositos.vue';
import TabelaDepositosConcluidos from './components/TabelaDepositosConcluidos.vue';
import TabelaDepositosEmAberto from './components/TabelaDepositosEmAberto.vue';
import Breadcrumb from './components/Breadcrumb.vue';
import { useRoute } from 'vue-router';
import { ref } from 'vue';
const route = useRoute();
const prestadorId = +route.params.id;
const itensBreadcrumb = [
    {
        path: '/gestao-medica-v3/deposito-antecipado',
        title: 'Depósito Antecipado'
    },
    {
        path: '/gestao-medica-v3/deposito-antecipado/extratos',
        title: 'Extratos'
    }
];

const tabelaDepositosEmAberto = ref(null);
const tabelaDepositosConcluidos = ref(null);
const resumoDepositos = ref(null);
function atualizar() {
    tabelaDepositosEmAberto.value?.buscarDepositosEmAberto();
    tabelaDepositosConcluidos.value?.buscarDepositosConcluidos();
    resumoDepositos.value?.buscarResumoDepositos();
}

</script>
<template>
    <div class="card flex flex-column" style="padding: 32px; gap: 16px; font-family: 'Inter UI', sans-serif !important">
        <Breadcrumb :rotas="itensBreadcrumb" />
        <h1 class="font-semibold text-2xl m-0">{{ route.params.prestador || "" }}</h1>
        <div class="grid">
            <div class="col-10 flex flex-column gap-5">
                <div class="flex flex-column gap-2">
                    <label class="font-medium text-xl">Em aberto</label>
                    <TabelaDepositosEmAberto ref="tabelaDepositosEmAberto" :prestadorId="prestadorId" />
                </div>
                <div class="flex flex-column gap-2">
                    <label class="font-medium text-xl">Concluído</label>
                    <TabelaDepositosConcluidos ref="tabelaDepositosConcluidos" :prestadorId="prestadorId" />
                </div>
            </div>
            <div class="col-2">
                <ResumoDepositos ref="resumoDepositos" :prestadorId="prestadorId" @atualizar="atualizar" />
            </div>
        </div>
    </div>
</template>
