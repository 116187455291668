<script setup>
import DialogFiltros from './dialogs/DialogFiltros.vue';
import BaseService from '@/services/BaseService';
import { ref, computed, defineProps, defineEmits } from 'vue';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['atualizar', 'update:loading', 'update:metadadosTabela']);
const props = defineProps({
    loading: Boolean,
    metadadosTabela: Object
});

const filtrosPreenchidos = computed(() => {
    const valores = Object.values(metadados.value?.filtrosExtras || {});

    return valores?.some(Boolean);
});

const metadados = computed({
    get() {
        return props.metadadosTabela;
    },
    set(novoValor) {
        emit('update:metadadosTabela', novoValor);
    }
});
const loadingTabela = computed({
    get() {
        return props.loading;
    },
    set(valor) {
        emit('update:loading', valor);
    }
});

const toast = useToast();
async function gerarRelatorioExtratos() {
    loadingTabela.value = true;
    try {
        const url = `/deposito-antecipado/gerar-relatorio-extrato-deposito`;
        const service = new BaseService(url, {}, { responseType: 'blob' });
        const { data } = await service.findAll({
            filtrosExtras: metadados.value?.filtrosExtras
        });
        const file = window.URL.createObjectURL(new Blob([data]));
        const docUrl = document.createElement('a');
        docUrl.href = file;
        docUrl.setAttribute('download', 'relátorio_extrato.xlsx');
        document.body.appendChild(docUrl);
        docUrl.click();
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao gerar planilha! ',
            life: 3000
        });
    }
    loadingTabela.value = false;
}

function atualizar() {
    emit('atualizar');
}

function limparFiltrosExtras() {
    metadados.value.filtrosExtras = {};
    atualizar();
}

const dialogFiltros = ref(null);
function abrirDialogFiltros() {
    dialogFiltros.value?.abrir();
}
</script>
<template>
    <div class="flex gap-2">
        <InputText v-model="metadados.filter" class="w-25rem mr-3" placeholder="Pesquisar por prestadores ou CNPJ" @keyup.enter="atualizar" />
        <Button class="p-button h-8 w-4rem" icon="pi pi-filter" @click="abrirDialogFiltros" />
        <Button v-if="filtrosPreenchidos" class="p-button p-button-outlined h-8 w-4rem" icon="pi pi-filter-slash" @click="limparFiltrosExtras" />
        <Button class="p-button h-8 w-4rem" severity="success" icon="pi pi-file-pdf" iconClass="text-lg" @click="gerarRelatorioExtratos" />
        <DialogFiltros ref="dialogFiltros" v-model:filtrosExtras="metadados.filtrosExtras" @atualizar="atualizar" />
    </div>
</template>
