<script setup>
import { ref, defineExpose, defineProps, defineEmits } from 'vue';

const emit = defineEmits(['update:anexosId', 'onLimparArrayDepositosIds']);
const props = defineProps({
    anexosId: Array,
    depositosIds: Array,
    enableRemove: Boolean,
    enableImport: Boolean
});

const visivel = ref(false);
const anexosIds = ref([]);

function adicionarComprovante(anexo) {
    anexosIds.value = anexo?.id ? [...anexosIds.value, anexo.id] : [...anexosIds.value];
    emit('update:anexosId', anexosIds.value);
}
function carregarComprovante(anexos) {
    if (anexos[0]?.id && !anexosIds.value.includes(anexos[0]?.id)) {
        anexosIds.value = [...anexosIds.value, anexos[0]?.id];
    }
    emit('update:anexosId', anexosIds.value);
}
function excluirComprovante() {
    anexosIds.value = [];
    emit('update:anexosId', []);
}
function limparDepositosIds() {
    emit('onLimparArrayDepositosIds', null);
}

function abrir() {
    if (!props.depositosIds?.length) return;
    visivel.value = true;
}

function fechar() {
    limparDepositosIds();
    visivel.value = false;
}

const anexoDialog = ref(null);
function deletarAnexos() {
    if (!anexoDialog.value) return;
    anexoDialog.value.onDeleteAllAnexos(anexosIds.value);
}

defineExpose({
    abrir,
    deletarAnexos
});
</script>

<template>
    <AppAnexoDialog
        ref="anexoDialog"
        v-if="depositosIds?.length"
        :visible="visivel"
        :origemId="depositosIds"
        :singleUpload="true"
        :enableRemove="enableRemove"
        :enableImport="enableImport"
        title="Comprovante do depósito"
        tipo="COMPROVANTE"
        origem="GESTAO_DEPOSITO_ANTECIPADO"
        @onClickCloseAnexo="fechar"
        @onDelete="excluirComprovante"
        @onLoad="carregarComprovante"
        @onUpload="adicionarComprovante"
    />
</template>
