<script setup>
import BaseService from '@/services/BaseService';
import { ref, defineProps, defineExpose } from 'vue';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
    prestadorId: Number
});

const loading = ref(false);
const enumOrigem = {
    CREDITO: 'Crédito Gerado',
    REEMBOLSO: 'Reembolso',
    Agendamento: 'Agendamento'
};

const toast = useToast();
const depositosEmAberto = ref([]);
async function buscarDepositosEmAberto() {
    loading.value = true;
    try {
        const url = `agendamento-deposito/listar-depositos-abertos/${props.prestadorId}`;
        const service = new BaseService(url);
        const { data } = await service.findAll({});
        depositosEmAberto.value = data.map((deposito) => {
            const valor = deposito.creditoDisponivel ?? deposito.total;
            const sinalValor = +valor < 0 ? '- ' : +valor > 0 ? '+ ' : '';
            const utilizado = deposito?.totalValorUtilizado;
            const sinalUtilizado = +utilizado < 0 ? '- ' : +utilizado > 0 ? '+ ' : '';
            return {
                ...deposito,
                pagamento: '-',
                origem: enumOrigem[deposito.origem],
                valor: sinalValor + formatarParaReal(valor),
                utilizado: sinalUtilizado + formatarParaReal(deposito?.totalValorUtilizado)
            };
        });
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao buscar registros de depositos em aberto! ',
            life: 3000
        });
    }
    loading.value = false;
}

function formatarParaReal(valor) {
    if (!valor) return 'R$ -';
    return (+valor.replace('-', '')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
}

buscarDepositosEmAberto();
defineExpose({ buscarDepositosEmAberto });
</script>
<template>
    <DataTable
        :loading="loading"
        :value="depositosEmAberto"
        class="p-0 border-1 border-200 border-round-sm"
        style="font-family: 'Inter UI', sans-serif"
    >
        <Column field="origem" header="Origem" style="width: 120px" />
        <Column field="data" header="Data" class="w-1" />
        <Column field="pagamento" header="Pagamento" class="w-1 text-center" />
        <Column field="nomeFuncionario" header="Funcionário">
            <template #body="{ data }">
                <div v-if="data.tipo_credito === 'MANUAL'">
                    ALTERAÇÃO MANUAL
                    <i v-tooltip.top="data.justificativa" class="pi pi-info-circle mr-2 text-primary text-md" />
                </div>
                <div v-else>{{ data.nomeFuncionario }}</div>
            </template>
        </Column>
        <Column field="valor" header="Valor" body-class="text-right" header-class="w-1 pl-6" />
        <Column field="utilizado" header="Utilizado" body-class="text-right" header-class="w-1 pl-6" />
    </DataTable>
</template>
