<template>
    <div class="card">
        <Toast />
        <div v-if="breadcrumb" class="field col-6"></div>
        <div class="flex flex-row gap-2 align-items-center text-800 mb-1" v-if="tooltip || subtitle">
            <AppInfoManual :tooltip="tooltip" :nomeTelaDoManual="nomeTelaDoManual" />
            <span>{{ subtitle }}</span>
            <TourComponent :tourName="nomeTelaDoManual" />
        </div>

        <Menu ref="menu" :model="internalActionItems" :popup="true" />

        <div class="flex flex-column mb-1 md:flex-row md:justify-content-between md:align-items-center">
            <div class="w-full">
                <slot name="anotherFilters"></slot>
                <div v-if="hasFiltro" class="flex flex-row p-fluid md:mt-0 mb-2">
                    <Chips
                        ref="chippesquisa"
                        v-model="filter"
                        style="width: 80%"
                        class="mr-2 step1"
                        placeholder="Pesquisar"
                        @keydown="onKeyDownChips($event)"
                        @add="load"
                        @remove="removeFiltro"
                    />

                    <div v-if="modulo && tipo">
                        <div v-if="showButtonLimparFiltros" style="height: 100%">
                            <Button
                                label="limpar filtros"
                                icon="pi pi-times"
                                style="height: 100%"
                                class="p-button-sm step2"
                                @click="removerTodosFiltros"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <slot name="incluir-registro">
                <div class="mt-2 ml-auto md:mt-0" v-if="newPath && ($checkPermission(permission + ':criar') || !permission)">
                    <Button label="Novo" icon="pi pi-plus" class="p-button p-button-text mr-2 step3" @click="$router.push(this.newPath)" />
                </div>
            </slot>
        </div>
        <slot name="header"></slot>
        <div class="body step4">
            <AppLoadingWrapper v-if="loading && loadingEffect" />
            <DataTable
                :lasy="lazyEffect"
                :loading="loading"
                v-if="!loading || lazyEffect"
                dataKey="id"
                :value="records"
                filterDisplay="menu"
                :row-hover="true"
                class="p-datatable-sm"
                responsiveLayout="stack"
                breakpoint="640px"
                :selectionMode="selectionMode"
                @rowSelect="onRowSelect"
                :rowClass="defaultRowClass"
                @sort="onSort"
                :sortField="currentSortField"
                :sortOrder="currentSortOrder"
                removableSort
                v-model:expandedRows="expandedRows"
            >
                <template #empty> Nenhum registro encontrado. </template>

                <Column v-if="expander" :expander="true" headerStyle="width: 3rem" />

                <slot name="columns"></slot>

                <Column v-if="showActionItems" bodyClass="text-right" headerStyle="width: 10rem;">
                    <template #body="slotProps">
                        <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary step5" @click="toggleMenu($event, slotProps.data)" />
                    </template>
                </Column>
                <template v-if="expander" #expansion="slotProps">
                    <slot name="expansion" v-bind:data="slotProps?.data"></slot>
                </template>
            </DataTable>
        </div>
        <Paginator
            ref="paginator"
            v-show="!loading"
            :rows="perPage"
            :totalRecords="total"
            @page="onPage($event)"
            paginatorTemplate=" PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 50, 100]"
            currentPageReportTemplate="Mostrando {last} de {totalRecords} registros"
        >
        </Paginator>

        <AppDeleteDialog
            v-if="showDeleteRecordDialog"
            v-model:visible="showDeleteRecordDialog"
            :record="record"
            @onConfirm="onClickDeleteRecord"
            @onClose="onClickCloseDeleteRecord"
        >
        </AppDeleteDialog>

        <AppLogAcoesDialog v-if="showLogAcoesDialog" :origem="origemHistoricoAcao" :origem_id="record.id" v-model:visible="showLogAcoesDialog" />
    </div>
</template>
<script>
import FiltroService from '../../services/FiltroService';
import AppInfoManual from '../AppInfoManual';
import AppLogAcoesDialog from '../../components/AppLogAcoesDialog.vue';
import TourComponent from '../TourComponent.vue';

export default {
    components: {
        AppInfoManual,
        AppLogAcoesDialog,
        TourComponent
    },
    props: {
        service: {
            type: Object,
            required: true
        },
        newPath: {
            type: String
        },
        editPath: {
            type: String
        },
        firstPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 10
        },
        actionItems: {
            type: Array
        },
        extraActionItems: {
            type: Array
        },
        rowClass: {
            type: Function
        },
        selectionMode: {
            type: String,
            default: 'single'
        },
        expander: {
            type: Boolean,
            default: false
        },
        customRowCondition: {
            type: Function
        },
        customRowStyle: {
            type: Function
        },
        loadingEffect: {
            type: Boolean,
            default: true
        },
        subtitle: {
            type: String
        },
        tooltip: {
            type: String
        },
        customDelete: {
            type: Function
        },
        permission: {
            type: String
        },
        showActionItems: {
            type: Boolean,
            default: true
        },
        hasFiltro: {
            type: Boolean,
            default: true
        },
        filtrosExtras: {
            type: Object
        },
        tipo: {
            type: String
        },
        modulo: {
            type: String
        },
        descricao: {
            type: String
        },
        showButtonLimparFiltros: {
            type: Boolean,
            default: true
        },
        nomeTelaDoManual: {
            type: String
        },
        breadcrumb: {
            type: Object
        },
        origemHistoricoAcao: {
            type: String
        }
    },
    emits: ['onToggleMenu', 'onRowSelect', 'onAfterDelete'],
    data() {
        return {
            filtroService: null,
            records: [],
            expandedRows: [],
            record: {},
            loading: false,
            lazyEffect: false,
            total: 0,
            page: 1,
            currentPerPage: null,
            sort: null,
            currentSortField: null,
            currentSortOrder: null,
            recordDialog: false,
            showDeleteRecordDialog: false,
            internalActionItems: [
                {
                    label: 'Alterar',
                    disabled: () => !this.$checkPermission(this.permission + ':editar') && this.permission,
                    icon: 'pi pi-pencil',
                    command: () => this.editRecord(this.record)
                },
                {
                    label: 'Excluir',
                    icon: 'pi pi-trash',
                    disabled: () => !this.$checkPermission(this.permission + ':excluir') && this.permission,
                    command: () => this.confirmDeleteRecord(this.record)
                },
                {
                    label: 'Histórico de ação',
                    icon: 'pi pi-paperclip',
                    command: () => this.logsAcoes(this.record)
                }
            ],
            filter: [],
            home: { icon: 'pi pi-home', to: '/' },
            showLogAcoesDialog: false
        };
    },
    mounted() {
        this.$service = this.service;
        this.filtroService = new FiltroService();
        this.internalActionItems = this.actionItems || this.internalActionItems;
        this.internalActionItems = [...this.internalActionItems, ...(this.extraActionItems || [])];
        this.page = this.firstPage;
        this.currentPerPage = this.perPage;
        this.load();
    },
    methods: {
        onKeyDownChips(event) {
            const inputValue = event.target.value;

            if (event.code === 'NumpadEnter') {
                if (inputValue && inputValue.trim().length) {
                    this.$refs.chippesquisa.addItem(event, inputValue, true);
                }
            }
        },
        async toggleMenu(event, data) {
            await this.$emit('onToggleMenu', event, data);

            this.record = data;
            this.$refs.menu.toggle(event);
        },
        async onRowSelect(event) {
            await this.$emit('onRowSelect', event);
        },
        editRecord(record) {
            this.$router.push(this.editPath + record.id);
        },
        confirmDeleteRecord() {
            this.customDelete ? this.customDelete(this.record) : this.openDeleteRecordDialog();
        },
        openDeleteRecordDialog() {
            this.showDeleteRecordDialog = true;
        },
        async onClickDeleteRecord(record) {
            if (!record) {
                return;
            }

            try {
                await this.$service.remove(record.id);
                this.load();
                this.showDeleteRecordDialog = false;
                this.$toast.add({
                    severity: 'success',
                    summary: 'Registro excluído com sucesso!',
                    life: 3000
                });
                await this.$emit('onAfterDelete');
            } catch (err) {
                const message = err?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao excluir registro! ' + message,
                    life: 3000
                });
            }
        },
        onClickCloseDeleteRecord() {
            this.showDeleteRecordDialog = false;
        },
        async removeFiltro(text) {
            if (this.modulo && this.tipo) {
                const texto = text.value[0];
                await this.filtroService.removerFiltro({
                    filtrosExtras: JSON.stringify({ modulo: this.modulo, tipo: this.tipo }),
                    texto: texto
                });
            }

            this.load();
        },
        async removerTodosFiltros() {
            if (this.modulo && this.tipo) {
                for (const item in this.filter) {
                    await this.filtroService.removerFiltro({
                        filtrosExtras: JSON.stringify({ modulo: this.modulo, tipo: this.tipo }),
                        texto: this.filter[item]
                    });
                }
                this.filter = [];
            }

            this.load();
        },
        async verificaFiltros() {
            if (this.modulo && this.tipo) {
                await this.filtroService.save({
                    descricao: this.descricao,
                    tipo: this.tipo,
                    modulo: this.modulo,
                    texto: this.filter
                });

                const { data } = await this.filtroService.carregaFiltroTexto({
                    filtrosExtras: JSON.stringify({ modulo: this.modulo, tipo: this.tipo })
                });

                if (data.length > 0) {
                    this.filter = [];
                    data.forEach((item) => this.filter.push(item.texto));
                }
            }
        },
        async load(loadingEffect = true) {
            try {
                await this.verificaFiltros();
                if (this.loadingEffect && loadingEffect) {
                    this.loading = true;
                }
                const { data } = await this.$service.findAll({
                    limit: this.currentPerPage,
                    page: this.page,
                    filter: this.filter,
                    sort: this.sort,
                    filtrosExtras: this.filtrosExtras
                });
                this.records = data.items;
                this.total = data.meta?.totalItems;
                this.totalPage = data.meta?.totalPages;
                if (this.loadingEffect && loadingEffect) {
                    this.loading = false;
                }
            } catch (error) {
                if (this.loadingEffect && loadingEffect) {
                    this.loading = false;
                }
                const message = error?.response?.data?.message;
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao listar! ' + message,
                    life: 3000
                });
            }
        },
        setLazyEffect() {
            this.loading = true;
            this.lazyEffect = true;
        },
        disableLazyEffect() {
            this.loading = false;
            this.lazyEffect = false;
        },
        async onPage(event) {
            this.page = event.page + 1;
            this.currentPerPage = event.rows;
            this.load();
        },
        defaultRowClass(data) {
            if (this.customRowCondition) {
                if (this.customRowCondition(data)) return this.customRowStyle(data);
            } else {
                if (this.rowClass) {
                    return this.rowClass(data);
                }
                return data.ativo === false ? 'row-inactive' : null;
            }
        },
        onSort(event) {
            this.currentSortField = event.sortField;
            this.currentSortOrder = event.sortOrder;
            const field = event.sortField
                ?.split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
                ? `${event.sortField
                      ?.split(/(?=[A-Z])/)
                      .join('_')
                      .toLowerCase()}`
                : '';
            const order = event.sortOrder == 1 ? `ASC` : 'DESC';
            if (field) {
                this.sort = {
                    [field]: order
                };
            } else {
                this.sort = null;
            }

            this.load();
        },
        async logsAcoes(data) {
            this.record = data;
            this.showLogAcoesDialog = true;
        }
    }
};
</script>
<style scoped lang="scss">
::v-deep(.row-inactive) {
    color: #9e9e9e !important;
}
::-webkit-scrollbar-track {
    background-color: #f4f4f4;
}

::-webkit-scrollbar {
    width: 6px;
    background: #f4f4f4;
}

::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 15px;
}
.card {
    height: 100%;
}
.body {
    transition: all 300ms;
    overflow: auto;
    height: 65vh;
    max-height: 100%;
}
.custom-subtitle {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0.4rem;
}
.breadcrumb-container {
    margin: 0px;
}

.breadcrumb-container .p-breadcrumb-item {
    font-size: 18px;
}

.breadcrumb-container .p-breadcrumb-item .pi {
    font-size: 24px;
}
</style>
