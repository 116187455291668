<script setup>
import BaseService from '@/services/BaseService';
import { helpers, required } from '@vuelidate/validators';
import { ref, defineExpose, defineEmits } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['atualizar']);

const loading = ref(false);
const visivel = ref(false);
const formulario = ref({});

const toast = useToast();
async function receberReembolso() {
    loading.value = true;
    try {
        const url = `agendamento-deposito/receber-reembolso/${prestadorId.value}`;
        const service = new BaseService(url);
        await service.save(formulario.value);

        fechar();
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao tentar alterar os valores! ',
            life: 3000
        });
    }
    loading.value = false;
}

const rules = {
    valorRecebido: {
        required: helpers.withMessage('Campo obrigatório', required)
    }
};
const v$ = useVuelidate(rules, formulario);
async function validarFormulario() {
    const formularioCorreto = await v$.value.$validate();
    if (!formulario?.value?.valorRecebido) {
        toast.add({
            severity: 'error',
            summary: 'O valor recebido deve ser maior que 0',
            life: 3000
        });
        return;
    }
    if (formularioCorreto) receberReembolso();
}

function atualizar() {
    emit('atualizar');
}

const prestadorId = ref(null);
function abrir(id) {
    prestadorId.value = id;
    formulario.value = {};
    visivel.value = true;
}

function fechar() {
    visivel.value = false;
    v$.value.$reset();
    atualizar();
}

defineExpose({
    abrir
});
</script>

<template>
    <Dialog :visible="visivel" modal style="width: 400px; max-width: 90%; font-family: 'Inter UI', sans-serif" @update:visible="fechar()">
        <template #header>
            <h4>Alterar valores</h4>
        </template>
        <div class="flex flex-column gap-4">
            <div class="flex flex-column gap-2">
                <label for="valorRecebido" class="text-lg"> Valor recebido* </label>
                <InputNumber
                    id="valorRecebido"
                    v-model="formulario.valorRecebido"
                    :class="{ 'p-invalid': v$.valorRecebido.$error }"
                    mode="currency"
                    currency="BRL"
                    locale="pt-BR"
                    :min="0"
                />
                <small v-if="v$.valorRecebido.$error" class="p-error">
                    {{ v$.valorRecebido.$errors[0].$message }}
                </small>
            </div>
            <div class="flex flex-column gap-2">
                <label for="observecao" class="text-lg font-400"> Observação </label>
                <Textarea id="observecao" v-model="formulario.observecao" />
            </div>
        </div>
        <template #footer>
            <Button class="p-button p-button-text" label="Cancelar" @click="fechar" />
            <Button :loading="loading" class="p-button h-full m-0" label="Alterar" @click="validarFormulario" />
        </template>
    </Dialog>
</template>
