<script setup>
import BaseService from '@/services/BaseService';
import { ref, watch, defineProps } from 'vue';
import { useDebounceFn } from '@vueuse/core';
import { useToast } from 'primevue/usetoast';

const props = defineProps({
    filtrosExtras: Object
});

const loading = ref(false);
const filtro = ref(null);

watch(
    () => props.filtrosExtras,
    () => filtrar(),
    { deep: true }
);

const filtrar = useDebounceFn(() => {
    buscarPrestadores();
}, 500);

const toast = useToast();
const prestadores = ref([]);
const service = new BaseService('/suppliers/combo');
async function buscarPrestadores() {
    loading.value = true;
    try {
        const { data } = await service.findAll({
            filter: filtro.value,
            filtrosExtras: props.filtrosExtras
        });

        prestadores.value = data.items;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar prestadores',
            life: 3000
        });
    }
    loading.value = false;
}

const filtroInput = ref(null);
function forcarNoFiltro() {
    filtroInput.value?.$el?.focus();
}

buscarPrestadores();
</script>
<template>
    <Dropdown :loading="loading" :options="prestadores" emptyFilterMessage="Nenhum registro encontrado" @show="forcarNoFiltro">
        <template #header>
            <div class="flex align-items-center px-3 py-2 surface-50">
                <InputText ref="filtroInput" v-model="filtro" autofocus class="w-full pr-5" @update:model-value="filtrar" />
                <i class="pi pi-filter text-lg text-600 -ml-5"></i>
            </div>
        </template>
    </Dropdown>
</template>
