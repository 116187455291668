<script setup>
import { computed, inject, onBeforeMount, ref } from 'vue';
import BaseService from '@/services/BaseService';
import { useToast } from 'primevue/usetoast';
import useTabelaClientesVinculados from '@/components/clientes-vinculados/useTabelaClientesVinculados';

const dialogRef = inject('dialogRef');
const toast = useToast();
const clientesSemVinculo = ref([]);
const idsClientesSelecionados = ref([]);
const loadingTabela = ref(false);
const { filtroPesquisa, params, onPage, onSort, totalClientes } = useTabelaClientesVinculados(carregarClientesSemVinculo);

const fechar = () => {
    dialogRef.value.close();
};

const loadingConfirmar = ref(false);

const confirmar = async () => {
    loadingConfirmar.value = true;
    try {
        const service = new BaseService('vinculo-customer');
        await service.save({
            idOrigem: dialogRef.value.data.idOrigem,
            idsCustomers: idsClientesSelecionados.value,
            origem: dialogRef.value.data.origem
        });
        toast.add({
            severity: 'success',
            summary: `Cliente${idsClientesSelecionados.value.length > 1 ? 's' : ''} vinculado${
                idsClientesSelecionados.value.length > 1 ? 's' : ''
            } com sucesso`,
            life: 3000
        });
        fechar();
    } catch (e) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar vincular os clientes',
            life: 3000,
            detail: e.response?.data?.message
        });
    }
    loadingConfirmar.value = false;
};

async function carregarClientesSemVinculo() {
    const service = new BaseService(`vinculo-customer/${dialogRef.value.data.origem}/${dialogRef.value.data.idOrigem}/nao-vinculado`);
    const { data } = await service.findAll(params.value);
    clientesSemVinculo.value = data.items;
    totalClientes.value = data.meta.totalItems;
}

onBeforeMount(async () => {
    loadingTabela.value = true;
    try {
        await carregarClientesSemVinculo();
    } catch (e) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar os clientes sem vínculo',
            life: 3000,
            detail: e.response?.data?.message
        });
    }
    loadingTabela.value = false;
});

const idsClientesSemVinculo = computed(() => clientesSemVinculo.value.map((cliente) => cliente.id));

const selecionarTodos = computed({
    get() {
        return idsClientesSemVinculo.value.length > 0 && idsClientesSemVinculo.value.every((id) => idsClientesSelecionados.value.includes(id));
    },
    set(val) {
        onInputSelecionarTodos(val);
    }
});

function onInputSelecionarTodos(val) {
    if (!val) {
        idsClientesSelecionados.value = idsClientesSelecionados.value.filter((id) => !new Set(idsClientesSemVinculo.value).has(id));
    } else {
        const novoArray = new Set([...idsClientesSelecionados.value, ...clientesSemVinculo.value.map((cliente) => cliente.id)]);
        idsClientesSelecionados.value = [...novoArray];
    }
}
</script>

<template>
    <div class="w-full max-h-full flex flex-column gap-4" style="height: 518px">
        <div class="w-full flex align-items-center gap-2">
            <InputText v-model="filtroPesquisa" class="flex-grow-1" placeholder="Pesquisar" />
        </div>

        <div class="flex align-items-center gap-3">
            <Checkbox
                v-model="selecionarTodos"
                input-id="selecionar-todos"
                class="flex-shrink-0 w-fit px-3"
                :disabled="!idsClientesSemVinculo.length"
                binary
            />
            <label for="selecionar-todos">Selecionar todos</label>
        </div>

        <DataTable class="h-26rem" :loading="loadingTabela" :value="clientesSemVinculo" scroll-height="flex" scrollable @sort="onSort">
            <Column class="w-3rem">
                <template #body="{ data }">
                    <Checkbox v-model="idsClientesSelecionados" :value="data.id" />
                </template>
            </Column>
            <Column class="w-8rem" field="code" header="Código" sortable />
            <Column field="name" header="Cliente sem vínculo" sortable />

            <template #empty>
                <span class="flex justify-content-center"> Nenhum cliente sem vínculo </span>
            </template>
        </DataTable>

        <Paginator
            v-model:rows="params.limit"
            :rowsPerPageOptions="[6, 10, 20]"
            :totalRecords="totalClientes"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            template="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
            :first="(params.page - 1) * params.limit"
            @page="onPage($event)"
        />

        <div class="flex justify-content-end">
            <Button class="p-button-text" label="Cancelar" @click="fechar" />
            <Button label="Confirmar" :disabled="!idsClientesSelecionados.length" :loading="loadingConfirmar" @click="confirmar" />
        </div>
    </div>
</template>
