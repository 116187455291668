import { computed, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useBreadcrumb() {
    const route = useRoute();
    const router = useRouter();
    const rotasAnterioresItems = ref([]);

    const rotasAnteriores = computed(() => {
        if (!route.meta.rotasAnteriores) return;
        const arrayRotasAnteriores = ref([]);

        arrayRotasAnteriores.value = route.meta.rotasAnteriores.map((rota) => {
            return {
                ...rota,
                to: rota.to.replace(/:\w+/g, (parametro) => {
                    const chave = parametro.substring(1);
                    return route.params[chave] || parametro;
                })
            };
        });

        return arrayRotasAnteriores.value;
    });

    function voltarParaPenultimoItem() {
        if (rotasAnteriores.value.length > 2) {
            const penultimaRota = rotasAnteriores.value[rotasAnteriores.value.length - 2];
            router.push(penultimaRota.to);
        } else {
            router.push('/');
        }
    }

    watchEffect(() => {
        rotasAnterioresItems.value = rotasAnteriores.value;
    });

    return {
        rotasAnterioresItems,
        voltarParaPenultimoItem
    };
}
