<script setup>
import { ref, defineExpose, defineProps, defineEmits, watch, nextTick } from 'vue';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['update:filtrosExtras', 'atualizar']);
const props = defineProps({
    filtrosExtras: Object
});

const loading = ref(false);
const visivel = ref(false);
const filtros = ref({
    dataUltimaAtualizacao: null,
    apenasCredito: false,
    apenasReembolso: false
});

watch(() => props.filtrosExtras, limparFiltros);

function atualizarFiltros() {
    emit('update:filtrosExtras', filtros.value);
}

function limparFiltros(filtrosExtras) {
    const filtrosEstaoLimpos = !Object.values(filtros.value).some(Boolean);
    const filtrosExtrasEstaoLimpos = !Object.values(filtrosExtras).some(Boolean);
    if (!filtrosEstaoLimpos && filtrosExtrasEstaoLimpos) {
        filtros.value = {
            dataUltimaAtualizacao: null,
            apenasCredito: false,
            apenasReembolso: false
        };
    }
}

function filtrar() {
    emit('atualizar');
    fechar();
}

const toast = useToast();
function validarCampos() {
    const data = filtros.value.dataUltimaAtualizacao;
    const informadoDataInicioEFim = !data?.length || (data?.[0] && data?.[1]);
    if (!informadoDataInicioEFim) {
        return toast.add({
            severity: 'error',
            summary: 'É obrigatório selecionar um intervalo de datas!',
            life: 3000
        });
    }

    atualizarFiltros();
    nextTick(filtrar);
}

function abrir() {
    visivel.value = true;
}

function fechar() {
    filtros.value = props.filtrosExtras || {};
    visivel.value = false;
}

defineExpose({
    abrir
});
</script>

<template>
    <Dialog :visible="visivel" modal style="width: 400px; max-width: 90%" @update:visible="fechar()">
        <template #header>
            <h4>Filtrar por</h4>
        </template>
        <AppLoadingWrapper v-if="loading" />
        <div v-else class="flex flex-column gap-4">
            <div class="flex flex-column gap-2 w-20rem">
                <div for="dataUltimaAtualizacao" class="text-gray-600">Data da última movimentação</div>
                <Calendar
                    id="dataUltimaAtualizacao"
                    v-model="filtros.dataUltimaAtualizacao"
                    class="w-full"
                    dateFormat="dd/mm/yy"
                    selectionMode="range"
                    showButtonBar
                    showIcon
                />
            </div>
            <div class="flex align-items-center gap-2">
                <Checkbox id="apenasCredito" v-model="filtros.apenasCredito" binary />
                <label for="apenasCredito"> Demonstrar apenas prestadores com crédito em aberto. </label>
            </div>
            <div class="flex align-items-center gap-2">
                <Checkbox id="apenasReembolso" v-model="filtros.apenasReembolso" binary />
                <label for="apenasReembolso"> Demonstrar apenas prestadores com reembolso em aberto. </label>
            </div>
        </div>
        <template #footer>
            <Button class="p-button p-button-text" label="Cancelar" @click="fechar" />
            <Button class="p-button h-full m-0" label="Filtrar" @click="validarCampos" />
        </template>
    </Dialog>
</template>
