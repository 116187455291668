import { ref, watch } from 'vue';
import { useDebounceFn } from '@vueuse/core';

export default function (recarregarTabela) {
    const params = ref({
        page: 1,
        limit: 6,
        filter: null,
        sort: null
    });

    const totalClientes = ref(0);

    function onPage(event) {
        params.value.page = event.page + 1;
        recarregarTabela();
    }

    function onSort(event) {
        params.value.page = 1;
        params.value.sort = `${event.sortField} ${event.sortOrder === 1 ? 'ASC' : 'DESC'}`;
        recarregarTabela();
    }

    const filtroPesquisa = ref('');

    watch(filtroPesquisa, (value) => {
        filtrar(value);
    });

    const filtrar = useDebounceFn((value) => {
        params.value.page = 1;
        params.value.filter = value;
        recarregarTabela();
    }, 500);

    return {
        params,
        totalClientes,
        onPage,
        onSort,
        filtroPesquisa
    };
}
