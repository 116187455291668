import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import './App.scss';

import moment from 'moment-timezone';
import { createApp, reactive } from 'vue';
import router from './router';
import AppWrapper from './AppWrapper.vue';
import BlockUI from 'primevue/blockui';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import DynamicDialog from 'primevue/dynamicdialog';
import DialogService from 'primevue/dialogservice';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import ProgressSpinner from 'primevue/progressspinner';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import AppAnexoDialog from './components/AppAnexoDialog';
import AppProcessoDialog from './components/AppProcessoDialog';
import AppLogErroDialog from './components/AppLogErroDialog';
import AppDeleteDialog from './components/AppDeleteDialog';
import AppPdfInfoDialog from './components/AppPdfInfoDialog';
import AppActivateDialog from './components/AppActivateDialog';
import AppInactivateDialog from './components/AppInactivateDialog';
import AppDescricaoDialog from './components/AppDescricaoDialog';
import AppEnvioWhatsDialog from './components/AppEnvioWhatsDialog';
import AppPanel from './components/AppPanel';
import AppPaginatedGrid from './components/grid/AppPaginatedGrid';
import AppPaginatedGridNested from './components/grid/AppPaginatedGridNested';
import AppFormCadastro from './components/form/AppFormCadastro';
import AppFormCadastroNested from './components/form/AppFormCadastroNested';
import AppLoadingWrapper from './components/AppLoadingWrapper';
import AppInfinityDropdown from './components/input/AppInfinityDropdown';
import AppOnePageDropdown from './components/input/AppOnePageDropdown';
import AppMultiselect from './components/input/AppMultiselect';
import AppInputTempo from './components/input/AppInputTempo';
import AppInputCalendarioRanged from './components/input/AppInputCalendarioRanged';
import AppInputCalendarioSingle from './components/input/AppInputCalendarioSingle';
import AppBackButton from './components/button/AppBackButton';
import AppNotificacaoButton from './components/notificacoes/AppNotificacaoButton';
import AppRetryDialog from './components/AppRetryDialog';
import { isLogged, isPrestadorAutenticado, tratarTokenPrestador, isAutoagendamentoAutenticado, tratarTokenAutoagendamento } from './services/auth';
import store from './store';
import { checkPermission } from '@/common/check-permission';
import VueTheMask from 'vue-the-mask';
import Editor from 'primevue/editor';
import AppMensagemNotFound from './components/AppMensagemNotFound';
import AppBreadcrumb from './components/menu/breadcrumb/AppBreadcrumb';
// import SocketioService from './services/socketioService';
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';

router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0);

    if (to.matched.some((record) => record.meta.requiresAuth || record.meta.permission)) {
        if (to.meta.requiresAuth && !isLogged()) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });
        } else if (to.meta.permission && store.getters.permissions.length && store.getters.permissions.indexOf(to.meta.permission) === -1) {
            next({ path: '/login' });
        } else {
            next();
        }
    } else if (to.meta.requerAutenticacaoPrestador) {
        if (isPrestadorAutenticado()) {
            tratarTokenPrestador();
        }

        if (!isPrestadorAutenticado()) {
            next({
                path: '/confirmacao-prestador/login',
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    } else if (to.meta.requerAutenticacaoCadastroPrestador) {
        if (isPrestadorAutenticado()) {
            tratarTokenPrestador();
        }

        if (!isPrestadorAutenticado()) {
            next({
                path: '/cadastro-prestador/login',
                query: { redirect: to.fullPath }
            });
        } else {
            next();
        }
    } else if (to.fullPath === '/confirmacao-prestador/login' && isPrestadorAutenticado()) {
        next({
            path: '/confirmacao-prestador/agendamentos'
        });
    } else if (to.fullPath === '/cadastro-prestador/login' && isPrestadorAutenticado()) {
        next({
            path: '/cadastro-prestador/cadastro'
        });
    } else if (to.meta.requerAutenticacaoAutoagendamento) {
        const login = to.path === '/autoagendamento/login';
        if (isAutoagendamentoAutenticado() && login) {
            next('/autoagendamento/confirmar-dados');
        } else if (!isAutoagendamentoAutenticado() && !login) {
            next('/autoagendamento/login');
        } else next();

        tratarTokenAutoagendamento();
    } else {
        next();
    }
});

const app = createApp(AppWrapper);
app.config.globalProperties.$checkPermission = checkPermission;
app.config.globalProperties.$appState = reactive({ darkTheme: false });
app.use(VueShepherdPlugin);

app.config.globalProperties.$filters = {
    cpf: function (value) {
        if (!value) return '';
        value = value.toString().padStart(11, '0');
        return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },
    cnpj: function (value) {
        if (!value) return '';
        value = value.toString().padStart(14, '0');
        return value.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    },
    pis: function (value) {
        if (!value) return '';
        value = value.toString().padStart(11, '0');
        return value.replace(/^(\d{3})(\d{5})(\d{2})(\d{1})/, '$1.$2.$3-$4');
    },
    phone: function (value) {
        if (!value) return '';
        value = value.toString().padStart(10, '0');
        return value.replace(/^(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    },
    zipcode: function (value) {
        if (!value) return '';
        value = value.toString().padStart(8, '0');
        return value.replace(/^(\d{5})(\d{3})/, '$1-$2');
    },
    formatDate(dateString) {
        const date = moment(dateString);
        return date.format('DD/MM/YYYY [às] HH:mm[h]');
    },
    formatTime(dateString) {
        const date = moment(dateString);
        return date.format('HH:mm');
    },
    formatDateOnly(dateString) {
        const date = moment.tz(dateString, 'YYYY-MM-DD', 'America/Sao_Paulo');
        if (date.isValid()) {
            return date.format('DD/MM/YYYY');
        }
        return ' ';
    },
    formatCurrency(value) {
        if (value) return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        return;
    },
    formatDayMonth(dateString) {
        const date = moment(dateString);
        return date.format('DD/MM');
    },
    jsonToDate(dateString) {
        const date = moment(dateString.replace('Z', ''));
        return date.toDate();
    },
    cut(text, length, clamp) {
        clamp = clamp || '...';
        var node = document.createElement('div');
        node.innerHTML = text;
        var content = node.textContent;
        return content.length > length ? content.slice(0, length) + clamp : content;
    },
    simNao(value) {
        return value ? 'Sim' : 'Não';
    },
    periodo(value) {
        if (value == 1) return 'Manhã';
        if (value == 2) return 'Tarde';
        return 'Não informado';
    }
};

app.config.unwrapInjectedRef = true;

app.use(PrimeVue, {
    ripple: true,
    inputStyle: 'outlined',
    theme: 'bootstrap4-light-blue',
    locale: {
        dayNames: ['domingo', 'segunda-feira', 'terça-feira', 'quarta-feira', 'quinta-feira', 'sexta-feira', 'sábado'],
        dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'],
        dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
        today: 'Hoje',
        clear: 'Limpar',
        dateFormat: 'dd/mm/yy',
        aria: {
            star: '1 estrela',
            stars: '{star} estrelas',
            pageLabel: ''
        }
    }
});
app.use(VueTheMask);
app.use(ConfirmationService);
app.use(DialogService);
app.use(ToastService);
app.use(router);
app.use(store);

// const socket = new SocketioService().socket;
// console.info(socket);
// app.config.globalProperties.$socket = socket;

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('BlockUI', BlockUI);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('DynamicDialog', DynamicDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('Row', Row);
app.component('ColumnGroup', ColumnGroup);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Galleria', Galleria);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('ProgressSpinner', ProgressSpinner);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('Editor', Editor);
app.component('AppAnexoDialog', AppAnexoDialog);
app.component('AppProcessoDialog', AppProcessoDialog);
app.component('AppLogErroDialog', AppLogErroDialog);
app.component('AppDeleteDialog', AppDeleteDialog);
app.component('AppPdfInfoDialog', AppPdfInfoDialog);
app.component('AppActivateDialog', AppActivateDialog);
app.component('AppInactivateDialog', AppInactivateDialog);
app.component('AppDescricaoDialog', AppDescricaoDialog);
app.component('AppEnvioWhatsDialog', AppEnvioWhatsDialog);
app.component('AppPanel', AppPanel);
app.component('AppPaginatedGrid', AppPaginatedGrid);
app.component('AppPaginatedGridNested', AppPaginatedGridNested);
app.component('AppFormCadastro', AppFormCadastro);
app.component('AppFormCadastroNested', AppFormCadastroNested);
app.component('AppLoadingWrapper', AppLoadingWrapper);
app.component('AppInfinityDropdown', AppInfinityDropdown);
app.component('AppOnePageDropdown', AppOnePageDropdown);
app.component('AppMultiselect', AppMultiselect);
app.component('AppInputTempo', AppInputTempo);
app.component('AppInputCalendarioRanged', AppInputCalendarioRanged);
app.component('AppInputCalendarioSingle', AppInputCalendarioSingle);
app.component('AppBackButton', AppBackButton);
app.component('AppNotificacaoButton', AppNotificacaoButton);
app.component('AppRetryDialog', AppRetryDialog);
app.component('AppMensagemNotFound', AppMensagemNotFound);
app.component('AppBreadcrumb', AppBreadcrumb);

app.mount('#app');
