<script setup>
import AppConfirmDialog from '@/components/AppConfirmDialog.vue';
import DialogAnexoComprovante from './dialogs/DialogAnexoComprovante';
import DialogProcedimentos from './dialogs/DialogProcedimentos.vue';
import BaseService from '@/services/BaseService';
import { ref, watch, computed, defineProps, defineEmits } from 'vue';
import { useToast } from 'primevue/usetoast';
import { mCPF } from '@/utils/Mask';

const emit = defineEmits(['update:loading']);
const props = defineProps({
    value: Array,
    loading: Boolean,
    metadados: Object
});

const loadingTabela = computed({
    get() {
        return props.loading;
    },
    set(novoValor) {
        emit('update:loading', novoValor);
    }
});

const menu = ref(null);
const actionItems = ref([]);
const actionRecord = ref(null);
async function toggleMenu(event, data) {
    actionRecord.value = data;
    menu?.value.toggle(event);
}

const toast = useToast();
const dialogConfirmacao = ref(false);
async function reenviarComprovanteDeposito() {
    loadingTabela.value = true;
    try {
        const url = `agendamento-deposito/enviar-comprovante-deposito`;
        const service = new BaseService(url);
        await service.findById(actionRecord.value.idAgendamento);
        toast.add({ severity: 'success', summary: 'Comprovante enviado com sucesso!', life: 3000 });
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao enviar comprovante do deposito! ',
            life: 3000
        });
    }
    dialogConfirmacao.value = false;
    loadingTabela.value = false;
}

const dialogProcedimentos = ref(false);
function abrirDialogProcedimentos(item) {
    const { idAgendamentoDeposito, idAgendamento } = item;
    dialogProcedimentos.value?.abrir(idAgendamentoDeposito, idAgendamento);
}

const depositosIds = computed(() => {
    if (actionRecord.value) {
        return [actionRecord.value.idAgendamentoDeposito];
    }
    return [];
});

const dialogAnexoComprovante = ref(null);
function abrirDialogAnexoComprovante() {
    dialogAnexoComprovante.value?.abrir();
}

function limparDepositosIds() {
    actionRecord.value = null;
}

watch(
    actionRecord,
    () => {
        actionItems.value = [
            {
                label: 'Anexo do depósito',
                icon: 'pi pi-paperclip',
                command: () => abrirDialogAnexoComprovante()
            },
            {
                label: 'Reenviar comprovante',
                icon: 'pi pi-send',
                command: () => (dialogConfirmacao.value = true)
            },
            {
                label: 'Ver procedimentos',
                icon: 'pi pi-info-circle',
                command: () => abrirDialogProcedimentos(actionRecord.value)
            }
        ];
    },
    { deep: true }
);
</script>
<template>
    <div class="flex flex-column">
        <DataTable
            :loading="loadingTabela"
            :value="value"
            :rows="metadados?.itensPorPagina"
            :totalRecords="metadados?.total"
            class="p-0"
            @page="$emit('metadados', $event)"
        >
            <Column field="codigo" header="Código" style="width: 10%"></Column>
            <Column field="funcionario" header="Nome funcionário">
                <template #body="{ data }">
                    <div class="flex flex-column">
                        <div class="font-medium">
                            {{ data.nomeFuncionario }}
                        </div>
                        <div class="text-500 flex gap-2">
                            <span>
                                {{ data.idFuncionario }}
                            </span>

                            <span>
                                {{ mCPF(data.cpfFuncionario) }}
                            </span>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="data" header="Data" style="width: 10%"></Column>
            <Column field="hora" header="Hora" style="width: 10%"></Column>
            <Column field="prestador" header="Prestador" style="width: 15%">
                <template #body="{ data }">
                    <div class="flex flex-column">
                        <div class="font-medium">
                            {{ data.prestador }}
                        </div>
                        <div class="text-500">
                            {{ data.cnpjPrestador }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="empresa" header="Empresa" style="width: 20%">
                <template #body="{ data }">
                    <div class="flex flex-column">
                        <div class="font-medium">
                            {{ data.rasaoSocialEmpresa }}
                        </div>
                        <div class="text-500">
                            {{ data.nomeFantasiaEmpresa }}
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="valor" header="Valor" style="width: 10%">
                <template #body="{ data }">
                    {{ (+data.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) }}
                </template>
            </Column>
            <Column bodyClass="text-right" headerStyle="width: 10rem;">
                <template #body="slotProps">
                    <Button icon="pi pi-ellipsis-v" class="p-button-text p-button-secondary" @click="toggleMenu($event, slotProps.data)" />
                </template>
            </Column>
        </DataTable>
        <Paginator
            :rows="metadados?.itensPorPagina"
            :totalRecords="metadados?.total"
            currentPageReportTemplate="Página {currentPage} de {totalPages}"
            template="PrevPageLink PageLinks NextPageLink CurrentPageReport"
            @page="$emit('metadados', $event)"
        />
        <Menu ref="menu" :model="actionItems" :popup="true" />
        <AppConfirmDialog
            v-model:visible="dialogConfirmacao"
            :loading="loading"
            title="Deseja reenviar o comprovante?"
            @onConfirm="reenviarComprovanteDeposito"
            @onClose="dialogConfirmacao = false"
            :enableRemove="false"
        />
        <DialogProcedimentos ref="dialogProcedimentos" />
        <DialogAnexoComprovante ref="dialogAnexoComprovante" :depositosIds="depositosIds" @onLimparArrayDepositosIds="limparDepositosIds" />
    </div>
</template>
