<template>
    <AppLoadingWrapper v-if="loading" />
    <Page
        :cancelar="['criar', 'editar'].includes(acaoFormulario)"
        :criar="acaoFormulario === 'listar'"
        :salvar="['editar', 'criar', 'visualizar'].includes(acaoFormulario)"
        :subtitulo="subtituloDaPagina"
        :titulo="tituloDaPagina"
        :visualizar="['editar', 'criar'].includes(acaoFormulario)"
        :voltar="['listar', 'visualizar'].includes(acaoFormulario)"
        @cancelar="cancelar()"
        @criar="abrirDialogTipoFormulario"
        @salvar="validarFormulario()"
        @visualizar="acaoFormulario = 'visualizar'"
        @voltar="voltar()"
    >
        <div ref="body" :style="{ height: `${alturaResponsiva}px` }">
            <Listagem v-if="acaoFormulario === 'listar'" @duplicar="duplicarFormulario" @editar="editarFormulario" />
            <Visualizacao v-if="acaoFormulario === 'visualizar'" :formulario="formulario" />
            <Formulario ref="formularioRef" v-if="['criar', 'editar'].includes(acaoFormulario)" v-model="formulario" />
        </div>
    </Page>
</template>

<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue';
import { getClientBase } from '../../../services/http';
import { useToast } from 'primevue/usetoast';
import Formulario from './components/Formulario.vue';
import Listagem from './components/Listagem.vue';
import Visualizacao from './components/Visualizacao.vue';
import Page from './components/Page.vue';
import router from '../../../router';
import AppLoadingWrapper from '../../../components/AppLoadingWrapper.vue';
import { useDialog } from 'primevue/usedialog';
import ConteudoModalTipoFormulario from '@/pages/gestao-medica-v3/formularios/components/ConteudoModalTipoFormulario.vue';

const dialog = useDialog();

const body = ref(null);
const toast = useToast();
const formulario = ref({
    titulo: null,
    descricao: '',
    tipoFormularioId: null,
    secoes: [
        {
            redirecionamentoId: crypto.randomUUID(),
            titulo: '',
            descricao: '',
            formularioPergunta: []
        }
    ]
});
const alturaResponsiva = ref(0);
const acaoFormulario = ref('listar');
const formularioRef = ref(null);
const loading = ref(false);

async function abrirDialogTipoFormulario() {
    dialog.open(ConteudoModalTipoFormulario, {
        props: {
            header: 'Novo formulário',
            style: {
                width: '100%',
                maxWidth: '400px'
            },
            modal: true
        },
        onClose: (opt) => {
            if (opt.data) {
                formulario.value.tipoFormularioId = opt.data;
                acaoFormulario.value = 'criar';
            }
        }
    });
}

const tituloDaPagina = computed(() => {
    if (acaoFormulario.value === 'criar') return 'Novo Formulário';
    if (acaoFormulario.value === 'editar') return 'Editar Formulário';
    if (acaoFormulario.value === 'visualizar') return 'Visualização';

    return 'Formulários';
});
const subtituloDaPagina = computed(() => {
    return acaoFormulario.value === 'listar' ? 'Gestão e criação de formulários' : undefined;
});

onMounted(() => {
    definirAlturaResponsiva();
    window.addEventListener('resize', definirAlturaResponsiva);
});
onUnmounted(() => {
    window.removeEventListener('resize', definirAlturaResponsiva);
});

async function salvarFormulario() {
    loading.value = true;
    try {
        await getClientBase().post('formulario', {
            ...formulario.value,
            update: !!formulario.value.id
        });
        acaoFormulario.value = 'listar';
        resetarFormulario();
        toast.add({
            severity: 'success',
            summary: 'Formulário salvo com sucesso',
            life: 3000
        });
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar salvar o formulário',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}
async function validarFormulario() {
    if (acaoFormulario.value === 'visualizar') {
        acaoFormulario.value = formulario.value.id ? 'editar' : 'criar';
    }

    nextTick(async () => {
        const validacao = await formularioRef.value.validarCampos(true);
        if (validacao) salvarFormulario();
    });
}
function cancelar() {
    acaoFormulario.value = 'listar';
    resetarFormulario();
}
function definirAlturaResponsiva() {
    const footer = 10;
    const coordenadas = body.value.getBoundingClientRect();

    alturaResponsiva.value = window.innerHeight - coordenadas.top - footer;
}
function duplicarFormulario(data) {
   const secoes = data.secoes.map((secao) => {
            return {
                descricao: secao?.descricao,
                formularioPergunta: secao?.formularioPergunta.map((pergunta) => {
                    
                    return {
                        redirecionamentoId: crypto.randomUUID(),
                        adicionarObservacao: pergunta?.adicionarObservacao,
                        direcionamentoGenero: pergunta?.direcionamentoGenero,
                        formularioOpcaoResposta: pergunta?.formularioOpcaoResposta?.map((opcao) => {
                            return {
                                textoOpcao: opcao?.textoOpcao,
                                outros: opcao?.outros,
                            };
                        }),
                        labelObservacao: pergunta?.labelObservacao,
                        nomePergunta: pergunta?.nomePergunta,
                        obrigatorio: pergunta?.obrigatorio,
                        ordem: pergunta?.ordem,
                        tipoResposta: pergunta?.tipoResposta,
                        variasRespostas: pergunta?.variasRespostas
                    };
                }),
                id: null,
                origemId: secao?.origemId,
                redirecionamentoId: secao?.redirecionamentoId,
                titulo: secao?.titulo
            };
        });
    formulario.value = {
        ...data,
        id: null,
        secoes
    };
    acaoFormulario.value = 'criar';
}
function editarFormulario(data) {
    const secoes = data.secoes.map((secao) => {
            return {
                ...secao,
                formularioPergunta: secao?.formularioPergunta?.map((pergunta) => {
                    return {
                        ...pergunta,
                        redirecionamentoId: crypto.randomUUID(),
                    };
                }),
            };
        });
    formulario.value = {
        ...data,
        secoes
    };
    acaoFormulario.value = 'editar';
}
function resetarFormulario() {
    formulario.value = {
        titulo: null,
        descricao: '',
        tipoFormularioId: null,
        secoes: [
            {
                redirecionamentoId: crypto.randomUUID(),
                titulo: '',
                descricao: '',
                formularioPergunta: []
            }
        ]
    };
}

function voltar() {
    if (acaoFormulario.value === 'listar') router.back();
    else if (formulario.value.id) acaoFormulario.value = 'editar';
    else acaoFormulario.value = 'criar';
}
</script>
