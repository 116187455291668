const EnumParecer = {
    INAPTO_PARA_FUNCAO: 'INAPTO_PARA_FUNCAO',
    INAPTO_PARA_TRABALHO_EM_ALTURA: 'INAPTO_PARA_TRABALHO_EM_ALTURA',
    INAPTO_PARA_TRABALHO_EM_ESPACOS_CONFINADOS: 'INAPTO_PARA_TRABALHO_EM_ESPACOS_CONFINADOS',
    INAPTO_PARA_EXPOSICAO_RADIOACOES_IONIZANTES: 'INAPTO_PARA_EXPOSICAO_RADIOACOES_IONIZANTES',
    APTO_PARA_FUNCAO : 'APTO_PARA_FUNCAO',
    APTO_PARA_TRABALHO_EM_ALTURA : 'APTO_PARA_TRABALHO_EM_ALTURA',
    APTO_PARA_TRABALHO_EM_ESPACOS_CONFINADOS: 'APTO_PARA_TRABALHO_EM_ESPACOS_CONFINADOS',
    APTO_PARA_EXPOSICAO_RADIOACOES_IONIZANTES: 'APTO_PARA_EXPOSICAO_RADIOACOES_IONIZANTES'
}

export default EnumParecer
