<template>
    <div class="h-full bg-black-alpha-10 grid m-0 sm:p-6 justify-content-center overflow-auto" @click="resetarSelecao()">
        <div class="col-12 lg:col-10 xl:col-8">
            <div
                :class="{ 'cursor-pointer': selecionado.secao !== -1 }"
                class="bg-white flex flex-column py-4 px-6 gap-2 border-round-md mb-6"
                @click.stop="selecionarSecao(-1)"
            >
                <InputText
                    ref="titulo"
                    v-show="selecionado.secao === -1"
                    v-model="formulario.titulo"
                    :class="{ 'p-invalid': v$.titulo.$error }"
                    autofocus
                    class="border-none border-bottom-1 border-noround shadow-none text-4xl font-semibold -mt-2"
                    placeholder="Título"
                />
                <div
                    v-show="selecionado.secao !== -1"
                    class="text-4xl font-semibold text-900 mt-2 cursor-auto"
                    style="width: fit-content"
                    @click.stop="selecionarSecao(-1, $refs.titulo.$el)"
                >
                    {{ formulario.titulo || 'Título' }}
                </div>
                <small v-if="v$.titulo.$error" class="p-error w-full">{{ v$.titulo.$errors[0].$message }}</small>
                <InputText
                    ref="descricao"
                    v-show="selecionado.secao === -1"
                    v-model="formulario.descricao"
                    class="border-none border-bottom-1 border-noround shadow-none font-medium"
                    placeholder="Descrição do formulário"
                />
                <div
                    v-show="selecionado.secao !== -1"
                    class="font-medium text-700 my-3 cursor-auto"
                    style="width: fit-content"
                    @click.stop="selecionarSecao(-1, $refs.descricao.$el)"
                >
                    {{ formulario.descricao }}
                </div>
            </div>
            <Button v-if="selecionado.secao === -1" class="px-4 -mt-4 mb-6" label="Nova seção" @click.stop="adicionarSecao(-1)" />
            <Secao
                v-for="(secao, indexSecao) in formularioFiltrado.secoes"
                :model-value="secao"
                :editavel="selecionado.secao === indexSecao && selecionado.pergunta === null"
                :key="indexSecao"
                :numero="indexSecao + 1"
                :salvando="salvando"
                :selecionada="selecionado.secao === indexSecao"
                @adicionar="adicionarSecao"
                @adicionar-pergunta="adicionarPergunta"
                @excluir="excluirSecao(secao.redirecionamentoId)"
                @duplicar="duplicarSecao"
                @selecionar="selecionarSecao(indexSecao, $event)"
                @update:modelValue="atualizarSecao($event)"
            >
                <Pergunta
                    v-for="(pergunta, indexPergunta) in secao.formularioPergunta"
                    :model-value="pergunta"
                    :editavel="selecionado.secao === indexSecao && selecionado.pergunta === indexPergunta"
                    :key="indexPergunta"
                    :numero="indexPergunta + 1"
                    :itens-redirecionamento="buscarItensRedirecionamento(indexSecao, indexPergunta)"
                    :aumentar-ordem="indexPergunta !== formulario.secoes[indexSecao].formularioPergunta.length - 1"
                    :diminuir-ordem="indexPergunta !== 0"
                    @aumentar-ordem="alterarOrdemDaPergunta(indexPergunta, indexPergunta + 1)"
                    @diminuir-ordem="alterarOrdemDaPergunta(indexPergunta, indexPergunta - 1)"
                    @excluir="excluirPergunta(secao.redirecionamentoId, pergunta.redirecionamentoId)"
                    @duplicar="duplicarPergunta"
                    @selecionar="selecionarPergunta(indexSecao, indexPergunta, $event)"
                    @update:modelValue="atualizarPergunta(secao, $event)"
                />
            </Secao>
        </div>
    </div>
</template>

<script setup>
import { ref, nextTick, computed, defineEmits, defineProps, defineExpose, watch } from 'vue';
import { helpers, requiredIf } from '@vuelidate/validators';
import Pergunta from './Pergunta.vue';
import Secao from './Secao.vue';
import useVuelidate from '@vuelidate/core';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    }
});

const formulario = ref(structuredClone(props.modelValue));
const salvando = ref(false);
const selecionado = ref({
    secao: -1,
    pergunta: null
});
const validacoes = {
    titulo: {
        required: helpers.withMessage(
            'Este campo é obrigatório',
            requiredIf(() => salvando.value)
        )
    }
};

const formularioFiltrado = computed(() => {
    const form = structuredClone(props.modelValue);
    const secoes = [];
    for (const secao of form.secoes || []) {
        if (secao.excluido) continue;
        const formularioPergunta = [];
        for (const pergunta of secao.formularioPergunta || []) {
            if (pergunta.excluido) continue;
            const opcoes = [];
            for (const opcao of pergunta.formularioOpcaoResposta || []) {
                if (!opcao.excluido) opcoes.push(opcao);
            }
            const instrucoes = [];
            for (const instrucao of pergunta.formularioInstrucaoResposta || []) {
                if (!instrucao.excluido) instrucoes.push(instrucao);
            }

            pergunta.formularioOpcaoResposta = opcoes;
            pergunta.formularioInstrucaoResposta = instrucoes;
            formularioPergunta.push(pergunta);
        }

        secao.formularioPergunta = formularioPergunta;
        secoes.push(secao);
    }
    form.secoes = secoes;

    return form;
});
const v$ = useVuelidate(validacoes, formulario);

watch(
    formulario,
    (novoValor) => {
        if (JSON.stringify(novoValor) !== JSON.stringify(props.modelValue)) {
            emit('update:modelValue', novoValor);
        }
    },
    { deep: true }
);
watch(
    () => props.modelValue,
    (novoValor) => {
        if (JSON.stringify(novoValor) !== JSON.stringify(formulario.value)) {
            formulario.value = novoValor;
        }
    }
);

async function adicionarPergunta() {
    if (!(await validarCampos())) return;

    const indexNovaPergunta = formulario.value.secoes[selecionado.value.secao].formularioPergunta.length;
    formulario.value.secoes[selecionado.value.secao].formularioPergunta.push({
        redirecionamentoId: crypto.randomUUID(),
        nomePergunta: '',
        tipoFormularioId: formulario.value.tipoFormularioId,
        tipoResposta: 'DISSERTATIVA',
        obrigatorio: true,
        variasRespostas: false,
        ordem: indexNovaPergunta + 1,
        direcionamentoGenero: 'AMBOS',
        direcionamentoTipoExame: 99,
        formularioOpcaoResposta: [],
        formularioInstrucaoResposta: [],
        adicionarObservacao: false,
        labelObservacao: ''
    });

    selecionado.value.pergunta = indexNovaPergunta;
}
async function adicionarSecao() {
    if (!(await validarCampos())) return;

    const indexNovaSecao = selecionado.value.secao + 1;
    formulario.value.secoes.splice(indexNovaSecao, 0, {
        redirecionamentoId: crypto.randomUUID(),
        titulo: '',
        descricao: '',
        formularioPergunta: []
    });

    nextTick(() => {
        selecionado.value.secao = indexNovaSecao;
        selecionado.value.pergunta = null;
    });
}
async function alterarOrdemDaPergunta(indexAtual, indexNovo) {
    if (!(await validarCampos())) return;

    const perguntas = formulario.value.secoes[selecionado.value.secao].formularioPergunta;
    const ordemAtual = perguntas[indexAtual].ordem;
    perguntas[indexAtual].ordem = perguntas[indexNovo].ordem;
    perguntas[indexNovo].ordem = ordemAtual;

    perguntas[indexNovo].formularioRedirecionamento = undefined;
    perguntas[indexNovo].formularioOpcaoResposta?.forEach((opcao) => {
        opcao.formularioRedirecionamento = undefined;
    });
    perguntas[indexAtual].formularioRedirecionamento = undefined;
    perguntas[indexAtual].formularioOpcaoResposta?.forEach((opcao) => {
        opcao.formularioRedirecionamento = undefined;
    });

    const pergunta = perguntas.splice(indexAtual, 1);
    perguntas.splice(indexNovo, 0, pergunta[0]);

    nextTick(() => (selecionado.value.pergunta = indexNovo));
}

async function duplicarPergunta() {
    if (!(await validarCampos())) return;

    const secaoAtual = formulario.value.secoes[selecionado.value.secao];
    const perguntaAtual = structuredClone(secaoAtual.formularioPergunta[selecionado.value.pergunta]);
    const indexNovaPergunta = selecionado.value.pergunta + 1;
    delete perguntaAtual.id;
    perguntaAtual.redirecionamentoId = crypto.randomUUID();
    secaoAtual.formularioPergunta.splice(indexNovaPergunta, 0, perguntaAtual);

    nextTick(() => (selecionado.value.pergunta = indexNovaPergunta));
}
async function duplicarSecao() {
    if (!(await validarCampos())) return;

    const secaoAtual = structuredClone(formulario.value.secoes[selecionado.value.secao]);
    const indexNovaSecao = selecionado.value.secao + 1;
    delete secaoAtual.id;
    secaoAtual.redirecionamentoId = crypto.randomUUID();
    formulario.value.secoes.splice(indexNovaSecao, 0, secaoAtual);

    nextTick(() => (selecionado.value.secao = indexNovaSecao));
}
async function resetarSelecao() {
    if (!(await validarCampos())) return;

    selecionado.value.secao = null;
    selecionado.value.pergunta = null;
}
async function selecionarPergunta(indexSecao, indexPergunta, elemento) {
    const mesmaSecao = indexSecao === selecionado.value.secao;
    const mesmaPergunta = indexPergunta === selecionado.value.pergunta;
    if ((mesmaSecao && mesmaPergunta) || !(await validarCampos())) {
        return;
    }

    selecionado.value.secao = indexSecao;
    selecionado.value.pergunta = indexPergunta;
    if (elemento) nextTick(() => elemento.focus());
}
async function selecionarSecao(indexSecao, elemento) {
    const semPergunta = selecionado.value.pergunta === null;
    const mesmaSecao = indexSecao === selecionado.value.secao;
    if ((mesmaSecao && semPergunta) || !(await validarCampos())) {
        return;
    }

    selecionado.value.secao = indexSecao;
    selecionado.value.pergunta = null;
    if (elemento) nextTick(() => elemento.focus());
}
async function validarCampos(salvandoFormulario) {
    salvando.value = salvandoFormulario;

    const validacao = await v$.value.$validate();
    if (validacao) v$.value.$reset();

    return validacao;
}
function atualizarSecao(novoValor) {
    const index = formulario.value.secoes.findIndex((secao) => {
        return secao.redirecionamentoId === novoValor.redirecionamentoId;
    });
    if (index === -1) return;

    const formularioPergunta = formulario.value.secoes[index].formularioPergunta;
    formulario.value.secoes[index] = { ...novoValor, formularioPergunta };
}
function atualizarPergunta(secaoPergunta, novoValor) {
    const indexSecao = formulario.value.secoes.findIndex((secao) => {
        return secao.redirecionamentoId === secaoPergunta.redirecionamentoId;
    });
    if (indexSecao === -1) return;

    const secao = formulario.value.secoes[indexSecao];
    const indexPergunta = secao.formularioPergunta.findIndex((pergunta) => {
        return pergunta.redirecionamentoId === novoValor.redirecionamentoId;
    });
    if (indexSecao === -1) return;

    secao.formularioPergunta[indexPergunta] = novoValor;
}
function buscarItensRedirecionamento(indexSecao, indexPergunta) {
    const itens = [];

    const secao = formulario.value.secoes[indexSecao];
    if (indexPergunta !== secao.formularioPergunta.length - 1) {
        const proximasPerguntas = secao.formularioPergunta.slice(indexPergunta + 1);
        itens.push({
            id: secao.redirecionamentoId,
            label: secao.titulo,
            secaoAtual: true,
            items: proximasPerguntas.map((pergunta) => ({
                id: pergunta.redirecionamentoId,
                label: pergunta.nomePergunta
            }))
        });
    }

    const proximasSecoes = formulario.value.secoes.slice(indexSecao + 1);
    itens.push(
        ...proximasSecoes.map((secao) => ({
            id: secao.redirecionamentoId,
            label: secao.titulo,
            items: secao.formularioPergunta.map((pergunta) => ({
                id: pergunta.redirecionamentoId,
                label: pergunta.nomePergunta
            }))
        }))
    );

    return itens;
}
function excluirPergunta(secaoRedirecionamentoId, perguntaRedirecionamentoId) {
    const indexSecao = formulario.value.secoes.findIndex((secao) => {
        return secao.redirecionamentoId === secaoRedirecionamentoId;
    });
    if (indexSecao === -1) return;

    const secao = formulario.value.secoes[indexSecao];
    const indexPergunta = secao.formularioPergunta.findIndex((pergunta) => {
        return pergunta.redirecionamentoId === perguntaRedirecionamentoId;
    });
    if (indexSecao === -1) return;
    const pergunta = secao.formularioPergunta[indexPergunta];

    if (pergunta.id) pergunta.excluido = true;
    else secao.formularioPergunta.splice(indexPergunta, 1);

    if (!secao.formularioPergunta.length) {
        selecionado.value.pergunta = null;
    } else if (!secao.formularioPergunta[indexPergunta]) {
        selecionado.value.pergunta--;
    }
}
function excluirSecao(redirecionamentoId) {
    const index = formulario.value.secoes.findIndex((secao) => {
        return secao.redirecionamentoId === redirecionamentoId;
    });
    if (index === -1) return;

    const secao = formulario.value.secoes[index];
    if (secao.id) secao.excluido = true;
    else formulario.value.secoes.splice(index, 1);

    if (!formulario.value.secoes.length) {
        selecionado.value.secao = null;
    } else if (!formulario.value.secoes[index]) {
        selecionado.value.secao--;
    }
}
function structuredClone(objeto) {
    const objetoString = JSON.stringify(objeto);
    return JSON.parse(objetoString);
}
defineExpose({ validarCampos });
</script>
