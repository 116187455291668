<script setup>
import { inject } from 'vue';
import { onBeforeMount, ref } from 'vue';
import { getClientBase } from '@/services/http';
import { useToast } from 'primevue/usetoast';

const toast = useToast();
const tiposDeFormulario = ref([]);
const tipoFormularioId = ref();
const dialogRef = inject('dialogRef');

async function definirTiposDeFormulario() {
    try {
        const { data } = await getClientBase().get('formulario/tipos-formularios');
        tiposDeFormulario.value = data.sort((a, b) => {
            if (a.name === 'Ficha Clínica') return -1;
            if (b.name === 'Ficha Clínica') return 1;
            return a.id - b.id;
        });
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar os tipos de formulario',
            life: 4000,
            detail: error.response?.data?.message
        });
    }
}

const fechar = () => {
    dialogRef.value.close();
};

const avancar = () => {
    dialogRef.value.close(tipoFormularioId.value);
};

onBeforeMount(() => {
    definirTiposDeFormulario();
});
</script>

<template>
    <div class="flex flex-column gap-4">
        <span>Selecione o tipo de formulário para prosseguir com a criação de um novo formulário</span>
        <div>
            <label for="tipoFormularioDropdown">Tipo de formulário</label>
            <Dropdown
                v-model="tipoFormularioId"
                :options="tiposDeFormulario"
                class="w-full border-round-md mt-2"
                option-label="name"
                placeholder="Selecionar"
                option-value="id"
            />
        </div>
        <div class="flex justify-content-end gap-3">
            <Button label="Cancelar" class="p-button-text" @click="fechar" />
            <Button label="Próximo" :disabled="!tipoFormularioId" @click="avancar" />
        </div>
    </div>
</template>
