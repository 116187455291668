<script setup>
import BaseService from '@/services/BaseService';
import { helpers, required, maxLength, minValue } from '@vuelidate/validators';
import { ref, defineExpose, defineEmits } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';

const emit = defineEmits(['atualizar']);

const loading = ref(false);
const visivel = ref(false);
const formulario = ref({});
const origensValor = [
    {
        label: 'Crédito',
        value: 'CREDITO'
    },
    {
        label: 'Reembolso',
        value: 'REEMBOLSO'
    }
];

const toast = useToast();
async function alterarValoresPrestador() {
    loading.value = true;
    try {
        const url = `agendamento-deposito/alterar-credito-prestador/${prestadorId.value}`;
        const service = new BaseService(url);
        await service.save(formulario.value);

        atualizar();
        fechar();
    } catch (error) {
        const mensagem = error?.response?.data?.message;
        toast.add({
            severity: 'error',
            summary: mensagem || 'Erro ao tentar alterar os valores! ',
            life: 3000
        });
    }
    loading.value = false;
}

const rules = {
    origem: {
        required: helpers.withMessage('Campo obrigatório', required)
    },
    credito: {
        required: helpers.withMessage('Campo obrigatório', required),
        maxLength: helpers.withMessage('Campo deve ter no máximo 10 carcteres', maxLength(10)),
        minValue: helpers.withMessage('Campo deve ter valor maior ou igual a 0', minValue(0))
    },
    justificativa: {
        required: helpers.withMessage('Campo obrigatório', required)
    }
};
const v$ = useVuelidate(rules, formulario);
async function validarFormulario() {
    const formularioCorreto = await v$.value.$validate();
    if (formularioCorreto) alterarValoresPrestador();
}

function atualizar() {
    emit('atualizar');
}

const prestadorId = ref(null);
function abrir(id) {
    prestadorId.value = id;
    formulario.value = {};
    visivel.value = true;
}

function fechar() {
    visivel.value = false;
    v$.value.$reset();
}

defineExpose({
    abrir
});
</script>

<template>
    <Dialog :visible="visivel" modal style="width: 400px; max-width: 90%; font-family: 'Inter UI', sans-serif" @update:visible="fechar()">
        <template #header>
            <h4>Alterar valores</h4>
        </template>
        <div class="flex flex-column gap-4">
            <div class="flex flex-column gap-2">
                <label for="origem" class="text-lg"> Tipo de valor* </label>
                <Dropdown
                    id="origem"
                    v-model="formulario.origem"
                    :class="{ 'p-invalid': v$.origem.$error }"
                    :options="origensValor"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Selecione"
                />
                <small v-if="v$.origem.$error" class="p-error">
                    {{ v$.origem.$errors[0].$message }}
                </small>
            </div>
            <div class="flex flex-column gap-2">
                <label for="credito" class="text-lg"> Novo valor* </label>
                <InputNumber
                    id="credito"
                    v-model="formulario.credito"
                    :class="{ 'p-invalid': v$.credito.$error }"
                    mode="currency"
                    currency="BRL"
                    locale="pt-BR"
                    :min="0"
                    :max="100000"
                />
                <small v-if="v$.credito.$error" class="p-error">
                    {{ v$.credito.$errors[0].$message }}
                </small>
            </div>
            <div class="flex flex-column gap-2">
                <label for="justificativa" class="text-lg font-400"> Justificativa* </label>
                <Textarea id="justificativa" v-model="formulario.justificativa" :class="{ 'p-invalid': v$.justificativa.$error }" />
                <small v-if="v$.justificativa.$error" class="p-error">
                    {{ v$.justificativa.$errors[0].$message }}
                </small>
            </div>
        </div>
        <template #footer>
            <Button class="p-button p-button-text" label="Cancelar" @click="fechar" />
            <Button :loading="loading" class="p-button h-full m-0" label="Alterar" @click="validarFormulario" />
        </template>
    </Dialog>
</template>
