const SET_TENANT = 'SET_TENANT';

const state = {
    companyId: null,
    debouncer: setTimeout(() => {}, 0)
};

const actions = {
    setTenant({ commit }, payload) {
        commit(SET_TENANT, { payload });
    },
    callWithDebouncer({ state }, callback) {
        clearTimeout(state.debouncer);
        state.debouncer = setTimeout(async () => {
            await callback();
        }, 42);
        return 'is_calling';
    }
};

const mutations = {
    [SET_TENANT](state, payload) {
        state.companyId = payload;
    }
};

const getters = {
    tenant(state) {
        return state.companyId;
    }
};

export const tenant = {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
};
