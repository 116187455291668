<script setup>
import { computed, inject, onBeforeMount, ref } from 'vue';
import BaseService from '@/services/BaseService';
import { useToast } from 'primevue/usetoast';
import useTabelaClientesVinculados from '@/components/clientes-vinculados/useTabelaClientesVinculados';

const dialogRef = inject('dialogRef');
const { filtroPesquisa, params, onPage, onSort, totalClientes } = useTabelaClientesVinculados(load);

const fechar = () => {
    dialogRef.value.close();
};

const novoVinculoClick = () => {
    dialogRef.value.close({ novoVinculo: true });
};

const clientes = ref([]);
const serviceClientesVinculados = new BaseService(`vinculo-customer/${dialogRef.value.data.origem}/${dialogRef.value.data.idOrigem}`);
const loading = ref(false);

onBeforeMount(async () => {
    await load();
});

const toast = useToast();

async function load() {
    loading.value = true;
    try {
        const { data } = await serviceClientesVinculados.findAll(params.value);
        clientes.value = data.items;
        totalClientes.value = data.meta.totalItems;
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Erro ao tentar buscar os clientes vinculados',
            life: 3000,
            detail: error.response?.data?.message
        });
    }
    loading.value = false;
}

const textoOrigem = computed(() => {
    const tipos = {
        ficha: 'formulário',
        aso: 'ASO'
    };

    return tipos[dialogRef.value.data.origem];
});

async function desvincularCliente(cliente) {
    try {
        cliente.loading = true;
        const service = new BaseService(`vinculo-customer/${dialogRef.value.data.origem}`);
        await service.remove(cliente.id);
        toast.add({
            severity: 'success',
            summary: 'Cliente desvinculado com sucesso!',
            life: 3000
        });
        await load();
    } catch (e) {
        cliente.loading = false;
        toast.add({
            severity: 'error',
            summary: 'Erro ao desvincular cliente',
            life: 3000,
            detail: e.response?.data?.message
        });
    }
}
</script>

<template>
    <div class="w-full max-h-full flex flex-column gap-4" style="height: 528px">
        <div class="w-full flex align-items-center gap-2">
            <InputText v-model="filtroPesquisa" class="flex-grow-1" placeholder="Pesquisar" />
            <Button label="Novo vínculo" icon="pi pi-plus" @click="novoVinculoClick" />
        </div>

        <div v-if="clientes.length" class="flex flex-column gap-4">
            <DataTable class="h-30rem" :loading="loading" :value="clientes" scroll-height="flex" scrollable @sort="onSort">
                <Column class="w-8rem" field="code" header="Código" sortable />
                <Column field="name" header="Cliente" sortable />
                <Column field="acoes" class="w-3rem">
                    <template #body="{ data }">
                        <Button class="p-button-text" icon="pi pi-trash" :loading="data.loading" @click="desvincularCliente(data)" />
                    </template>
                </Column>
            </DataTable>

            <Paginator
                v-model:rows="params.limit"
                :rowsPerPageOptions="[6, 10, 20]"
                :totalRecords="totalClientes"
                currentPageReportTemplate="Página {currentPage} de {totalPages}"
                template="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                :first="(params.page - 1) * params.limit"
                @page="onPage($event)"
            />
        </div>

        <div v-else class="flex-grow-1 flex flex-column justify-content-center align-items-center">
            <img src="layout/images/formularios/listagem-vazia.svg" alt="listagem vazia" width="132" />
            Nenhum cliente vinculado a esse {{ textoOrigem }}
        </div>

        <div class="flex justify-content-end">
            <Button class="p-button-outlined" label="Fechar" @click="fechar" />
        </div>
    </div>
</template>
